import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Collaborateur } from "../../models/annuaire/collaborateur";
import { plainToInstance } from 'class-transformer';

@Injectable({
    providedIn: 'root'
  })
  export class CollaborateurService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/ann/collaborateur';

    getAll(): Observable<Collaborateur[]> {
      return this.http.get<Collaborateur[]>(this.url).pipe(
        map(c =>{
          return plainToInstance(Collaborateur, c);
        })
      );
    }

    public searchValidIdentifiant(filtre: string): Observable<boolean> {
      return this.http.get<boolean>(this.url +'/search?filter='+filtre);
    }

    add(newCollaborateur: Collaborateur): Observable<Collaborateur> {
      return this.http.post<Collaborateur>(this.url, newCollaborateur);
    }

    update(updatedCollaborateur: Collaborateur): Observable<Collaborateur> {
      return this.http.put<Collaborateur>(this.url + '/' + updatedCollaborateur.id, updatedCollaborateur);
    }

    updatePassword(password: string): Observable<Collaborateur> {
      return this.http.put<Collaborateur>(this.url + '/updatePassword', password);
    }

    updateActivite(): Observable<Collaborateur> {
      return this.http.put<Collaborateur>(this.url + '/updateActivite/', {});
    }

    activate(collaborateurID: number) : Observable<Collaborateur> {
      return this.http.put<Collaborateur>(this.url + '/' + collaborateurID + '/activate', {});
    }

    delete(collaborateurID: number) : Observable<Collaborateur> {
      return this.http.delete<Collaborateur>(this.url + '/' + collaborateurID);
    }

    getMyProfil() : Observable<Collaborateur> {
      return this.http.get<Collaborateur>(this.url + "/getMyProfil").pipe(
        map(c =>{
          return plainToInstance(Collaborateur, c);
        })
      );
    }
  }