<app-nav-menu></app-nav-menu>
<div *ngIf="!isLoading; else loadingBlock" class="p-d-flex p-d-row" style="margin-top:20px;">
    <div class="menu">
        <h4 *ngIf="territoires.length > 1">Territoires</h4>
        <div *ngIf="territoires.length > 1" style="padding:10px;">
            <p-dropdown [options]="territoires" [(ngModel)]="selectedTerritoire" optionLabel="nom"
                (onChange)="selectTerritoire()" placeholder="Sélectionnez un territoire">
            </p-dropdown>
        </div>
        <h4>Établissements</h4>
        <p-megaMenu class="tableau-bord" [model]="etablissementsMenu" orientation="vertical"></p-megaMenu>
        <h4>Secteurs de territoire</h4>
        <p-megaMenu [model]="secteursMenu" orientation="vertical"></p-megaMenu>
    </div>    
    <div *ngIf="selected.length > 0" style="margin-left: 30px; margin-right: 30px; width: 100%;">
        <div *ngFor="let selected of selected" style="display: inline-block;">
            <div class="selected">
                <span class="selected-label" [innerHTML]="getDisplaySelected(selected)"></span>
                <span class="selected-icon pi pi-times-circle" (click)="removeSelected(selected)"></span>
            </div>
        </div>
        <div *ngIf="isSearching" style="text-align: center">
            <sesame-loader></sesame-loader>
        </div>
        <div *ngIf="!isSearching && !isErreurRequete" style="text-align: center">
            <div style="display:flex; justify-content:left;">
                <p-selectButton [options]="selectPrevisionnel" [ngModel]="isPrevisionnel" (onOptionClick)="clickSelectPrevision($event)" optionLabel="libelle" optionValue="valeur"></p-selectButton>
            </div>
            <p-table #tTableauDeBord [value]="isPrevisionnel ? previsionnel : tableauDeBord" [paginator]="true" [rows]="25" [(first)]="first" [autoLayout]="true" [rowsPerPageOptions]="[25,50,100]"
                [showCurrentPageReport]="true" currentPageReportTemplate="{first} à {last} parmi {totalRecords} services" (onEditInit)="onEditInit($event)" (onEditComplete)="onEditComplete($event)">
                <ng-template pTemplate="header">
                    <tr *ngIf="!isPrevisionnel">
                        <th pSortableColumn="nomOrder" rowspan="2">Service <p-sortIcon field="nomOrder"></p-sortIcon></th>
                        <th colspan="10" class="th-titre">Lits</th>
                        <th *ngIf="isTableauEntier" colspan="1" style="border: 0;"></th>
                        <th *ngIf="isTableauEntier" colspan="3" class="th-titre">Lits tension</th>
                        <!--Si on enlève la colonne occupé des éléments cachés de base, enlever le ngIf -->
                        <th *ngIf="isTableauEntier" class="th-titre" colspan="1"></th>
                    </tr>
                    <tr *ngIf="!isPrevisionnel">
                        <th pSortableColumn="total" style="text-align: center;">Total <p-sortIcon field="total"></p-sortIcon></th>
                        <th pSortableColumn="capacitaireReel" style="text-align: center;">Capacitaire réel <p-sortIcon field="capacitaireReel"></p-sortIcon></th>
                        <th pSortableColumn="dispoH" style="text-align: center;">Dispo. Homme <p-sortIcon field="dispoH"></p-sortIcon></th>
                        <th pSortableColumn="dispoF" style="text-align: center;">Dispo. Femme <p-sortIcon field="dispoF"></p-sortIcon></th>
                        <th pSortableColumn="dispoI" style="text-align: center;">Dispo. indéterminé <p-sortIcon field="dispoI"></p-sortIcon></th>
                        <th pSortableColumn="dispoT" style="text-align: center;">Dispo. total <p-sortIcon field="dispoT"></p-sortIcon></th>
                        <th pSortableColumn="occup" style="text-align: center;" *ngIf="isTableauEntier">Occupés <p-sortIcon field="occup"></p-sortIcon></th>                    
                        <th pSortableColumn="indispo" style="text-align: center;">Indisponibles <p-sortIcon field="indispo"></p-sortIcon></th>          
                        <th pSortableColumn="entrees" style="text-align: center;">Entrées <p-sortIcon field="entrees"></p-sortIcon></th>          
                        <th pSortableColumn="sorties" style="text-align: center;">Sorties <p-sortIcon field="sorties"></p-sortIcon></th>
                        <th *ngIf="isTableauEntier"></th>
                        <th pSortableColumn="LTtotal" style="text-align: center;" *ngIf="isTableauEntier">Total <p-sortIcon field="LTtotal"></p-sortIcon></th>
                        <th pSortableColumn="LTouverts" style="text-align: center;" *ngIf="isTableauEntier">Ouverts <p-sortIcon field="LTouverts"></p-sortIcon></th>
                        <th pSortableColumn="LTdispo" style="text-align: center;" *ngIf="isTableauEntier">Disponibles <p-sortIcon field="LTdispo"></p-sortIcon></th>
                        <th>
                            <button class="btn-amc small blue" style="letter-spacing: 0; margin: 0;" (click)="affichageTableau()">
                                <span [ngClass]='isTableauEntier ? "fa-solid fa-minus" : "fa-solid fa-plus"'></span>
                            </button>
                        </th>
                    </tr>
                    <tr class="totauxClass" *ngIf="!isPrevisionnel">
                        <td style="text-align: left!important;">Totaux</td>
                        <td> {{ tdbTotal.total }} </td>
                        <td> {{ tdbTotal.capacitaireReel }} </td>
                        <td> {{ tdbTotal.dispoH }} </td>
                        <td> {{ tdbTotal.dispoF }} </td>
                        <td> {{ tdbTotal.dispoI }} </td>
                        <td> {{ tdbTotal.dispoT }} </td>
                        <td *ngIf="isTableauEntier"> {{ tdbTotal.occup }} </td>
                        <td> {{ tdbTotal.indispo }} </td>
                        <td> {{ tdbTotal.entrees }} </td>
                        <td> {{ tdbTotal.sorties }} </td>
                        <td *ngIf="isTableauEntier"></td>
                        <td *ngIf="isTableauEntier"> {{ tdbTotal.LTtotal }} </td>
                        <td *ngIf="isTableauEntier"> {{ tdbTotal.LTouverts }} </td>
                        <td *ngIf="isTableauEntier"> {{ tdbTotal.LTdispo }} </td>
                        <td></td>
                    </tr>

                    <tr *ngIf="isPrevisionnel">
                        <th pSortableColumn="nomOrder" rowspan="2">Service <p-sortIcon field="nomOrder"></p-sortIcon></th>
                        <th colspan="11" class="th-titre">Disponibilité prévisionnelle des lits</th>
                    </tr>
                    <tr *ngIf="isPrevisionnel">
                        <th *ngFor="let jour of joursPrevision" style="text-align:center">{{jour.libelle}} {{jour.dateObject | date:'dd/M'}}</th>
                    </tr>

                    <tr class="totauxClass" *ngIf="isPrevisionnel">
                        <td style="text-align: left!important;">Totaux</td>
                        <td *ngFor="let totalJ of previsionnelTotal">{{totalJ}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-unite>
                    <tr class="tableau-de-bord" *ngIf="!isPrevisionnel">
                        <td style="text-align: left!important;" [class]="getCouleurTension(unite.total,unite.dispoT)">
                            <div [pTooltip]="getDateHeure(unite)" class="TDBUniteNom1" *ngIf="unite.etablissement">{{unite.etablissement}}</div>
                            <div [pTooltip]="getDateHeure(unite)" [ngClass]="unite.etablissement ? 'TDBUniteNom2' : 'TDBUniteNom1'">{{unite.nom}}</div>
                        </td>
                        <td class="important">{{unite.total}}</td>
                        <td>{{unite.capacitaireReel}}</td>
                        <td *ngIf="!unite.editable">{{unite.dispoH}}</td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.dispoH"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.dispoH}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable">{{unite.dispoF}}</td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="3">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.dispoF"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.dispoF}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable">{{unite.dispoI}}</td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="4">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.dispoI"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.dispoI}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="important">{{unite.dispoT}}</td>
                        <td class="important" *ngIf="isTableauEntier && !unite.editable">{{unite.occup}}</td>
                        <td class="important" *ngIf="isTableauEntier && unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="18">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.occup"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.occup}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="important" *ngIf="!unite.editable">{{unite.indispo}}</td>
                        <td class="important" *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="7">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.indispo"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.indispo}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable">{{unite.entrees}}</td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="19">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.entrees"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.entrees}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable">{{unite.sorties}}</td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="20">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.sorties"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.sorties}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="isTableauEntier" class="important"></td>
                        <td *ngIf="isTableauEntier && !unite.editable" class="important">{{unite.LTtotal}}</td>
                        <td *ngIf="isTableauEntier && unite.editable" class="important" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="12">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.LTtotal"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.LTtotal}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="isTableauEntier && !unite.editable" class="important">{{unite.LTouverts}}</td>
                        <td *ngIf="isTableauEntier && unite.editable" class="important" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="13">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.LTouverts"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.LTouverts}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="isTableauEntier && !unite.editable" class="important">{{unite.LTdispo}}</td>
                        <td *ngIf="isTableauEntier && unite.editable" class="important" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="14">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber [min]="0" size="2" (keydown)="onKeyDown($event)" [(ngModel)]="unite.LTdispo"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{unite.LTdispo}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <!--Adaptation en fonction de la dernière colonne-->
                        <td [ngClass]='isTableauEntier ? "important" : ""'></td>
                    </tr>
                    <tr class="previsionnel" *ngIf="isPrevisionnel">
                        <td style="text-align: left!important;">
                            <div class="TDBUniteNom1" *ngIf="unite.etablissement">{{unite.etablissement}}</div>
                            <div [ngClass]="unite.etablissement ? 'TDBUniteNom2' : 'TDBUniteNom1'">{{unite.nom}}</div>
                        </td>
                        <!-- Excusez-moi pour les colonnes ci-dessous en dur au lieu de faire le ngFor... 
                             Mais il semblerait que Primeng présente un nouveau bug dans l'interaction entre les colonnes pEditableColumn et le ngFor.
                             Avec le ngFor, la touche Entrée n'est pas prise en compte dans certains cas de modifications d'une cellule...
                             Impossible de trouver pourquoi ou de contourner le problème. -->
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[0])">{{unite.previsionnel[0]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#0">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[0]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[0])">{{unite.previsionnel[0]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[1])">{{unite.previsionnel[1]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#1">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[1]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[1])">{{unite.previsionnel[1]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[2])">{{unite.previsionnel[2]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#2">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[2]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[2])">{{unite.previsionnel[2]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[3])">{{unite.previsionnel[3]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#3">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[3]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[3])">{{unite.previsionnel[3]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[4])">{{unite.previsionnel[4]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#4">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[4]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[4])">{{unite.previsionnel[4]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[5])">{{unite.previsionnel[5]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#5">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[5]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[5])">{{unite.previsionnel[5]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[6])">{{unite.previsionnel[6]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#6">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[6]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[6])">{{unite.previsionnel[6]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[7])">{{unite.previsionnel[7]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#7">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[7]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[7])">{{unite.previsionnel[7]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[8])">{{unite.previsionnel[8]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#8">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[8]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[8])">{{unite.previsionnel[8]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[9])">{{unite.previsionnel[9]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#9">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[9]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[9])">{{unite.previsionnel[9]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td *ngIf="!unite.editable"><span [class]="getClassValeur(unite.previsionnel[10])">{{unite.previsionnel[10]}}</span></td>
                        <td *ngIf="unite.editable" pEditableColumn [pEditableColumn]="unite" pEditableColumnField="15#10">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-inputNumber size="2" [(ngModel)]="unite.previsionnel[10]"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span [class]="getClassValeur(unite.previsionnel[10])">{{unite.previsionnel[10]}}</span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </tr>
                </ng-template>                
            </p-table>
        </div>
    </div>
</div>
<ng-template #loadingBlock>
    <div style="text-align: center">
        <sesame-loader></sesame-loader>
    </div>
</ng-template>