<form [formGroup]="mdpForm" class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-6">
        <label for="login" class="champObligatoire">Identifiant</label>
        <input id="login" formControlName="login" type="text" style="margin-bottom: 5%;" pInputText required/>
    </div>
    <div class="p-field p-col-6">
        <label for="password" class="champObligatoire">Mot de passe actuel</label>
        <input id="password" formControlName="password" type="password" [feedback]="false" style="margin-bottom: 5%;" pPassword required/>
    </div>
    <div class="p-field p-col-6">
        <label for="newPassword" class="champObligatoire">Nouveau mot de passe</label>
        <input id="newPassword" formControlName="newPassword" type="password" [feedback]="false" pPassword minlength="8" pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[!@#$%^&*()_+./\-]){1,}).{8,}" required/>
        <small>8 caractères minimum, une majuscule et un caractère spécial.</small>
    </div>
    <div class="p-field p-col-6">
        <label for="confrimPassword" class="champObligatoire">Confirmation du nouveau mot de passe</label>
                <input id="confrimPassword" formControlName="confrimPassword" type="password" [feedback]="false" pPassword minlength="8" pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[!@#$%^&*()_+./\-]){1,}).{8,}" required/>
    </div>
    <div class="p-field p-col-12 p-md-12">
        <button type="button" class="btn-amc green" (click)="validate()" [disabled]="mdpForm.invalid">
            <span class="icon-validate">&nbsp; Valider</span>
        </button>
    </div>
</form>
