import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { UserService } from '../../../authentification/services/user.service';

@Injectable()
export class AuthenticateGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    private messageService: MessageService
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isNaN(this.userService.currentUser?.id)) {
      this.messageService.add({
        severity: "error",
        summary: "Accès restreint",
        detail: "Vous devez vous connecter pour accéder à cette page."
      });
      this.router.navigate(['']);
      return false;
    } else {
      return true;
    }
  }

}
