import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { Secteur } from "../models/secteur";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";

@Injectable({
    providedIn: 'root'
  })
export class SecteurService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/gdl/secteur';

    add(secteur: Secteur): Observable<Secteur> {
      return this.http.post<Secteur>(this.url, secteur).pipe(map(s => plainToInstance(Secteur, s)));
    }

    update(secteur: Secteur): Observable<Secteur> {
      return this.http.put<Secteur>(this.url + "/" + secteur.id, secteur).pipe(map(s => plainToInstance(Secteur, s)));
    }
    
    remove(id: number) {
      return this.http.delete(this.url + "/" + id);
    }

    addSecteurUnites(id: number, uniteIds: number[]) {
      let parametres: string = "?";
      uniteIds.forEach(id => parametres += (parametres == "?" ? "" : "&") + "uniteIds=" + id);
      return this.http.post(this.url + "/" + id + "/unites" + parametres, null);
    }

    removeSecteurUnites(id: number, uniteIds: number[]) {
      let parametres: string = "?";
      uniteIds.forEach(id => parametres += (parametres == "?" ? "" : "&") + "uniteIds=" + id);
      return this.http.delete(this.url + "/" + id + "/unites" + parametres);
    }
}