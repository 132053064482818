import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../authentification/models/user';
import { UserService } from '../authentification/services/user.service';
import { TerritoireService } from '../shared/services/territoire.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  user: User;

  territoireId: number;
  territoireAdminId: number;
  territoireBedManagerId: number;

  isAdminAMC: boolean = false;
  isAdminTerritoire: boolean = false;
  isBedManagerTerritoire: boolean = false;

  constructor (
    private router: Router,
    private userService: UserService,
    private territoireService: TerritoireService
  ) { }

  ngOnInit() {
    this.user = this.userService.currentUser;

    this.isAdminAMC = this.user.isRole(1);

    this.territoireService.getAll().subscribe(territoires => {
      // On essaie de récupérer en priorité le premier des territoires qui sont associés à son établissement
      this.user.territoires.forEach(t => {
        if(!this.territoireId && territoires.find(ter => ter.id == t)) {
          this.territoireId = t;
        }
      });

      // Si on ne trouve pas, on prend le premier de la liste
      if(!this.territoireId) {
        this.territoireId = territoires[0].id;
      }
      
      // On parcourt tous les territoires associés à l'utilisateur pour déterminer :
      // - Le premier que l'on charge dans le tableau de bord
      // - Le premier que l'on charge en tant qu'administrateur de territoire dans les modules de paramétrage
      // - Le premier que l'on charge en tant que bed manager de territoire dans le module de paramétrage des secteurs de territoire
      // - La visibilité des options du menu Paramétrage
      territoires.forEach(t => {
        if(!this.territoireAdminId && this.user.isRoleTerritoire(2, t.id)) {
          this.territoireAdminId = t.id;
        }
        if(!this.territoireBedManagerId && this.user.isRoleTerritoire(3, t.id)) {
          this.territoireBedManagerId = t.id;
        }
      });

      //Si l'utilisateur n'est pas bed manager de territoire, on prend le droit admin de territoire s'il l'a
      if(!this.territoireBedManagerId) {
        this.territoireBedManagerId = this.territoireAdminId;
      
        //Si l'utilisateur n'est ni bed manager de territoire ni admin de territoire, on prend le droit admin AMC s'il l'a et on le met sur son territoire par défaut
        if(!this.territoireBedManagerId && this.isAdminAMC) {
          this.territoireBedManagerId = this.territoireId;
        }
      }

      //Si l'utilisateur n'est pas admin de territoire, on prend le droit admin AMC s'il l'a et on le met sur son territoire par défaut
      if(!this.territoireAdminId && this.isAdminAMC) {
        this.territoireAdminId = this.territoireId;
      }

      this.isAdminTerritoire = this.user.isRole(2) && this.territoireAdminId != null; // Sécurité permettant de s'assurer que l'utilisateur est admin de territoire d'un territoire toujours activé
      this.isBedManagerTerritoire = this.user.isRole(3) && this.territoireBedManagerId != null; // Sécurité permettant de s'assurer que l'utilisateur est bed manager de territoire d'un territoire toujours activé
    });
  }

  getButtonClass(buttonName: string) {
    let className = 'grey';
    if (this.router.url.includes('/tableau-de-bord')) {
      if (buttonName == 'tdb') className = 'orange';
    } else if (this.router.url.includes('/profil')) {
      if (buttonName == 'profil') className = 'orange';
    } else if (this.router.url.includes('/parametrage')) {
      if (buttonName == "params") className = 'orange';
    } else if (this.router.url.includes('/indicateurs')) {
      if (buttonName == "indic") className = 'orange';
    }
    return className;
  }

  logout() {
    this.userService.logout();
  }
}
