import { Type } from "class-transformer";
import { Fonction } from "./fonction";

export class CollaborateurFonction {
    id: number;
    fonctionId: number;
    collaborateurId: number;
  
    @Type(() => Fonction) fonction : Fonction;
}
