<div class="p-fluid p-formgrid p-grid" style="overflow: hidden">
    <div class="p-field p-col-5">
        <label class="champObligatoire">Rechercher</label>
        <input type="text" pInputText [(ngModel)]="texteRecherche" (input)="modificationRecherche()" />
    </div>
    <div class="p-col-12">
        <p-messages severity="info">
            <ng-template pTemplate>
                <i class="pi pi-info-circle" style="font-weight: 600; font-size: 1.5em"></i>
                <div class="p-ml-2">Double cliquez sur un établissement pour le sélectionner.</div>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-12" *ngIf="loading == false">
        <p-table
        #etabs
        [paginator]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 20, 30, 40, 50]"
        [autoLayout]="true"
        [value]="apiFinessResult?.records"
        [globalFilterFields]="['fields.raison_sociale', 'fields.etablissement', 'fields.code_postal', 'fields.libelle_commune', 'fields.dep']"
        >
            <ng-template pTemplate="caption">
                <div style="display: flex; justify-content: space-between">
                    <input
                        type="text"
                        pInputText
                        size="50"
                        placeholder="Rechercher"
                        style="width: 250px; margin-right: 20px"
                        (input)="etabs.filterGlobal($event.target.value, 'contains')"
                    />
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of configurationTableStructure" [pSortableColumn]="'fields.' + col.field">
                        {{ col.header }} <p-sortIcon field="code"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr (dblclick)="selectionEtablissement(record)" class="ligne">
                    <td *ngFor="let col of configurationTableStructure">{{ record.fields[col.field] }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-col-12" style="text-align: center" *ngIf="loading == true">
        <sesame-loader></sesame-loader>
    </div>
</div>
