import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { UserService } from '../../../authentification/services/user.service';
import { AuthenticateGuard } from '../authenticate/authenticate.guard';

@Injectable()
export class AdminAMCGuard implements CanActivate {

  constructor (
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private authenticateGuard: AuthenticateGuard
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authenticateGuard.canActivate()) {
      if(!this.userService.currentUser.isRole(1)) {
        this.messageService.add({
          severity: "error",
          summary: "Accès restreint",
          detail: "Vous n'avez pas les droits nécessaires pour administrer cette page."
        });
        this.router.navigate(['']);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

}
