<app-nav-menu></app-nav-menu>

<div class="container">
    <div style="display: flex; justify-content:space-between;">
        <div class="titre-parametrage" style="margin-bottom: 2%;">
            Paramétrage > <b>Gestion des indicateurs</b>
        </div>
        <div>
            <p-dropdown *ngIf="territoires.length > 1" id="territoire-etab" [options]="territoires"
                placeholder="Sélectionnez un territoire" [(ngModel)]="selectedTerritoire" optionLabel="nom"
                (onChange)="selectTerritoire()">
            </p-dropdown>
            <br />
            <p-dropdown *ngIf="selectedTerritoire" id="territoire-etab" [options]="etablissements"
                [(ngModel)]="selectedEtablissement" optionLabel="nom" (onChange)="selectEtablissement()">
            </p-dropdown>
        </div>
    </div>
    <!-- Section Filière longue dans une carte -->
    <div class="card p-col-8">
        <h3>Filière longue</h3>
        <p-table styleClass="p-datatable-sm" [value]="tableauSeuilsUrgence">
            <ng-template pTemplate="header">
                <tr>
                    <th class="p-md-1">Critère</th>
                    <th *ngFor="let seuil of seuils" class="p-md-1">{{ seuil.libelle }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tableauSeuilUrgence let-i="rowIndex">
                <tr>
                    <td>{{ tableauSeuilUrgence[0].libelle}}</td>
                    <td *ngFor="let seuil of seuils" [ngClass]="'seuil'+ seuil.id">
                        <p-inputNumber [(ngModel)]="tableauSeuilUrgence[seuil.id]" min="0"></p-inputNumber>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!-- Section UHCD dans une carte -->
    <div class="card">
        <h3>UHCD</h3>
        <p-table class="p-datatable-sm" [value]="tableauSeuilsUHCD">
            <ng-template pTemplate="header">
                <tr>
                    <th class="p-md-1">Critère</th>
                    <th *ngFor="let seuil of seuils" class="p-md-1">{{ seuil.libelle }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tableauSeuilUHCD let-i="rowIndex">
                <tr>
                    <td>{{ tableauSeuilUHCD[0].libelle}}</td>
                    <td *ngFor="let seuil of seuils" [ngClass]="'seuil'+ seuil.id">
                        <p-inputNumber [(ngModel)]="tableauSeuilUHCD[seuil.id]" min="0"></p-inputNumber>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12">
            <button type="button" class="btn-amc green" (click)="validate()" [disabled]="isSaving">
                <span class="icon-validate">&nbsp; Valider</span>
            </button>
        </div>
    </div>
</div>
<div style="text-align: center" *ngIf="isLoading">
    <sesame-loader></sesame-loader>
</div>