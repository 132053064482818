import { Secteur } from "../secteur";
import { TDB_Seuil_Etablissement } from "../tdb_seuil_etablissement";

 export class Etablissement {
    id: number;
    nom: string;

    finess_EJ: string;
    finess_Etab: string;
    siret: string;
    ville: string;
    mail: string;

    connecteurId?: number;
 
    secteurs: Secteur[];

    seuilEtablissement?: TDB_Seuil_Etablissement[];

 }