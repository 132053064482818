import { Etablissement } from "./etablissement";
import { Type } from "class-transformer";
import { CollaborateurRole } from "./collaborateurRole";
import { CollaborateurFonction } from "./collaborateurFonction";

 export class Collaborateur {
  id: number;
  nom: string;
  prenom: string;
  login : string;
  password: string;
  etablissementID : number;
  mail: string;
  civilite: string;
  mail2: string;
  telBureau: string;
  telPortable: string;
  isVisible: boolean;
  activite: Date;
  isActif: boolean;

  @Type(() => Etablissement) etablissement: Etablissement;
  @Type(() => CollaborateurRole) collaborateurRoles: CollaborateurRole[] = [];
  @Type(() => CollaborateurFonction) collaborateurFonctions: CollaborateurFonction[] = [];

  get getRolesHTML() : string {
    let rolesHTML: string = "";
    this.collaborateurRoles.forEach(cr => {
      rolesHTML += rolesHTML == "" ? cr.role.libelle : "<br />" + cr.role.libelle;
    });
    return rolesHTML;
  }

  get display(): string {
    return this.nom + " " + this.prenom;
  }
}