
<div class="inscription-container">
    <div class="p-fluid p-formgrid p-grid" style="width: 100%;">
        <div *ngIf="mode != 4" class="p-grid header">
            <div class="p-field p-col-12 p-md-12">
                <img src="../../assets/images/logo_amc_new.png" [routerLink]="''">
            </div>
        </div>
        <div class="p-field p-col-12 p-md-12 titre-parametrage">
            <span *ngIf="mode == 0 || mode == 1">Sésame Territoire > <b>Création de Compte</b></span>
            <span *ngIf="mode == 2">Paramétrage > Gestion de comptes > <b>Activer un compte</b></span>
            <span *ngIf="mode == 3">Paramétrage > Gestion de comptes > <b>Modifier un compte</b></span>
            <span *ngIf="mode == 4"><b>Mon Profil</b></span>
        </div>
        <div class="p-field p-col-12 p-md-12 p-d-flex p-d-flex-row message-alert">
            <div class="message-icon">
                <span class="fa-sharp fa-solid fa-circle-info"></span>
            </div>
            <div class="message-content">
                Les champs indiqués en <b style="color:#F0841A;">orange</b> sont des champs dont la saisie est obligatoire.
            </div>
        </div>
        <div class="p-field p-col-12 p-md-12" style="padding-right: 0;padding-left: 0;">
            <form [formGroup]="inscriptionForm" *ngIf="loading == false; else loadingBlock">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <p-card header="ÉTABLISSEMENT" id="pinkCard">
                            <ng-template pTemplate="header">
                                <button *ngIf="isSelectedEtab" type="button" class="btn-amc grey"
                                    style="float: right;margin-bottom: 0; width: 34px"
                                    pTooltip="Vider les champs" tooltipPosition="left" (click)="editEtablissement()">
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </ng-template>
                            <div *ngIf="mode == 0 || mode == 1 || mode == 3" class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12">
                                    <div class="p-field" [style]="'float: left;' + (mode != 4 ? 'width: calc(100% - 50px);' : '')">
                                        <p-dropdown [options]="etablissements" (onChange)="onEtabChange($event.value)"
                                            optionLabel="nom" [filter]="true" filterBy="nom" [showClear]="true" id="selectedEtab"
                                            formControlName="selectedEtab" placeholder="Sélectionnez votre établissement">
                                        </p-dropdown>
                                    </div>
                                    <div *ngIf="mode != 4" style="float: left;width: 50px;">
                                        <button type="button" class="btn-amc blue" style="margin: 0; margin-top: 2px;"
                                            pTooltip="Recherche avancée" tooltipPosition="left" (click)="rechercheApiFiness()">
                                            <span class="icon-search"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12">
                                    <label for="etabNom" class="champObligatoire">Nom</label>
                                    <input id="etabNom" formControlName="etabNom" type="text" pInputText required/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12">
                                    <label for="siret" class="champObligatoire">N° SIRET</label>
                                    <input id="siret" formControlName="siret" type="text" pInputText required/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-6">
                                    <label for="finess_ej" class="champObligatoire">N° FINESS Juridique</label>
                                    <input id="finess_ej" formControlName="finess_ej" type="text" pInputText required/>
                                </div>
                                <div class="p-field p-col-6">
                                    <label for="finess_etab" class="champObligatoire">N° FINESS Établissement</label>
                                    <input id="finess_etab" formControlName="finess_etab" type="text" pInputText required/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12">
                                    <label for="ville">Ville</label>
                                    <input id="ville" formControlName="ville" type="text" pInputText/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12">
                                    <label for="etabMail">Mail</label>
                                    <input id="etabMail" formControlName="etabMail" type="mail" pInputText />
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div class="p-field p-col-12 p-md-8">
                        <p-card header="COMPTE UTILISATEUR" id="orangeCard" class="p-field">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="civilite" class="champObligatoire">Civilité</label>
                                    <input id="civilite" formControlName="civilite" type="text" pInputText required/>
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="mail2">Mail 2</label>
                                    <input id="mail2" formControlName="mail2" type="mail" pInputText/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="nom" class="champObligatoire">Nom</label>
                                    <input id="nom" formControlName="nom" type="text" pInputText required/>
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="telBureau" class="champObligatoire">Téléphone bureau</label>
                                    <input id="telBureau" formControlName="telBureau" type="text" pInputText required/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="prenom" class="champObligatoire">Prénom</label>
                                    <input id="prenom" formControlName="prenom" type="text" pInputText required/>
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="tel">Téléphone portable</label>
                                    <input id="tel" formControlName="tel" type="text" pInputText/>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="fonction">Fonction(s)</label>
                                    <p-multiSelect [options]="fonctions" display="chip"
                                        optionLabel="libelle" formControlName="fonction"
                                        placeholder="Sélectionnez une ou plusieurs fonctions">
                                    </p-multiSelect>
                                </div>
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="identifiant" class="champObligatoire">Identifiant</label>
                                    <span class="p-input-icon-right">
                                        <i *ngIf="loadingLogin == true" class="pi pi-spin pi-spinner"></i>
                                        <i *ngIf="identifiantValid == true"  class="pi pi-check"></i>
                                        <i *ngIf="identifiantValid == false" class="pi pi-times"></i>
                                        
                                        <input #box id="identifiant" formControlName="login" (keyup)="identifiantSearch(box.value)" type="text" pInputText required/>
                                    </span>
                                </div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-6">
                                    <label for="mail" class="champObligatoire">Mail</label>
                                    <input id="mail" formControlName="mail" type="mail" pInputText required/>
                                </div>
                                <div *ngIf="mode != 3" class="p-field p-col-12 p-md-6">
                                    <label *ngIf="mode != 4" for="mdp" class="champObligatoire">Mot de passe</label>
                                    <span *ngIf="mode != 4" class="p-input-icon-right">
                                        <i *ngIf="mdpType == 'password'" class="pi pi-eye" (click)="switchMdpType()"></i>
                                        <i *ngIf="mdpType == 'text'" class="pi pi-eye-slash" (click)="switchMdpType()"></i>
                                        <input id="mdp" formControlName="password" [type]="mdpType" [feedback]="false" pPassword minlength="8"
                                            pattern="^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[!@#$%^&*()_+./\-]){1,}).{8,}" required />
                                    </span>
                                    <small *ngIf="mode != 4" id="username-help">8 caractères minimum, une majuscule et un caractère spécial.</small>

                                    <label *ngIf="mode == 4" class="champObligatoire">Mot de passe</label>
                                    <button *ngIf="mode == 4" id="mdpButton" type="button" class="btn-amc blue" style="width:100%; margin:0px;" (click)="openPopupModificationMdp()">
                                        <span class="fa-solid fa-key"></span>&nbsp;&nbsp;MODIFIER MON MOT DE PASSE
                                    </button>
                                </div>
                            </div>
                        </p-card>
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-12" style="margin-bottom: 0; margin-top: 1rem;">
                                <button *ngIf="mode == 4" type="button" class="btn-amc green" style="margin-right: 0;" (click)="validateMonProfil()" [disabled]="inscriptionForm.invalid">
                                    <span class="icon-validate">&nbsp; Valider</span>
                                </button>
        
                                <button *ngIf="mode == 2" type="button" class="btn-amc green" style="margin-right: 0;" (click)="activate()">
                                    <span class="fa-solid fa-user-plus"></span>&nbsp;&nbsp;Activer le compte
                                </button>
        
                                <button *ngIf="mode == 0 || mode == 1 || mode == 3" type="button" class="btn-amc green" style="margin-right: 0;"
                                    (click)="validate()" [disabled]="inscriptionForm.invalid">
                                    <span class="icon-validate">&nbsp; Valider</span>
                                </button>
        
                                <button *ngIf="mode != 4" type="button" class="btn-amc orange" (click)="return()">
                                    <span class="icon-cancel">&nbsp; Annuler</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <ng-template #loadingBlock>
            <div style="text-align: center">
                <sesame-loader></sesame-loader>
            </div>
        </ng-template>
    </div>
</div>