import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-idle-popup',
  templateUrl: './idle-popup.component.html',
  styleUrls: ['./idle-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IdlePopupComponent implements OnInit {
  timer: RadialTimer;

  @ViewChild("timer", { static: true }) timerEl: ElementRef;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.timer = new RadialTimer(this.timerEl.nativeElement, this.config.data.timeoutTimer, () => {
      this.closeDialog(false);
    });
    this.timer.start();
  }

  closeDialog(value: boolean){
    this.timer.stop();
    this.ref.close(value);
  }
}

class RadialTimer {
  seconds = 0;
  count = 0;
  degrees = 0;
  interval = null;
  timerContainer = null;
  number = null;
  slice = null;
  pieRight = null;
  pieLeft = null;
  quarter = null;
  onTimerEndCallback: any;

  constructor(htmlElement: HTMLElement, s: number, onTimerEndCallback) {
    this.timerContainer = htmlElement;
    this.number = this.timerContainer.getElementsByClassName("n")[0];
    this.slice = this.timerContainer.getElementsByClassName("slice")[0];
    this.pieRight = this.timerContainer.getElementsByClassName("pie r")[0];
    this.pieLeft = this.timerContainer.getElementsByClassName("pie l")[0];
    this.quarter = this.timerContainer.getElementsByClassName("q")[0];
    this.seconds = s;
    this.onTimerEndCallback = onTimerEndCallback;

  }

  start() {
    this.interval = window.setInterval(() => {
      this.number.innerHTML = this.seconds - this.count;
      this.count++;

      if (this.count > (this.seconds)) {
        clearInterval(this.interval);
        setTimeout(() => {
          this.onTimerEndCallback.call();
        }, 0);
      } else {
        this.degrees = this.degrees + (360 / this.seconds);
        if (this.count >= (this.seconds / 2)) {          
          this.slice.classList.add("nc");
          this.pieLeft.style["transform"] = "rotate(" + this.degrees + "deg)";
          if (!this.slice.classList.contains("mth")) this.pieRight.style["transform"] = "rotate(180deg)";
          this.slice.classList.add("mth");
          if (this.count >= (this.seconds * 0.75)) this.quarter.remove();
        } else {
          this.pieRight.style["transform"] = "rotate(" + this.degrees + "deg)";
          this.pieLeft.style["transform"] = "rotate(" + this.degrees + "deg)";
        }
      }

    }, 1000);
  }

  stop() {
    clearInterval(this.interval);
  }
}
