<app-nav-menu></app-nav-menu>
<div class="container">
    <div>
        <div style="display: flex; justify-content:space-between;">
            <div class="titre-parametrage">
                Paramétrage > <b>Secteurs et services</b>
            </div>
            <div *ngIf="!loading">
                <p-dropdown *ngIf="territoires.length > 1" id="territoire-etab" [options]="territoires" placeholder="Sélectionnez un territoire"
                    [(ngModel)]="selectedTerritoire" optionLabel="nom" (onChange)="selectTerritoire()">
                </p-dropdown>
                <br />
                <p-dropdown *ngIf="selectedTerritoire" id="territoire-etab" [options]="etablissements"
                    [(ngModel)]="selectedEtab" optionLabel="nom" (onChange)="selectEtablissement()">
                </p-dropdown>
            </div>
        </div>
        <div *ngIf="!loading && selectedTerritoire" cdkDropListGroup style="display: flex; flex-direction:row;">
            <!--Services des territoires-->
            <div class="card card-grey" style="margin-top: 30px;">
                <div class="card-header" style="justify-content:center;">
                    <h4 class="not-editable">Services</h4>
                </div>
                <div class="card-content" style="height: 100%!important;">
                    <div *ngIf="!selectedEtab.connecteurId" class="ajout-element">
                        <button class="btn-amc green" (click)="addOrUpdateService()">
                            <span class="icon-add">&nbsp; Ajouter un service</span>
                        </button>
                    </div>
                    <div class="elements" cdkDropList #servicesDragList="cdkDropList" [cdkDropListData]="services" cdkDropListSortingDisabled>
                        <div class="chip-grey" *ngFor="let service of services" cdkDrag #serviceDrag [cdkDragData]="service" [cdkDragDisabled]="selectedEtab.connecteurId"
                        (cdkDragStarted)="onDrag(service)">
                            <span>
                                {{service.nom}} <i *ngIf="service.code">({{ service.code }})</i>
                                <span class="capacitaire" *ngIf="service.getCapacitaireReel">
                                    <br/>
                                    {{service.getCapacitaireReel}} LIT{{service.getCapacitaireReel > 1 ? 'S' : ''}}
                                </span>
                            </span>
                            
                            &nbsp;
                            <div *ngIf="!selectedEtab.connecteurId" class="service-action">
                                <button class="btn-amc red p-button-sm" (click)="removeService(service)">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>

                                <button class="btn-amc blue p-button-sm" (click)="addOrUpdateService(service)">
                                    <i class="fa-solid fa-pen"></i>
                                </button>
                            </div>
                            <div *ngIf="selectedEtab.connecteurId" >
                                <p-inputSwitch [(ngModel)]="service.isActif" (onChange)="activateOrDesactivateService(service)"
                                    [pTooltip]="service.isActif ? 'Désactiver le service' : 'Réactiver le service'">
                                </p-inputSwitch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-container">
                <!--SECTEURS-->
                <div *ngFor="let secteur of secteurs" class="card card-grey">
                    <div class="card-header">
                        <h4 *ngIf="editingSecteurId != secteur.id" (click)="!this.selectedEtab.connecteurId ? onNomSecteurClick(secteur) : null" [style]="this.selectedEtab.connecteurId ? 'cursor:auto;' : ''">
                            {{secteur.nom}}
                        </h4>
                        <input [id]='"input-" + secteur.id' *ngIf="editingSecteurId == secteur.id" pInputText [(ngModel)]="secteur.nom" (focusout)="addOrUpdateSecteur(secteur)" 
                            (keypress)="keyPressSecteur($event, secteur)" />
                        <div *ngIf="!selectedEtab.connecteurId && secteur.id" class="delete-card" (click)="removeSecteur(secteur)"><span class="fa-solid fa-trash-can"></span></div>
                    </div>
                    <div class="card-content content-list" cdkDropList #servicesDuSecteur="cdkDropList" [cdkDragDisabled]="true" (cdkDropListDropped)="onDrop(secteur)" 
                        [cdkDropListData]="secteur.services" [cdkDropListEnterPredicate]="authorizeDrop()">
                        <div class="chip-grey full-width" *ngFor="let service of secteur.services">
                            <span [style]="!service.isActif ? 'opacity: 0.3;' : ''">
                                {{ service.nom }} <i *ngIf="service.code && service.code.length > 0">({{ service.code }})</i>
                            </span>
                            &nbsp;<span *ngIf="!selectedEtab.connecteurId" class="fa-regular fa-circle-xmark" (click)="removeServiceFromSecteur(secteur,service)"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!selectedEtab.connecteurId" class="card ajout" (click)="createSecteur()"><span class="fa-solid fa-plus"></span></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading" style="text-align: center">
    <sesame-loader></sesame-loader>
</div>