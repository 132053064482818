import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService} from 'primeng/api';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { PopUpRechercheAvanceeComponent } from 'src/app/inscription/pop-up-recherche-avancee/pop-up-recherche-avancee.component';

@Component({
  selector: 'app-pop-up-etablissement',
  templateUrl: './pop-up-etablissement.component.html',
  styleUrls: ['./pop-up-etablissement.component.scss']
})
export class PopUpEtablissementComponent implements OnInit {

  etablissementForm: UntypedFormGroup;

  etablissement: Etablissement;

  finessEtabExistants: string[] = [];

  loading: boolean = false;

  constructor (
    private formBuilder : UntypedFormBuilder,
    public dialogService: DialogService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public refLocale: DynamicDialogRef,
    private messageService: MessageService
  ) { }
  
  ngOnInit(): void {
    this.finessEtabExistants = this.config.data.finessEtabExistants;

    // Création
    if(!this.config.data.etablissement) {
      this.etablissementForm = this.formBuilder.group({
        nom: [null, Validators.required],
        siret: [null, Validators.required],
        finess_ej: [null, Validators.required],
        finess_etab: [null, Validators.required],
        ville: [null, Validators.required],
        mail: [null, Validators.required]
      });
    }
    // Modification
    else {
      this.etablissement = this.config.data.etablissement;
      this.etablissementForm = this.formBuilder.group({
        nom: [this.etablissement.nom, Validators.required],
        siret: [this.etablissement.siret, Validators.required],
        finess_ej: [this.etablissement.finess_EJ, Validators.required],
        finess_etab: [this.etablissement.finess_Etab, Validators.required],
        ville: [this.etablissement.ville, Validators.required],
        mail: [this.etablissement.mail, Validators.required]
      });
    }
  }

  rechercheApiFiness() {
    this.ref = this.dialogService.open(PopUpRechercheAvanceeComponent, {
      header: "Recherche avancée",
      width: "60%",
      contentStyle: { height: "auto", overflow: "auto" },
      data: {
        recherche: this.etablissementForm.get('nom').value
      },
    });

    this.ref.onClose.subscribe((etab: Etablissement) => {
      if(etab) {
        this.remplissageEtablissement(etab);
      }
    });
  }

  remplissageEtablissement(etab: Etablissement) {
    this.etablissementForm.get('nom').setValue(etab.nom);
    this.etablissementForm.get('siret').setValue(etab.siret);
    this.etablissementForm.get('finess_ej').setValue(etab.finess_EJ);
    this.etablissementForm.get('finess_etab').setValue(etab.finess_Etab);
    this.etablissementForm.get('ville').setValue(etab.ville);
  }

  validate() {
    let nom: string = this.etablissementForm.get('nom').value;
    let siret: string = this.etablissementForm.get('siret').value;
    let finess_ej: string = this.etablissementForm.get('finess_ej').value;
    let finess_etab: string = this.etablissementForm.get('finess_etab').value;
    let ville: string = this.etablissementForm.get('ville').value; 
    let mail: string = this.etablissementForm.get('mail').value; 

    if(!nom || nom.trim() == "" || !siret || siret.trim() == "" || !finess_ej || finess_ej.trim() == "" || !finess_etab || finess_etab.trim() == "") {
      this.messageService.add({ 
        severity: 'error',
        summary: 'Données non valides',
        detail:  'Veuillez remplir les champs obligatoires'
      });
    } else if((!this.etablissement && this.finessEtabExistants.includes(finess_etab))
              || (this.etablissement && this.finessEtabExistants.includes(finess_etab) && this.etablissement.finess_Etab != finess_etab)) {
      this.messageService.add({ 
        severity: 'error',
        summary: 'Données non valides',
        detail:  'Un établissement avec ce FINESS Établissement existe déjà.'
      });
    } else {
      let etablissement = !this.etablissement ? new Etablissement() : this.etablissement;
      etablissement.nom = nom;
      etablissement.finess_EJ = finess_ej;
      etablissement.finess_Etab = finess_etab;
      etablissement.mail = mail;
      etablissement.siret = siret;
      etablissement.ville = ville;
      
      //Passage de l'établissement à la page parente
      this.refLocale.close(etablissement);
    }
  }
}
