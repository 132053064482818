<form [formGroup]="etablissementForm" *ngIf="!loading; else loadingBlock">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-6">
            <div class="p-field" style="float: left; width: calc(100% - 50px)">
                <label for="nom" class="champObligatoire">Nom de l'établissement</label>
                <input id="nom" formControlName="nom" type="text" pInputText required/>
            </div>
            <div style="float: left;width: 50px;">
                <button type="button" class="btn-amc blue" style="margin: 0; margin-top: 29px;"
                    pTooltip="Recherche avancée" tooltipPosition="left" (click)="rechercheApiFiness()">
                    <span class="icon-search"></span>
                </button>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="siret" class="champObligatoire">N° SIRET</label>
            <input id="siret" formControlName="siret" type="text" pInputText/>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="finess_ej" class="champObligatoire">N° FINESS Juridique</label>
            <input id="finess_ej" formControlName="finess_ej" type="text" pInputText />
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="finess_etab" class="champObligatoire">N° FINESS Établissement</label>
            <input id="finess_etab" formControlName="finess_etab" type="text" pInputText />
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="ville">Ville</label>
            <input type="text" id="ville" formControlName="ville" pInputText/>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="mail">Mail</label>
            <input id="mail" formControlName="mail" type="text" pInputText/>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12" style="margin-bottom:0px;">
            <button type="button" class="btn-amc green" (click)="validate()">
                <span class="icon-validate">&nbsp; Valider</span>
            </button>
        </div>
    </div>
</form>
<ng-template #loadingBlock>
    <div style="text-align: center">
        <sesame-loader></sesame-loader>
    </div>
</ng-template>
