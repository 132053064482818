import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { User } from '../authentification/models/user';
import { UserService } from '../authentification/services/user.service';
import { TerritoireService } from '../shared/services/territoire.service';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccueilComponent implements OnInit {

  user: User;

  constructor(
    private userService: UserService,
    private route : ActivatedRoute,
    private router : Router,
    private messageService: MessageService,
    private territoireService: TerritoireService
  ) { }

  ngOnInit(): void {
    this.userService.user$.subscribe(user => {
      //On récupère l'utilisateur connecté
      this.user = user;

      // Si l'utilisateur est connecté, on redirige automatiquement vers le tableau de bord de son territoire
      // (s'il en a plusieurs, on prend le premier)
      if(this.user) {
        this.territoireService.getAll().subscribe(territoires => {
          if(territoires.length > 0) {
            let territoireId: number;
            // On essaie de récupérer en priorité le premier des territoires qui sont associés à son établissement
            this.user.territoires.forEach(t => {
              if(!territoireId && territoires.find(ter => ter.id == t)) {
                territoireId = t;
              }
            });
            // Si on ne trouve pas, on prend le premier de la liste
            if(!territoireId) {
              territoireId = territoires[0].id;
            }
            // Redirection
            this.router.navigate(['../' + territoireId + '/tableau-de-bord'], { relativeTo: this.route });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Accès restreint",
              detail: "Votre établissement n'est relié à aucun territoire."
            });
          }
        });
      }
    });
  }

  clickIdentification() {
    this.userService.login(this.router.url);
  }

}
