import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { Etablissement } from "../../models/annuaire/etablissement";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { TDB_Seuil_Etablissement } from "../../models/tdb_seuil_etablissement";

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {

  constructor(private http: HttpClient) { }
  
  private url = environment.url + 'api/ann/etablissement';

  getAll(): Observable<Etablissement[]> {
    return this.http.get<Etablissement[]>(this.url).pipe(map(e => plainToInstance(Etablissement, e)));
  }

  getEtablissementSeuilById(id: number): Observable<Etablissement> {
    return this.http.get<Etablissement>(this.url + "/" + id + "/etablissement-seuil").pipe(map(e => plainToInstance(Etablissement, e)));
  }

  addSeuil(id: number, newSeuilEtab: TDB_Seuil_Etablissement[]): Observable<TDB_Seuil_Etablissement> {
    return this.http.post<TDB_Seuil_Etablissement>(this.url + "/" + id + "/etablissement-seuil", newSeuilEtab).pipe(map(e => plainToInstance(TDB_Seuil_Etablissement, e)));
  }

  updateSeuil(id: number, seuilEtabToUpdate: TDB_Seuil_Etablissement[]): Observable<TDB_Seuil_Etablissement> {
      return this.http.put<TDB_Seuil_Etablissement>(this.url + "/" + id + "/etablissement-seuil", seuilEtabToUpdate).pipe(map(e => plainToInstance(TDB_Seuil_Etablissement, e)));
  }

  add(newEtab: Etablissement): Observable<Etablissement> {
    return this.http.post<Etablissement>(this.url, newEtab).pipe(map(e => plainToInstance(Etablissement, e)));
  }

  update(etabToUpdate: Etablissement): Observable<Etablissement> {
      return this.http.put<Etablissement>(this.url + "/" + etabToUpdate.id, etabToUpdate).pipe(map(e => plainToInstance(Etablissement, e)));
  }

  remove(id: number) {
      return this.http.delete(this.url + "/" + id);
  }

}
