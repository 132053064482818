import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule, LogLevel, OidcConfigService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { CallbackComponent } from './components/callback/callback.component';
import { LocalStorage } from './localStorage';
import { IdlePopupComponent } from './components/idle-popup/idle-popup.component';
import {DialogService} from 'primeng/dynamicdialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ConfigService } from '../shared/services/config/config.service';

@NgModule({
    declarations: [
        CallbackComponent,
        IdlePopupComponent
    ],
    imports: [
        CommonModule,
        AuthModule.forRoot({ storage: LocalStorage }),
        BrowserAnimationsModule
    ],
    providers: [
        OidcConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configureAuth,
            deps: [OidcConfigService, ConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        DialogService
    ]
})
export class AuthentificationModule { }

export function configureAuth(oidcConfigService: OidcConfigService) {
  return async () => {
    
    let originUrl = window.location.origin;
    let authUrl = originUrl  + "/identity"; 

    let openIdConfiguration: OpenIdConfiguration = {
      authority: authUrl,
      redirectUrl: originUrl+"/callback",
      clientId: 'sesameui',
      responseType: 'code',
      scope: 'openid profile offline_access ann roles',
      postLogoutRedirectUri: originUrl,
      forbiddenRoute: '',
      unauthorizedRoute: '',
      silentRenew: true,
      silentRenewUrl: originUrl + '/silent-renew.html',
      historyCleanupOff: true,
      autoUserInfo: true,
      logLevel: LogLevel.Error,
      maxIdTokenIatOffsetAllowedInSeconds: 120,
      useRefreshToken: true,
      authWellknownEndpoints: {
        issuer: authUrl,
        jwksUri: authUrl + '/.well-known/openid-configuration/jwks',
        authorizationEndpoint: authUrl + '/connect/authorize',
        tokenEndpoint: authUrl + '/connect/token',
        userInfoEndpoint: authUrl + '/connect/userinfo',
        endSessionEndpoint: authUrl + '/connect/endsession',
        checkSessionIframe: authUrl + '/connect/checksession',
        revocationEndpoint: authUrl + '/connect/revocation',
        introspectionEndpoint: authUrl + '/connect/introspect',
      }
    };

    

    await oidcConfigService.withConfigs([openIdConfiguration]);
  }
}

