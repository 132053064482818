<div class="p-d-flex p-flex-column p-ai-center">
    <div #timer class="radialtimer">
        <div class='n'></div>
        <div class='slice'>
            <div class='q'></div>
            <div class='pie r'></div>
            <div class='pie l'></div>
        </div>
    </div>
    <div class="p-d-flex p-jc-between" style="width: 70%;">
        <button type="button" class="btn-amc orange" (click)="closeDialog(false)"><span class="icon-cancel">&nbsp;
                Non</span></button>
        <button type="button" class="btn-amc green" type="submit" (click)="closeDialog(true)"><span class="icon-validate">&nbsp;
                Oui</span></button>
    </div>
</div>