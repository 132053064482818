import { AbstractSecurityStorage } from 'angular-auth-oidc-client';


export class LocalStorage implements AbstractSecurityStorage {
    clear(): void {
        
    }
    read(key: string) {
        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : value;
    }

    write(key: string, value: any): void {
        window.localStorage.setItem(key, JSON.stringify(value || null));
    }
    remove(key: string): void {
        window.localStorage.removeItem(key);
    }

}
