import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/authentification/services/user.service';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { Secteur } from 'src/app/shared/models/secteur';
import { Unite } from 'src/app/shared/models/unite';
import { SecteurService } from 'src/app/shared/services/secteur.service';
import { UniteService } from 'src/app/shared/services/unite.service';
import { PopUpServiceComponent } from './pop-up-service/pop-up-service.component';
import { Secteur_unite } from 'src/app/shared/models/secteur_unite';
import { Territoire } from 'src/app/shared/models/territoire';
import { TerritoireService } from 'src/app/shared/services/territoire.service';

@Component({
  selector: 'app-gestion-secteurs-services',
  templateUrl: './gestion-secteurs-services.component.html',
  styleUrls: ['./gestion-secteurs-services.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GestionSecteursServicesComponent implements OnInit {

  territoires: Territoire[] = [];
  selectedTerritoire: Territoire;

  editingSecteurId: number;
  editingSecteurNom: string;
  serviceDragged: Unite;

  etablissements: Etablissement[] = [];
  selectedEtab: Etablissement;
  secteurs: Secteur[] = [];
  services: Unite[] = [];

  refService: DynamicDialogRef;

  loading: boolean = false;

  constructor (
    private confirmationService : ConfirmationService, 
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private uniteService: UniteService,
    private secteurService: SecteurService,
    private territoireService: TerritoireService,
    private messageService: MessageService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      if (this.route.snapshot.data.etablissements) {
        this.etablissements = this.route.snapshot.data.etablissements;
        if(this.etablissements.find(e => e.id == this.userService.currentUser.etablissementId)) {
          this.selectedEtab = this.etablissements.find(e => e.id == this.userService.currentUser.etablissementId);
        } else {
          this.selectedEtab = this.etablissements[0];
        }

        this.loading = true;
        this.territoireService.getAll().subscribe(territoires => {
          this.territoires = territoires;
          this.selectedTerritoire = this.territoires.find(ter => ter.id == parseInt(routeParams.id));

          if(this.selectedTerritoire) {
            this.selectEtablissement();
          } else {
            this.loading = false;
          }
        });
      }
    });
  }

  selectTerritoire() {
    this.router.navigate([this.selectedTerritoire.id + '/parametrage/gestion-secteurs-services']);
  }

  selectEtablissement() {
    this.loading = true;

    // Récupération des secteurs liés à l'établissement
    this.secteurs = this.selectedEtab.secteurs;

    // Récupération des services liés à l'établissement
    this.uniteService.getByEtablissementIds([this.selectedEtab.id]).subscribe(unites => {
      this.services = unites;
      this.loading = false;
    });

    // Mise en forme des services dans le secteur
    this.secteurs.forEach(secteur => {
      secteur.services = secteur.secteurUnites.map(su => su.unite);
    });
  }

  // #region ------------- UNITÉS / SERVICES -------------

  removeService(service: Unite) {
    this.confirmationService.confirm({
      message: "Souhaitez-vous vraiment supprimer le service <b>" + service.nom + "</b> ? Attention, cette action est définitive.",
      accept: () => {
        // Modification bdd
        this.uniteService.remove(service.id).subscribe(() => {
          // Mise à jour front
          this.services = this.services.filter(s => s.id != service.id);
          this.secteurs.forEach(s => {
            s.services = s.services.filter(ss => ss.id != service.id);
            s.secteurUnites = s.secteurUnites.filter(su => su.uniteID != service.id);
          });
          this.selectedEtab.secteurs = this.secteurs;

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le service a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESSION SERVICE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du service',
            detail: 'Le service n\'a pas pu être supprimé. Veuillez réessayer.'
          });
        });
      }
    });
  }

  addOrUpdateService(service?: Unite) {
    this.refService = this.dialogService.open(PopUpServiceComponent, {
      header: !service ? "CRÉER UN NOUVEAU SERVICE" : "MODIFIER LE SERVICE",
      width: "50%",
      contentStyle: { height: "auto", overflow: "visible" },
      data: {
        isNew: !service,
        service: service ? service : null
      }
    });

    this.refService.onClose.subscribe(unite => {
      if(unite) {
        // Ajout
        if(!service) {
          unite.etablissementID = this.selectedEtab.id;
          
          this.uniteService.add(unite).subscribe(newUnite => {
            // Mise à jour front
            this.services.push(newUnite);

            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'Le service a bien été créé.'
            });
          },
          (e) => {
            console.error("ERREUR CREATION SERVICE");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la création du service',
              detail: 'Le service n\'a pas pu être créé. Veuillez réessayer.'
            });
          });
        }
        // Modification
        else {
          this.uniteService.update(unite).subscribe(updatedUnite => {
            // Mise à jour front
            this.services.filter(s => s.id == updatedUnite.id).forEach(s => {
              s.nom = updatedUnite.nom;
              s.code = updatedUnite.code;
              s.capacitaires = updatedUnite.capacitaires;
            });
            this.secteurs.filter(s => s.services.map(ss => ss.id).includes(updatedUnite.id)).forEach(s => {
              s.services.filter(ss => ss.id == updatedUnite.id).forEach(ss => {
                ss.nom = updatedUnite.nom;
                ss.code = updatedUnite.code;
                ss.capacitaires = updatedUnite.capacitaires;
              });
            });
            this.selectedEtab.secteurs = this.secteurs;

            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'Le service a bien été mis à jour.'
            });
          },
          (e) => {
            console.error("ERREUR MODIFICATION SERVICE");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la modification du service',
              detail: 'Le service n\'a pas pu être modifié. Veuillez réessayer.'
            });
          });
        }
      }
    });
  }

  activateOrDesactivateService(service: Unite) {
    this.uniteService.update(service).subscribe(() => {
      // Mise à jour du front
      this.secteurs.filter(s => s.services.map(ss => ss.id).includes(service.id)).forEach(s => {
        s.services.filter(ss => ss.id == service.id).forEach(ss => {
          ss.isActif = service.isActif;
        });
      });
      this.selectedEtab.secteurs = this.secteurs;

      this.messageService.add({
        severity: 'success',
        summary: 'Données enregistrées avec succès !',
        detail:  'Le service a bien été ' + (service.isActif ? 'réactivé' : 'désactivé') + '.'
      });
    },
    (e) => {
      console.error("ERREUR (DÉS)ACTIVATION SERVICE");
      console.error(e);

      this.messageService.add({
        severity: 'error',
        summary: 'Erreur lors de la modification du service',
        detail: 'Le service n\'a pas pu être ' + (service.isActif ? 'réactivé' : 'désactivé') + '. Veuillez réessayer.'
      });
    });
  }

  // #endregion

  // #region ------------- SECTEURS -------------

  onNomSecteurClick(secteur: Secteur) {
    this.editingSecteurId = secteur.id;
    this.editingSecteurNom = secteur.nom;
    setTimeout(() => {
      document.getElementById('input-' + secteur.id).focus();
    }, 100);
  }

  keyPressSecteur(event : KeyboardEvent, secteur: Secteur) {
    if(event.key == 'Enter') {
      this.addOrUpdateSecteur(secteur);
    }
  }

  createSecteur() {
    // Mise en forme du nouveau secteur
    let newSecteur: Secteur = new Secteur();
    newSecteur.etablissementId = this.selectedEtab.id;
    this.secteurs.push(newSecteur);
    this.onNomSecteurClick(newSecteur);
  }

  removeSecteur(secteur: Secteur) {
    this.confirmationService.confirm({
      message: "Souhaitez-vous vraiment supprimer le secteur <b>" + secteur.nom + "</b> ? Attention, cette action est définitive.",
      accept: () => {
        this.secteurService.remove(secteur.id).subscribe(() => {
          // Mise à jour front
          this.secteurs = this.secteurs.filter(s => s.id != secteur.id);
          this.selectedEtab.secteurs = this.secteurs;

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le secteur a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du secteur',
            detail: 'Le secteur n\'a pas pu être supprimé. Veuillez réessayer.'
          });
        })
      }
    });
  }

  addOrUpdateSecteur(secteur: Secteur) {
    this.editingSecteurId = null;
    if(!secteur.id) {
      if(!secteur.nom || secteur.nom == '') {
        this.secteurs.pop();
      } else {
        this.secteurService.add(secteur).subscribe(s => {
          // Mise à jour front
          this.secteurs.pop();
          s.services = [];
          s.secteurUnites = [];
          this.secteurs.push(s);
          this.selectedEtab.secteurs = this.secteurs;
  
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le secteur a bien été créé.'
          });
        },
        (e) => {
          console.error("ERREUR CREATION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la création du secteur',
            detail: 'Le secteur n\'a pas pu être créé. Veuillez réessayer.'
          });
        });
      }
    } else {
      if(!secteur.nom || secteur.nom == '') {
        secteur.nom = this.editingSecteurNom;
        this.editingSecteurNom = null;
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur lors de la modification du secteur',
          detail: 'Le nom du secteur ne doit pas être vide.'
        });
      } else if(this.editingSecteurNom != secteur.nom) {
        this.secteurService.update(secteur).subscribe(() => {
          this.editingSecteurNom = null;
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le secteur a bien été modifié.'
          });
        },
        (e) => {
          secteur.nom = this.editingSecteurNom;
          this.editingSecteurNom = null;
          console.error("ERREUR MODIFICATION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification du secteur',
            detail: 'Le secteur n\'a pas pu être modifié. Veuillez réessayer.'
          });
        });
      }
    }
  }

  // #endregion

  // #region ------------- GESTION DES UNITÉS / SERVICES DANS LES SECTEURS -------------
  
  removeServiceFromSecteur(secteur: Secteur, service: Unite) {
    this.confirmationService.confirm({
      message:"Voulez vous supprimer le service <b>" + service.nom + "</b> du secteur <b>" + secteur.nom + "</b> ?",
      accept: () => {
        // Modification en bdd
        this.secteurService.removeSecteurUnites(secteur.id, [service.id]).subscribe(() => {
          // Mise à jour front
          secteur.services = secteur.services.filter(s => s.id != service.id);
          secteur.secteurUnites = secteur.secteurUnites.filter(su => su.uniteID != service.id);

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le service a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESSION SERVICE DANS SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du service dans le secteur',
            detail: 'Le service n\'a pas pu être supprimé du secteur. Veuillez réessayer.'
          });
        });
      }
    });
  }

  onDrop(secteur: Secteur) {
    // Ajouter en bdd
    this.secteurService.addSecteurUnites(secteur.id, [this.serviceDragged.id]).subscribe(() => {
      // Mise à jour front
      secteur.services.push(this.serviceDragged);
      secteur.secteurUnites.push({ secteurID: secteur.id, uniteID: this.serviceDragged.id, unite: this.serviceDragged } as Secteur_unite);

      this.messageService.add({
        severity: 'success',
        summary: 'Données enregistrées avec succès !',
        detail:  'Le service a bien été ajouté.'
      });
    },
    (e) => {
      console.error("ERREUR AJOUT SERVICE DANS LE SECTEUR");
      console.error(e);

      this.messageService.add({
        severity: 'error',
        summary: 'Erreur lors de l\'ajout du service dans le secteur',
        detail: 'Le service n\'a pas pu être ajouté dans le secteur. Veuillez réessayer.'
      });
    });
  }

  onDrag(service: Unite) {
    this.serviceDragged = service;
  }

  authorizeDrop() {
    return function(drag: CdkDrag, drop: CdkDropList) {
      return drop.data?.find(e => e.id == drag.data.id) ? false : true;
    };
  }

  // #endregion
}