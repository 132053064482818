import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sesameDate' })
export class SesameDatePipe implements PipeTransform {
  transform(date: any, format: string){
    //Contrôle si timezone renseignée, si oui récupère manuellement
    var dateLength = date?.toString().length;
    //si la date n'est pas renseignée ou null ou pas définie, alors on ne passe pas dans cette condition
    if (date && dateLength > 5 && /^[+-][0-2][0-9]:[0-5][0-9]$/.test(date.toString().substring(dateLength-6,dateLength)))
    {
        var timeZone = date.toString().substring(dateLength-6,dateLength);
        return new DatePipe("en-US").transform(date,format,timeZone);
    } 
    else{    
        return new DatePipe("en-US").transform(date,format);
    }
  }
}