import { Component, OnInit } from '@angular/core';
import { User } from '../authentification/models/user';
import { UserService } from '../authentification/services/user.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  currentUser: User;

  constructor() { }

  ngOnInit(): void {
  }

}
