import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { TDB_Champs } from "../models/tdb_champs";

@Injectable({
    providedIn: 'root'
  })
  export class TDB_ChampsService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/gdl/tdb_champs';
    
    getAllUrgence(): Observable<TDB_Champs[]> {
      return this.http.get<TDB_Champs[]>(this.url).pipe(map(t => plainToInstance(TDB_Champs, t)));
    }

  }