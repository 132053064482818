import { Type } from "class-transformer";
import { Role } from "./role";

 export class CollaborateurRole {
  id: number;
  roleId: number;
  collaborateurId: number;
  territoireId?: number;

  @Type(() => Role) role: Role;
}