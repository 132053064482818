<app-nav-menu></app-nav-menu>
<div class="container">
    <div class="titre-parametrage" style="margin-bottom: 2%;">
        Paramétrage > <b>Gestion des comptes</b>
    </div>
    <div *ngIf="!loading">
        <div class="p-fluid p-formgrid p-grid" style="margin-right:0rem;margin-left:0rem;">
            <div class="p-field p-col-12 p-md-12 indicateurs">
                <div class="p-fluid p-formgrid p-grid" style="height:100%">
                    <div class="p-field p-col-3 indicateurBox" (click)="filtre(0)" [ngClass]="{'filtreSelected': filterSelected == 0}">
                        <div class="numbers">{{ allCollaborateurs.length }}</div>
                        <span class="info">{{ allCollaborateurs.length > 1 ? 'Comptes Créés' : 'Compte Créé' }}</span>
                    </div>
                    <div class="p-field p-col-3 indicateurBox" (click)="filtre(1)" [ngClass]="{'filtreSelected': filterSelected == 1}">
                        <div class="numbers">{{ collaborateursActif.length }}</div>
                        <span class="info">{{ collaborateursActif.length > 1 ? 'Comptes Actifs' : 'Compte Actif' }}</span>
                    </div>
                    <div class="p-field p-col-3 indicateurBox" (click)="filtre(2)" [ngClass]="{'filtreSelected': filterSelected == 2}">
                        <div class="numbers">{{ collaborateursInactif.length }}</div>
                        <span class="info">{{ collaborateursInactif.length > 1 ? 'Comptes Inactifs' : 'Compte Inactif' }}</span>
                    </div>
                    <div class="p-field p-col-3 indicateurBox" (click)="filtre(3)" [ngClass]="{'filtreSelected': filterSelected == 3}">
                        <div class="numbers">{{ bedManageurs.length }}</div>
                        <span class="info">{{ bedManageurs.length > 1 ? 'Bed Managers Territoire' : 'Bed Manager Territoire' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <button class="btn-amc green" (click)="addColaborateur()" style="float: left">
                    <span class="icon-add">&nbsp; Ajouter un compte</span>
                </button>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <p-table #tComptes [value]="collaborateurs"
                    [globalFilterFields]="['nom','prenom','mail','etablissement.nom','getRolesHTML','activite']"
                    [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rows]="10" [autoLayout]="true" [showCurrentPageReport]="true"
                    currentPageReportTemplate=" {first} à {last} parmi {totalRecords} comptes" >
                    <ng-template pTemplate="caption">
                        <div style="display: flex; justify-content: space-between;">
                            <!-- INPUT DE RECHERCHE -->
                            <input type="text" pInputText size="50" placeholder="Rechercher"
                                (input)="tComptes.filterGlobal($event.target.value, 'contains')"
                                style="width: 250px; margin-right: 20px;">
                        </div>   
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="nom">
                                Nom <p-sortIcon field="nom"></p-sortIcon>
                            </th>
                            <th pSortableColumn="prenom">
                                Prénom <p-sortIcon field="prenom"></p-sortIcon>
                            </th>
                            <th pSortableColumn="mail">
                                Mail <p-sortIcon field="mail"></p-sortIcon>
                            </th>
                            <th pSortableColumn="etablissement.nom">
                                Etablissement <p-sortIcon field="etablissement.nom"></p-sortIcon>
                            </th>
                            <th pSortableColumn="getRolesHTML">
                                Rôle(s) <p-sortIcon field="getRolesHTML"></p-sortIcon>
                            </th>
                            <th pSortableColumn="activite">
                                Dernière activité <p-sortIcon field="activite"></p-sortIcon>
                            </th>
                            <th>

                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c>
                        <tr>
                            <td style="border-left: none; border-right: none; text-align: left!important;">
                                {{ c.nom }}
                            </td>
                            <td style="border-left: none; border-right: none; text-align: left!important;">
                                {{ c.prenom }}
                            </td>
                            <td style="border-left: none; border-right: none; text-align: left!important;">
                                {{ c.mail || '-' }}
                            </td>
                            <td style="border-left: none; border-right: none; text-align: left!important;">
                                {{ c.etablissement.nom }}
                            </td>
                            <td *ngIf="c.collaborateurRoles.length > 0; else elseCollaborateurRoles" style="border-left: none; border-right: none; text-align: left!important;">
                                <span [innerHTML]="c.getRolesHTML"></span>
                            </td>
                            <ng-template #elseCollaborateurRoles>
                                <td>-</td>
                            </ng-template>
                            <td style="border-left: none; border-right: none; text-align: left!important;">
                                {{ c.activite ? (c.activite | date: "dd/MM/yyyy HH:mm") : '-' }}
                            </td>
                            <td style="border-left: none; border-right: none;">
                                <button class="btn-amc red" 
                                (click)="deleteCollaborateur(c)"
                                pTooltip="Supprimer le collaborateur"
                                tooltipPosition="bottom" 
                                style="margin-left: 5px; margin-right: 5px;">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                                <button class="btn-amc blue"
                                (click)="editCollaborateur(c)"
                                pTooltip="Modifier le collaborateur"
                                tooltipPosition="bottom"
                                style="margin-left: 5px; margin-right: 5px;">
                                    <i class="fa-solid fa-pen"></i>
                                </button>
                                <button *ngIf="!c.isActif"
                                class="btn-amc green"
                                (click)="activateCollaborateur(c)"
                                pTooltip="Activer le collaborateur"
                                tooltipPosition="bottom"
                                style="margin-left: 5px; margin-right: 5px;">
                                    <i class="fa-solid fa-user-plus"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
<div style="text-align: center" *ngIf="loading">
    <sesame-loader></sesame-loader>
</div>


