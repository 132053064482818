import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: any;

  constructor(private http: HttpClient) {
  }

  init() {
    return this.http.get<any>('./assets/config.json').toPromise().then(data =>{
      this.config = data;
    });
  }

  get(key: string) {
    return this.config[key];
  }
}
