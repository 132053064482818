import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { Territoire } from 'src/app/shared/models/territoire';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EtablissementService } from 'src/app/shared/services/annuaire/etablissement.service';
import { TerritoireService } from 'src/app/shared/services/territoire.service';
import { TerritoireEtablissement } from 'src/app/shared/models/territoire_etablissement';
import { PopUpEtablissementComponent } from './pop-up-etablissement/pop-up-etablissement.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-territoires',
  templateUrl: './territoires.component.html',
  styleUrls: ['./territoires.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TerritoiresComponent implements OnInit {

  // L'ensemble des territoires et établissements de la plateforme
  territoires: Territoire[] = []; 
  etablissements: Etablissement[] = [];

  // Établissements par territoires
  etablissementsTerritoires: Etablissement[][] = [];

  // Liste d'établissements à afficher (en fonction du filtre de recherche)
  etablissementsAffiches: Etablissement[] = [];

  editingTerritoireId: number;
  editingTerritoireNom: string;

  etablissementDragged: Etablissement;

  searchText: string = "";

  refEtab: DynamicDialogRef;

  loading: boolean = false;

  constructor (
    private confirmationService : ConfirmationService, 
    private territoireService: TerritoireService,
    private etablissementService: EtablissementService,
    private messageService: MessageService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    combineLatest([
      this.etablissementService.getAll(),
      this.territoireService.getAll()
    ])
    .subscribe(([etablissements, territoires]) => {
      this.loading = false;
      this.etablissements = etablissements;
      
      //Initialement on affiche tous les établissements de sesame-territoire
      this.etablissementsAffiches = [...etablissements];

      this.territoires = territoires;

      //Récupération des liaisons territoires et établissements
      let territoireEtablissemnt: TerritoireEtablissement[][] = territoires.map(ter => ter.territoireEtablissements);
      territoires.forEach((territoire, index) => {
        // Stockage des établissements de chaque territoire indexés dans la liste par l'identifiant du territoire
        this.etablissementsTerritoires[territoire.id] = etablissements.filter(etab => territoireEtablissemnt[index].filter(te => te.etablissementID == etab.id).length > 0);
      });
    });
  }

  // #region ------------- ÉTABLISSEMENTS -------------

  removeEtablissement(etab: Etablissement) {
    this.confirmationService.confirm({
      message: "Souhaitez-vous vraiment supprimer l'établissement <b>" + etab.nom + "</b> ? Attention, cette action est définitive.",
      accept: () => {
        // Modification bdd
        this.etablissementService.remove(etab.id).subscribe(() => {
          // Mise à jour front
          this.etablissements = this.etablissements.filter(e => e.id != etab.id);
          this.territoires.filter(t => t.territoireEtablissements.map(te => te.etablissementID).includes(etab.id)).forEach(t => {
            t.territoireEtablissements = t.territoireEtablissements.filter(te => te.etablissementID != etab.id);
            this.etablissementsTerritoires[t.id] = this.etablissementsTerritoires[t.id].filter(e => e.id != etab.id);
          });
          this.filtrer();

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'L\'établissement a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESSION ÉTABLISSEMENT");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression de l\'établissement',
            detail: 'L\'établissement n\'a pas pu être supprimé. Veuillez réessayer.'
          });
        });
      }
    });
  }

  addOrUpdateEtablissement(etab?: Etablissement) {
    this.refEtab = this.dialogService.open(PopUpEtablissementComponent, {
      header: "CRÉER UN NOUVEL ÉTABLISSEMENT" ,
      width: "50%",
      contentStyle: { height: "auto", overflow: "visible" },
      data: {
        etablissement: etab,
        finessEtabExistants: this.etablissements.map(e => e.finess_Etab)
      }
    });

    this.refEtab.onClose.subscribe(etablissement => {
      if(etablissement) {
        // Ajout
        if(!etablissement.id) {
          this.etablissementService.add(etablissement).subscribe(newetablissement => {
            // Mise à jour front
            this.etablissements.push(newetablissement);
            this.filtrer();
  
            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'L\'établissement a bien été créé.'
            });
          },
          (e) => {
            console.error("ERREUR CRÉATION ÉTABLISSEMENT");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la création de l\'établissement',
              detail: 'L\'établissement n\'a pas pu être créé. Veuillez réessayer.'
            });
          });
        }
        // Modification
        else {
          this.etablissementService.update(etablissement).subscribe(updatedEtab => {
            // Mise à jour front
            this.etablissements.filter(e => e.id == updatedEtab.id).forEach(e => {
              e.nom = updatedEtab.nom;
              e.siret = updatedEtab.siret;
              e.finess_EJ = updatedEtab.finess_EJ;
              e.finess_Etab = updatedEtab.finess_Etab;
              e.ville = updatedEtab.ville;
              e.mail = updatedEtab.mail;
            });
            this.territoires.filter(t => t.territoireEtablissements.map(te => te.etablissementID).includes(updatedEtab.id)).forEach(t => {
              this.etablissementsTerritoires[t.id].filter(e => e.id == updatedEtab.id).forEach(e => {
                e.nom = updatedEtab.nom;
                e.siret = updatedEtab.siret;
                e.finess_EJ = updatedEtab.finess_EJ;
                e.finess_Etab = updatedEtab.finess_Etab;
                e.ville = updatedEtab.ville;
                e.mail = updatedEtab.mail;
              });
            });
            this.filtrer();
  
            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'L\'établissement a bien été mis à jour.'
            });
          },
          (e) => {
            console.error("ERREUR MODIFICATION ÉTABLISSEMENT");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la modification de l\'établissement',
              detail: 'L\'établissement n\'a pas pu être modifié. Veuillez réessayer.'
            });
          });
        }
      }
    });
  }

  // Filtrer la liste des établissements en fonction du motif de recherche
  filtrer() {
    this.etablissementsAffiches = this.etablissements.filter(etab => !this.searchText || this.searchText == ''
                                                                     || etab.nom.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()));
  }

  // #endregion

  // #region ------------- TERRITOIRES -------------

  onNomTerritoireClick(territoire: Territoire) {
    this.editingTerritoireId = territoire.id;
    this.editingTerritoireNom = territoire.nom;
    
    setTimeout(() => {
      document.getElementById('input-' + territoire.id).focus();
    }, 100);
  }

  keyPressTerritoire(event : KeyboardEvent, territoire: Territoire) {
    if(event.key == 'Enter') {
      this.addOrUpdateTerritoire(territoire);
    }
  }

  createTerritoire() {
    let territoire = new Territoire();
    this.territoires.push(territoire);
    this.onNomTerritoireClick(territoire);
  }

  removeTerritoire(territoire: Territoire) {
    this.confirmationService.confirm({
      message: "Souhaitez-vous vraiment supprimer le territoire <b>" + territoire.nom + "</b> ? Attention, cette action est définitive.",
      accept: () => {
        this.territoireService.remove(territoire.id).subscribe(() => {
          // Mise à jour front
          this.territoires = this.territoires.filter(s => s.id != territoire.id);
          this.etablissementsTerritoires[territoire.id] = [];

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le territoire a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESION TERRITOIRE");
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du territoire',
            detail: 'Le territoire n\'a pas pu être supprimé. Veuillez réessayer.'
          });
        })
      }
    });
  }

  addOrUpdateTerritoire(territoire: Territoire) {
    this.editingTerritoireId = null;
    if(!territoire.id) {
      if(!territoire.nom || territoire.nom == '') {
        this.territoires.pop();
      } else {
        this.territoireService.add(territoire).subscribe(t => {
          // Mise à jour front
          this.territoires.pop();
          this.territoires.push(t);
  
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le territoire a bien été créé.'
          });
        },
        (e) => {
          console.error("ERREUR CRÉATION DU TERRITOIRE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la création du territoire',
            detail: 'Le territoire n\'a pas pu être créé. Veuillez réessayer.'
          });
        });
      }
    } else {
      if(!territoire.nom || territoire.nom == '') {
        territoire.nom = this.editingTerritoireNom;
        this.editingTerritoireNom = null;
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur lors de la modification du territoire',
          detail: 'Le nom du territoire ne doit pas être vide.'
        });
      } else if(this.editingTerritoireNom != territoire.nom) {
        this.territoireService.update(territoire).subscribe(() => {
          this.editingTerritoireNom = null;
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le territoire a bien été modifié.'
          });
        },
        (e) => {
          territoire.nom = this.editingTerritoireNom;
          this.editingTerritoireNom = null;
          console.error("ERREUR MODIFICATION TERRITOIRE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification du territoire',
            detail: 'Le territoire n\'a pas pu être modifié. Veuillez réessayer.'
          });
        });
      }
    }
  }

  // #endregion

  // #region ------------- GESTION DES ÉTABLISSEMENTS DANS LES TERRITOIRES -------------

  removeEtablissementFromTerritoire(territoire: Territoire, etablissement: Etablissement) {
    this.confirmationService.confirm({
      message:"Voulez vous supprimer l\'établissement <b>" + etablissement.nom + "</b> du territoire <b>" + territoire.nom + "</b> ?",
      accept: () => {
        // Modification en bdd
        this.territoireService.removeTerritoireEtablissement(territoire.id, etablissement.id).subscribe(
          () => {
            this.etablissementsTerritoires[territoire.id] = this.etablissementsTerritoires[territoire.id].filter(e => e.id != etablissement.id);
            territoire.territoireEtablissements = territoire.territoireEtablissements.filter(te => te.etablissementID != etablissement.id);

            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'L\'établissement a bien été supprimé.'
            });
          },
          (e) => {
            console.error("ERREUR ÉTABLISSEMENT DANS LE TERRITOIRE");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la suppression de l\'établissement dans le territoire',
              detail: 'L\'établissement n\'a pas pu être supprimé du territoire. Veuillez réessayer.'
            });
          }
        );
      }
    });
  }
  
  onDrag(etab: Etablissement) {
    this.etablissementDragged = etab;
  }

  onDrop(territoire: Territoire) {
    this.territoireService.addTerritoireEtablissement(territoire.id, this.etablissementDragged.id).subscribe(
      () => {
        this.etablissementsTerritoires[territoire.id].push(this.etablissementDragged);
        territoire.territoireEtablissements.push({ territoireID: territoire.id, etablissementID: this.etablissementDragged.id } as TerritoireEtablissement);
        
        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail:  'L\'établissement a bien été ajouté.'
        });
      },
      (e) => {
        console.error("ERREUR AJOUT ÉTABLISSEMENT DANS LE TERRITOIRE");
        console.error(e);
  
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur lors de l\'ajout de l\'établissement dans le territoire',
          detail: 'L\'établissement n\'a pas pu être ajouté dans le territoire. Veuillez réessayer.'
        });
      }
    );
  }
  
  authorizeDrop() {
    return function(drag: CdkDrag, drop: CdkDropList) {
      return drop.data?.find(e => e.id == drag.data.id) ? false : true;
    };
  }

  // #endregion
}