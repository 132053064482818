import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Collaborateur } from 'src/app/shared/models/annuaire/collaborateur';
import { CollaborateurService } from 'src/app/shared/services/annuaire/collaborateur.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-gestion-comptes',
  templateUrl: './gestion-comptes.component.html',
  styleUrls: ['./gestion-comptes.component.scss']
})
export class GestionComptesComponent implements OnInit {

  collaborateurs: Collaborateur[] = [];
  allCollaborateurs: Collaborateur[] = [];
  collaborateursActif: Collaborateur[] = [];
  collaborateursInactif: Collaborateur[] = [];
  bedManageurs: Collaborateur[] = [];

  refDelete: DynamicDialogRef;

  filterSelected: number = null;

  loading: boolean = false;

  constructor(
    private collaborateurService: CollaborateurService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    // Remet les tableaux à 0
    this.collaborateurs = [];
    this.allCollaborateurs = [];
    this.collaborateursActif = [];
    this.collaborateursInactif = [];
    this.bedManageurs = [];

    this.loading = true;

    // Récupére la liste des collaborateurs
    this.collaborateurService.getAll().subscribe(c => {
      this.allCollaborateurs = c;
      this.collaborateurs = this.allCollaborateurs;

      // Assigne les catégories des collaborateurs
      this.allCollaborateurs.forEach(collab => {
        if(collab.isActif) { // Comptes actifs
          this.collaborateursActif.push(collab);

          collab.collaborateurRoles.forEach(cr => {  // Bed Manageurs
            if(cr.roleId == 1 || cr.roleId == 2) {
              this.bedManageurs.push(collab)
            }
          });
        } else {  // Comptes inactifs
          this.collaborateursInactif.push(collab);
        }
      });

      this.loading = false;
    });
  }

  /*** Filtre le tableau en fontions des options :
   * 0 : Tous les comptes
   * 1 : Comptes actifs
   * 2 : Comptes inactifs
   * 3 : Bed managers 
   ***/
  filtre(options: number) {
    this.collaborateurs = [];

    if(this.filterSelected == options) {
      this.filterSelected = null;
      this.collaborateurs = this.allCollaborateurs;
    } else {
      this.filterSelected = options;
      switch (options) {
        case 0:
          this.collaborateurs = this.allCollaborateurs;
          break;
        case 1:
          this.collaborateurs = this.collaborateursActif;
          break;
        case 2:
          this.collaborateurs = this.collaborateursInactif;
          break;
        case 3:
          this.collaborateurs = this.bedManageurs;
          break;
      }
    }
  }

  addColaborateur() {
    this.router.navigate(['./creation'], {
      relativeTo: this.route,
      state: { mode: 1 }
    });
  }

  activateCollaborateur(collab : Collaborateur) {
    this.router.navigate(['./' + collab.id], {
      relativeTo: this.route,
      state: { 
        mode: 2,
        collaborateur: collab
      }
    });
  }

  editCollaborateur(collab : Collaborateur) {
    this.router.navigate(['./' + collab.id], {
      relativeTo: this.route,
      state: { 
        mode: 3,
        collaborateur: collab
      }
    });
  }

  deleteCollaborateur(collab: Collaborateur) {
    this.confirmationService.confirm({
      message: 'Souhaitez-vous vraiment supprimer le compte de <b>'+ collab.nom + ' ' + collab.prenom+'</b> ? <br>Attention, cette action est définitive.',
      header: '',
      accept: () => {
        this.collaborateurService.delete(collab.id).subscribe(() => {
          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le compte a bien été supprimé.'
          });
          // Met à jour les données
          this.initData();
        },
        (e) => {
          console.error("ERREUR SUPPRESSION COMPTE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du compte',
            detail: 'Le compte n\'as pas pu être supprimé. Veuillez réessayer.'
          });
        });
      }
    });
  }
}
