<form [formGroup]="serviceForm" class="p-fluid p-formgrid p-grid" *ngIf="!loading; else loadingBlock">
    <div class="p-field p-col-12">
        <label for="nom" class="champObligatoire">Nom du service</label>
        <input id="nom" formControlName="nom" type="text" pInputText required/>
    </div>
    <div class="p-field p-col-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
                <label for="code">Code</label>
                <input id="code" formControlName="code" type="text" pInputText/>
            </div>
            <div class="p-field p-col-6">
                <label for="maxCapacitaire">Total de lits (capacitaire maximum)</label>
                <p-inputNumber id="maxCapacitaire" formControlName="maxCapacitaire" [showButtons]="true" inputId="stacked" [min]="0"> </p-inputNumber>
            </div>
            <div class="p-field p-col-4">
                <label for="reelCapacitaire">Capacitaire réel</label>
                <p-inputNumber id="reelCapacitaire" formControlName="reelCapacitaire" [showButtons]="true" inputId="stacked" [min]="0"> </p-inputNumber>
            </div>
            <div class="p-field p-col-4">
                <label for="dateDebut">Du</label>
                <p-calendar id="dateDebut" formControlName="dateDebut" [showIcon]="true" dateFormat="dd/mm/yy" firstDayOfWeek="1"></p-calendar>
            </div>
            <div class="p-field p-col-4">
                <label for="dateFin">Au</label>
                <p-calendar id="dateFin" formControlName="dateFin" [showIcon]="true" dateFormat="dd/mm/yy" firstDayOfWeek="1"></p-calendar>
            </div>
            <div class="p-field p-col-4"></div>
            <div class="p-field p-col-8">
                <small>Indiquez uniquement une date de début si vous souhaitez que le capacitaire réel s'applique de manière continue.</small>
            </div>
        </div>
    </div>
    <div class="p-field p-col-12 p-md-12" style="margin-bottom:0px;">
        <button type="button" class="btn-amc green" (click)="validate()" [disabled]="serviceForm.invalid">
            <span class="icon-validate">&nbsp; Valider</span>
        </button>
    </div>
</form>
<ng-template #loadingBlock>
    <div style="text-align: center">
        <sesame-loader></sesame-loader>
    </div>
</ng-template>
