<app-nav-menu></app-nav-menu>
<div class="container">
    <div style="display: flex; justify-content:space-between;">
        <div class="titre-parametrage">
            Paramétrage > <b>Secteurs de territoire</b>
        </div>
        <div *ngIf="!isLoading">
            <p-dropdown *ngIf="territoires.length > 1" id="territoire-etab" [options]="territoires" placeholder="Sélectionnez un territoire"
                [(ngModel)]="selectedTerritoire" optionLabel="nom" (onChange)="selectTerritoire()">
            </p-dropdown>
        </div>
    </div>
    <div class="card-container" *ngIf="!loading && selectedTerritoire">
        <div *ngFor="let secteur of secteurs; let i = index" class="card card-grey">
            <div class="card-header">
                <h4 *ngIf="editingSecteurId != secteur.id" (click)="onNomSecteurClick(secteur)">{{secteur.nom}}</h4>
                <input [id]='"input-"+secteur.id' *ngIf="editingSecteurId == secteur.id" pInputText [(ngModel)]="secteur.nom" (focusout)="addOrUpdateSecteur(secteur)" (keypress)="keyPress($event, secteur)">
                <div *ngIf="secteur.id" class="delete-card" (click)="removeSecteur(secteur)"><span class="fa-solid fa-trash-can"></span></div>
            </div>
            <div class="card-content">
                <p-megaMenu [class]="(i + 1) % 5 == 0 ? 'menu-a-gauche' : 'menu-a-droite'" [model]="secteur.etablissementsMenu" appendTo="body" orientation="vertical" [id]='secteur.id'>
                </p-megaMenu>
            </div>
        </div>
        <div class="card ajout" (click)="createSecteur()"><span class="fa-solid fa-plus"></span></div>
    </div>
</div>
<div *ngIf="loading" style="text-align: center">
    <sesame-loader></sesame-loader>
</div>