import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { F_Result } from '../../models/APIFiness/F_Result';
import { plainToInstance } from 'class-transformer';


@Injectable({
  providedIn: 'root'
})
export class ApiFinessService {

  private url = environment.url + "api/gdl/finess/";

  constructor(private http: HttpClient) { }

  recherche(texteRecherche: string): Observable<F_Result> {
    return this.http.get<F_Result>(this.url + "recherche/" + texteRecherche).pipe(
      map((res) => {
        return plainToInstance(F_Result, res);
      })
    );
  }
}
