import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { Etablissement } from "../models/annuaire/etablissement";
import { Secteur } from "../models/secteur";
import { Territoire } from "../models/territoire";
import { map, shareReplay } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { TerritoireEtablissement } from "../models/territoire_etablissement";
import { CollaborateurRole } from "../models/annuaire/collaborateurRole";

@Injectable({
    providedIn: 'root'
  })
  export class TerritoireService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/gdl/territoire';
    
    getAll$ = this.getAll().pipe(shareReplay(1));
    getAll(): Observable<Territoire[]> {
      return this.http.get<Territoire[]>(this.url).pipe(map(t => plainToInstance(Territoire, t)));
    }

    getEtablissementsSecteursById(id:number): Observable<Etablissement[]> {  
      return this.http.get<Etablissement[]>(this.url + "/" + id + "/etablissements-secteurs").pipe(map(e => plainToInstance(Etablissement, e)));
    }

    getSecteursById(id:number): Observable<Secteur[]> {  
      return this.http.get<Secteur[]>(this.url + "/" + id + "/secteurs").pipe(map(s => plainToInstance(Secteur, s)));
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////GESTION DES TERRITOIRES ET DES ÉTABLISSEMENTS////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    add(territoire: Territoire): Observable<Territoire> {
      return this.http.post<Territoire>(this.url, territoire).pipe(map(t => plainToInstance(Territoire, t)));
    }

    update(territoire: Territoire): Observable<Territoire> {
      return this.http.put<Territoire>(this.url + "/" + territoire.id, territoire).pipe(map(t => plainToInstance(Territoire, t)));
    }
    
    remove(id: number) {  
      return this.http.delete(this.url + "/" + id);
    }

    removeTerritoireEtablissement(id: number, etabId: number) {
      return this.http.delete(this.url + "/" + id + "/etablissement?etabId=" + etabId);
    }
  
    addTerritoireEtablissement(id: number, etabId: number) {
      return this.http.post(this.url + "/" + id + "/etablissement?etabId=" + etabId, null);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////GESTION DES RÔLES//////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    updateCollaborateurRoles(terrId: number, collaborateurRoles: CollaborateurRole[]): Observable<CollaborateurRole[]> {
      return this.http.put<CollaborateurRole[]>(this.url + "/" + terrId + "/CollaborateurRole", collaborateurRoles).pipe(map(cr => plainToInstance(CollaborateurRole, cr)));
    }
  }