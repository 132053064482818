import { MegaMenuItem } from "primeng/api";
import { Secteur_unite } from "./secteur_unite";
import { Unite } from "./unite";

export class Secteur {
  id: number;
  nom: string;
  territoireId?: number;
  etablissementId?: number;
  sesameId?: number;  
  
  secteurUnites?: Secteur_unite[];

  // Champ fictif pour récupérer les unités du secteur en passant par this.secteurUnites (dans le cas des secteurs d'établissement)
  services?: Unite[];
  // Champ fictif pour récupérer les établissement du secteur (dans le cas des secteurs de territoire) sous forme de MegaMenuItem (composant Primeng)
  etablissementsMenu?: MegaMenuItem[];
 }