<div class="body">
    <div class="bloc-gauche p-d-flex p-d-flex-row">
        <div>
            <div class="logo-accueil">
                <img src="../../assets/images/logo_sesame_territoire.svg" alt="">
            </div>
            <div class="paragraphe">La gestion territoriale des lits à portée de clic&nbsp;!</div>
            <div class="accueil-buttons" >
                <button type="button" class="orange" (click)="clickIdentification()" >
                    <span class="fas fa-right-from-bracket"></span>&nbsp;&nbsp;S'identifier
                </button>
                <button type="button" class="orange" [routerLink]="'/inscription'">
                    <span class="fa-solid fa-user-plus"></span>&nbsp;&nbsp;Créer un compte
                </button>
            </div>
            <div class="logo-accueil footer-accueil">
                <span>Une solution créée et développée par</span>
                <img src="../../assets/images/logo_amc.svg" alt="">
            </div>
        </div>
    </div>
</div>