import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Secteur } from '../models/secteur';
import { TerritoireService } from '../services/territoire.service';

@Injectable({
  providedIn: 'root'
})
export class SecteursResolver implements Resolve<Secteur[]> {

  constructor(private territoireService: TerritoireService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Secteur[]> {
      const territoireId = parseInt(route.paramMap.get('id'));
      return this.territoireService.getSecteursById(territoireId);
  }   
}