import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { UserService } from '../../../authentification/services/user.service';
import { AuthenticateGuard } from '../authenticate/authenticate.guard';

@Injectable()
export class BedManagerTerritoireGuard implements CanActivate {

  constructor (
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private authenticateGuard: AuthenticateGuard
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authenticateGuard.canActivate()) {
      if(!this.userService.currentUser.isRole(1)
          && !this.userService.currentUser.isRoleTerritoire(2, parseInt(route.paramMap.get('id')))
          && !this.userService.currentUser.isRoleTerritoire(3, parseInt(route.paramMap.get('id')))) {
        this.messageService.add({
          severity: "error",
          summary: "Accès restreint",
          detail: "Vous n'avez pas les droits nécessaires pour administrer ce territoire."
        });
        this.router.navigate(['']);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

}
