<app-nav-menu></app-nav-menu>
<div class="container">
    <div class="titre-parametrage">
        Paramétrage > <b>Territoires</b>
    </div>
    <div *ngIf="!loading; else loadingBlock" cdkDropListGroup style="display: flex; flex-direction:row;">
        <!--Etablissements des territoires-->
        <div class="card card-grey" style="margin-top: 30px;height: 100%;">
            <div class="card-header" style="justify-content:center;">
                <h4 class="not-editable">Établissements</h4>
            </div>
            <div class="card-content">
                <div class="ajout-element">
                    <button class="btn-amc green" (click)="addOrUpdateEtablissement()">
                        <span class="icon-add">&nbsp; Ajouter un établissement</span>
                    </button>
                </div>
                <!-- INPUT DE RECHERCHE -->
                <div style="display: flex; justify-content: space-between; margin: 5px;">
                    <input type="text" pInputText size="50" placeholder="Rechercher" [(ngModel)]="searchText"
                        (ngModelChange)="filtrer()" style="width: 100%;">
                </div>
                <div class="elements" style="overflow: auto; max-height: 65vh; width: 100%;" cdkDropList #etablissementsDragList="cdkDropList"
                    [cdkDropListData]="etablissementsAffiches" cdkDropListSortingDisabled>
                    <div class="chip-grey" *ngFor="let etab of etablissementsAffiches" cdkDrag #etablissementDrag [cdkDragData]="etab" [cdkDragDisabled]="false"
                        (cdkDragStarted)="onDrag(etab)">
                        <span style="width: calc(100% - 85px);">{{etab.nom}}</span>
                        <div class="etablissement-action">
                            <button class="btn-amc red p-button-sm" (click)="removeEtablissement(etab)">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>

                            <button class="btn-amc blue p-button-sm" (click)="addOrUpdateEtablissement(etab)">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-container" style="height: 100%;">    
             <!-- Territoires -->
            <div *ngFor="let territoire of territoires" class="card card-grey">
                <div class="card-header">
                    <h4 *ngIf="editingTerritoireId != territoire.id" (click)="onNomTerritoireClick(territoire)">
                        {{territoire.nom}}
                    </h4>
                    <input type="text" [id]='"input-" + territoire.id' *ngIf="editingTerritoireId == territoire.id" pInputText [(ngModel)]="territoire.nom" 
                        (focusout)="addOrUpdateTerritoire(territoire)" (keypress)="keyPressTerritoire($event, territoire)" />
                    <div class="delete-card" (click)="removeTerritoire(territoire)">
                        <span class="fa-solid fa-trash-can"></span>
                    </div>
                </div>
                <div class="card-content content-list" cdkDropList #etablissementsDuTerritoire="cdkDropList" [cdkDragDisabled]="true" (cdkDropListDropped)="onDrop(territoire)" 
                    [cdkDropListData]="etablissementsTerritoires[territoire.id]" [cdkDropListEnterPredicate]="authorizeDrop()">
                    <div class="chip-grey full-width" *ngFor="let etablissement of etablissementsTerritoires[territoire.id]">
                        <span>
                            {{ etablissement.nom }}
                        </span>
                        &nbsp;
                        <span class="fa-regular fa-circle-xmark" (click)="removeEtablissementFromTerritoire(territoire,etablissement)"></span>
                    </div>
                </div>
            </div>
            <div class="card ajout" (click)="createTerritoire()">
                <span class="fa-solid fa-plus"></span>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingBlock>
    <div style="text-align: center">
        <sesame-loader></sesame-loader>
    </div>
</ng-template>