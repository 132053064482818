<app-nav-menu></app-nav-menu>
<div class="indicateurs-container">
    <div class="p-grid">
        <div class="p-col-8">
            <div class="p-grid nested-grid">
                <div class="p-col-8">
                    <div class="box">
                        <label>
                            <span style="color: var(--orange)">32</span> patients présents aux urgences
                        </label>
                        <br /><br />
                        <p-chart type="bar" [data]="patientsUrgences" [options]="barChartOptions"
                            [height]="calcMediaScreen()"></p-chart>
                    </div>
                </div>
                <div class="p-col-4">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <div class="box numbers">
                                <div class="triangle rouge">
                                    <i class="fas fa-clock"></i>
                                </div>
                                <div class="indic"><b>04</b>
                                    <br /> patients présents +10h aux urgences
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6">
                            <div class="box numbers">
                                <div class="triangle vert">
                                    <i class="fas fa-sign-out-alt"></i>
                                </div>
                                <!-- Sorties EFFECTIVES du jour -->
                                <div class="indic"><b>12</b>
                                    <br /> patients sortis aujourd'hui
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6">
                            <div class="box numbers">
                                <div class="triangle cyan">
                                    <i class="fas fa-bed"></i>
                                </div>
                                <!-- Patients ADMIS qui n'ont pas de lit, hors urgences -->
                                <div class="indic"><b>07</b>
                                    <br /> patients en attente d'un lit
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6">
                            <div class="box numbers">
                                <div class="triangle org">
                                    <i class="fas fa-sign-out-alt"></i>
                                </div>
                                <!-- Sorties prévisionnelles DU JOUR -->
                                <div class="indic"><b>08</b>
                                    <br /> sorties prévisionnelles aujourd'hui
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-grid nested-grid">
                <div class="p-col-4">
                    <div class="box" id="pat_urg_veille">
                        <label><span style="color: var(--orange)">30</span> patients aux urgences le
                            {{yesterday}}</label>
                        <div class="legend-container">
                            <ul>
                                <li>
                                    <span style="background: #FFC58C;"></span>
                                    Plus de 75 ans
                                </li>
                                <li>
                                    <span style="background: #F0841A;"></span>
                                    Moins de 3 ans
                                </li>
                            </ul>
                        </div>
                        <p-chart type="doughnut" [data]="patientsUrgences_yesterday"
                            [options]="doughnutOptions"></p-chart>
                    </div>
                </div>
                <div class="p-col-4">
                    <div class="box" id="hospit_veille">
                        <label><span style="color: var(--orange)">10</span> hospitalisations du {{yesterday}}</label>
                        <div class="legend-container">
                            <ul>
                                <li>
                                    <span style="background: #FFC58C;"></span>
                                    Plus de 75 ans
                                </li>
                                <li>
                                    <span style="background: #F0841A;"></span>
                                    Moins de 3 ans
                                </li>
                            </ul>
                        </div>
                        <p-chart type="doughnut" [data]="hospitalisations_yesterday"
                            [options]="doughnutOptions"></p-chart>
                    </div>
                </div>
                <div class="p-col-2">
                    <div class="box percent">
                        <div class="p-grid nested-grid" style="height:100%;">
                            <div class="p-col-4">
                                <div class="progressbar">
                                    <div class="progress" [style]="'height:' + pourcentage_hospit + '%;'">
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-8">
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <label><i class="far fa-calendar"></i> &nbsp; {{yesterday}}</label>
                                    </div>
                                    <div class="p-col-12" style="margin-top:calc(100% - 23px);">
                                        <div class="txt">
                                            hospitalisations provenant des urgences
                                            <br />
                                            <b>{{ pourcentage_hospit }}<span style="font-size:2rem;">%</span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-2">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <div class="box numbers">
                                <div class="triangle rse">
                                    <i class="fas fa-disease"></i>
                                </div>
                                <div class="indic"><b>15</b>
                                    <br /> patients Covid+ hospitalisés
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <div class="box numbers">
                                <div class="triangle rse">
                                    <i class="fas fa-heartbeat"></i>
                                </div>
                                <div class="indic"><b>01</b>
                                    <br /> patients Covid+ en réanimation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="p-col-4">
            <div class="box" id="nosortieprev">
                <div class="no_sortieprev_percent">62%</div>
                <div class="no_sortieprev_txt">
                    des patients hospitalisés n'ont <u>pas</u> de date de sortie prévisionnelle saisie.
                </div>
            </div>

            <div class="box" id="sectors" style="margin-top:16px;">
                <div class="sectors_container">
                    <span *ngFor="let secteur of secteurs; let i = index" id="{{'sector_label' + i}}"
                        [style]="'width:' + secteur.pourcent + '%;'">
                        {{secteur.libelle}}
                    </span>
                </div>
                <div class="sectors_container">
                    <div class="timeline" id="{{'timeline' + i}}" *ngFor="let secteur of secteurs; let i = index"
                        [style]="'width:' + secteur.pourcent + '%;'"
                        pTooltip="{{secteur.libelle}} - {{secteur.pourcent}}%" tooltipPosition="bottom"
                        (click)="displayHorizontalChart(secteur)">
                        {{secteur.pourcent}} %
                    </div>
                </div>
                <br />
                <div *ngIf="afficherSecteurChart">
                    <p-chart type="horizontalBar" [data]="secteur1" [options]="horizontalChartOptions"></p-chart>
                </div>
            </div>
        </div>
    </div>

</div>