import { Component, OnInit } from '@angular/core'
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-indicateurs',
  templateUrl: './indicateurs.component.html',
  styleUrls: ['./indicateurs.component.scss']
})
export class IndicateursComponent implements OnInit {

  afficherSecteurChart : boolean = false; // Afficher le diagramme horizontal par secteur

  innerWidth: number; // Taille d'écran dynamique
  yesterday: string; // Date d'hier dynamique

  doughnutOptions: any; // Config diagramme donut
  barChartOptions: any; // Config diagramme en barres
  horizontalChartOptions: any; // Config diagramme en barres horizontal

  patientsUrgences: any; // Diagramme barres patients aux urgences en temps réel
  patientsUrgences_yesterday: any; // Diagramme donut patients aux urgences la veille
  hospitalisations_yesterday: any; // Diagramme donut hospitalisations de la veille

  pourcentage_hospit: number; // % d'hospit des urgences
  pourcentage_sanssortie: number; // % patients sans date de sortie

  secteurs: any[] = []; // Liste des secteurs
  secteur1: any; // Diagramme en barres horizontal par secteur

  constructor() { }

  ngOnInit(): void {

    this.pourcentage_hospit = 23; // EN DUR
    this.pourcentage_sanssortie = 58; // EN DUR

    this.secteurs = [
      { id: 0, libelle: 'Chirurgie', pourcent: 15 },
      { id: 1, libelle: 'Pôle somatique', pourcent: 40 }, 
      { id: 2, libelle: 'Mère-enfant', pourcent: 20 }, 
      { id: 3, libelle: 'Médecine', pourcent: 25 } 
    ];

    // Calcul du jour précédant la date du jour
    let dte = new Date();
    dte.setDate(dte.getDate() - 1);
    this.yesterday = formatDate(dte, 'dd/MM', 'en');

    // Configurations des diagrammes
    this.doughnutOptions = {
      legend: {
        display: false
      }
    }

    this.barChartOptions = {
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function (value) { if (Number.isInteger(value)) { return value; } },
            stepSize: 1
          }
        }]
      }
    }

    this.horizontalChartOptions = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            callback: function (value) { if (Number.isInteger(value)) { return value; } },
            stepSize: 1
          }
        }]
      }
    }

    // BARRES - Patients aux urgences
    this.patientsUrgences = {
      labels: ['Consultation', 'Imagerie', 'Laboratoire', 'IOA', 'Médecin Urgentiste', 'Médecin spécialiste', 'Recherche chambre'],
      datasets: [
        {
          backgroundColor: '#F0841A',
          data: [5, 7, 6, 2, 3, 2, 7]
        }
      ]
    }

    // DONUT - Patients aux urgences la veille
    this.patientsUrgences_yesterday = {
      labels: ['Autres patients', 'Plus de 75 ans', 'Moins de 3 ans'],
      datasets: [
        {
          data: [12, 15, 3],
          backgroundColor: [
            "#143D69",
            "#FFC58C",
            "#F0841A"
          ],
          hoverBackgroundColor: [
            "#1E599B",
            "#FFD9B3",
            "#F5B574"
          ]
        }]
    };

    // DONUT - Hospitalisations la veille
    this.hospitalisations_yesterday = {
      labels: ['Autres patients', 'Plus de 75 ans', 'Moins de 3 ans'],
      datasets: [
        {
          data: [5, 4, 1],
          backgroundColor: [
            "#143D69",
            "#FFC58C",
            "#F0841A"
          ],
          hoverBackgroundColor: [
            "#1E599B",
            "#FFD9B3",
            "#F5B574"
          ]
        }]
    };

    // BARRES HORIZONTALES - Secteur
    this.secteur1 = {
      // Services du secteur - imposer un nombre de caractères maximum lors de la récupération des données
      labels: ['Cardiologie', 'CHIR-1', 'CHIR-2', 'Maternité', 'Méd. polyvalente', 'Pneumologie', 'Réanimation', 'UHCD', 'Méd. gériatrique'],
      datasets: [
        {
          label: 'Patients sans date de sortie ',
          backgroundColor: '#DC67AB',
          data: [5, 3, 5, 1, 0, 2, 7, 5, 8]
        }
      ]
    };
  }


  // Calcul taille d'écran pour adapter la taille du diagramme en barres
  calcMediaScreen(): string {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1500) {
      return '331px';
    }
    else {
      return '231px'
    }
  }

  // Afficher le diagramme horizontal du secteur sélectionné
  displayHorizontalChart(secteur : any){
    this.afficherSecteurChart = true;
  }

}
