import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Capacitaire } from 'src/app/shared/models/capacitaire';
import { Unite } from 'src/app/shared/models/unite';

@Component({
  selector: 'app-pop-up-service',
  templateUrl: './pop-up-service.component.html',
  styleUrls: ['./pop-up-service.component.scss']
})
export class PopUpServiceComponent implements OnInit {

  serviceForm : UntypedFormGroup;
  service: Unite;
  isNew: boolean;
  loading: boolean = false;

  constructor (
    private formBuilder : UntypedFormBuilder,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.isNew = this.config.data.isNew;

    // Création 
    if(this.isNew) {
      this.serviceForm = this.formBuilder.group({
        nom: ["", Validators.required],
        code: ["", Validators.nullValidator],
        maxCapacitaire: [null, Validators.nullValidator],
        reelCapacitaire: [null, Validators.nullValidator],
        dateDebut: [null, Validators.nullValidator],
        dateFin: [null, Validators.nullValidator]
      });
    }
    // Modification
    else {
      this.service = this.config.data.service;
      let capacitaire = this.service.capacitaires[0];
      
      this.serviceForm = this.formBuilder.group({
        nom: [this.service.nom, Validators.required],
        code: [this.service.code, Validators.nullValidator],
        maxCapacitaire: [capacitaire ? capacitaire.capacitaireMax : null, Validators.nullValidator],
        reelCapacitaire: [capacitaire ? capacitaire.capacitaireReel : null, Validators.nullValidator],
        dateDebut: [capacitaire && capacitaire.dateReelDebut ? new Date(capacitaire.dateReelDebut) : null, Validators.nullValidator],
        dateFin: [capacitaire && capacitaire.dateReelFin ? new Date(capacitaire.dateReelFin) : null, Validators.nullValidator]
      });
    }
  }

  validate() {
    let capacitaireMax: number = this.serviceForm.get('maxCapacitaire').value;
    let capacitaireReel: number = this.serviceForm.get('reelCapacitaire').value;
    let dateDebut: Date = this.serviceForm.get('dateDebut').value;
    let dateFin: Date = this.serviceForm.get('dateFin').value;
    
    if((dateDebut || dateFin) && !capacitaireReel) {
      this.messageService.add({ 
        severity: 'error',
        summary: 'Données non valides',
        detail:  'La valeur du capacitaire réel doit être saisie si au moins l\'une des deux dates est renseignée.'
      });
    } else {
      if(capacitaireReel && !dateDebut) {
        dateDebut = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      }

      if(dateFin && dateDebut > dateFin) {
        this.messageService.add({
          severity: 'error',
          summary: 'Données non valides',
          detail:  'La date de fin doit être supérieure ou égale à la date de début.'
        });
      } else {
        // Mise en forme du capacitaire
        let capacitaires: Capacitaire[] = [];
        if(capacitaireMax || capacitaireReel) {
          let capacitaire: Capacitaire = new Capacitaire();
          if(!this.isNew) {
            if(this.service?.capacitaires[0]) {
              capacitaire = this.service.capacitaires[0];
            } else {
              capacitaire.uniteId = this.service.id;
            }
          }
          capacitaire.capacitaireMax = capacitaireMax;
          capacitaire.capacitaireReel = capacitaireReel;
          capacitaire.dateReelDebut = dateDebut;
          capacitaire.dateReelFin = dateFin;
          capacitaires.push(capacitaire);
        }

        // Mise en forme de l'unité
        if(this.isNew) {
          this.service = new Unite();
        }
        this.service.nom = this.serviceForm.get('nom').value;
        this.service.code = this.serviceForm.get('code').value;
        this.service.capacitaires = capacitaires;
        
        //Passage du service à la page parente
        this.ref.close(this.service);
      }
    }
  }
}
