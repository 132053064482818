import 'reflect-metadata';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AmcSingleSpaModule } from '@sesame/single-spa'; 

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AccueilComponent } from './accueil/accueil.component';
import { TableauDeBordComponent } from './tableau-de-bord/tableau-de-bord.component';
import { InscriptionComponent } from './inscription/inscription.component';
import { SharedModule } from 'primeng/api';
import { PrimeNgModule } from './shared/primeng/primeng.module';
import { ProfilComponent } from './profil/profil.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestionRolesComponent } from './parametrage/gestion-roles/gestion-roles.component';
import { SecteursDeTerritoireComponent } from './parametrage/secteurs-de-territoire/secteurs-de-territoire.component';
import { GestionSecteursServicesComponent } from './parametrage/gestion-secteurs-services/gestion-secteurs-services.component';
import { EtablissementsResolver } from './shared/resolvers/etablissements.resolver';
import { SecteursResolver } from './shared/resolvers/secteurs.resolver';
import { IndicateursComponent } from './indicateurs/indicateurs.component';
import { SesameDatePipe } from './shared/pipe/sesame-date.pipe';
import { AuthentificationModule } from './authentification/authentification.module';
import { CallbackComponent } from './authentification/components/callback/callback.component';
import { AuthenticateGuard } from './shared/guard/authenticate/authenticate.guard';
import { TerritoireGuard } from './shared/guard/territoire/territoire.guard';
import { ConfigService } from './shared/services/config/config.service';
import { PopUpRechercheAvanceeComponent } from './inscription/pop-up-recherche-avancee/pop-up-recherche-avancee.component';
import { GestionComptesComponent } from './parametrage/gestion-comptes/gestion-comptes.component';
import { PopUpModificationMdpComponent } from './inscription/pop-up-modification-mdp/pop-up-modification-mdp.component';
import { PopUpServiceComponent } from './parametrage/gestion-secteurs-services/pop-up-service/pop-up-service.component';
import { AdminAMCGuard } from './shared/guard/admin-amc/admin-amc.guard';
import { AdminTerritoireGuard } from './shared/guard/admin-territoire/admin-territoire.guard';
import { BedManagerTerritoireGuard } from './shared/guard/bed-manager-territoire/bed-manager-territoire.guard';
import { TerritoiresComponent } from './parametrage/territoires/territoires.component';
import { PopUpEtablissementComponent } from './parametrage/territoires/pop-up-etablissement/pop-up-etablissement.component';
import { GestionIndicateursComponent } from './parametrage/gestion-indicateurs/gestion-indicateurs.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        AccueilComponent,
        TableauDeBordComponent,
        ProfilComponent,
        GestionRolesComponent,
        SecteursDeTerritoireComponent,
        GestionSecteursServicesComponent,
        TerritoiresComponent,
        IndicateursComponent,
        InscriptionComponent,
        PopUpRechercheAvanceeComponent,
        GestionComptesComponent,
        PopUpModificationMdpComponent,
        PopUpServiceComponent,
        PopUpEtablissementComponent,
        GestionIndicateursComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        AuthentificationModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        PrimeNgModule,
        CommonModule,
        BrowserAnimationsModule,
        AmcSingleSpaModule,
        RouterModule.forRoot([
            {
                path: 'callback',
                component: CallbackComponent,
                pathMatch: 'full'
            },
            {
                path: '',
                component: AccueilComponent,
                pathMatch: 'full'
            },
            {
                path: 'inscription',
                component: InscriptionComponent
            },
            {
                path: ':id/tableau-de-bord',
                canActivate: [TerritoireGuard],
                resolve: { etablissements: EtablissementsResolver, secteurs: SecteursResolver },
                component: TableauDeBordComponent
            },
            {
                path: 'indicateurs',
                canActivate: [AuthenticateGuard],
                component: IndicateursComponent
            },
            {
                path: 'profil',
                canActivate: [AuthenticateGuard],
                component: ProfilComponent
            },
            {
                path: 'parametrage',
                children: [
                    {
                        path: 'territoires',
                        canActivate: [AdminAMCGuard],
                        component: TerritoiresComponent,
                    },
                    {
                        path: 'gestion-roles',
                        canActivate: [AdminTerritoireGuard],
                        component: GestionRolesComponent
                    },
                    {
                        path: 'gestion-comptes',
                        canActivate: [AdminTerritoireGuard],
                        children: [
                            {
                                path: '',
                                component: GestionComptesComponent
                            },
                            {
                                path: ':compteId',
                                component: InscriptionComponent
                            },
                            {
                                path: 'creation',
                                component: InscriptionComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: ':id/parametrage',
                children: [
                    {
                        path: 'secteurs-de-territoire',
                        canActivate: [BedManagerTerritoireGuard],
                        resolve: { etablissements: EtablissementsResolver, secteurs: SecteursResolver },
                        component: SecteursDeTerritoireComponent,
                    },
                    {
                        path: 'gestion-secteurs-services',
                        canActivate: [AdminTerritoireGuard],
                        resolve: { etablissements: EtablissementsResolver },
                        component: GestionSecteursServicesComponent
                    },
                    {
                        path: 'gestion-indicateurs',
                        canActivate: [BedManagerTerritoireGuard],
                        resolve: { etablissements: EtablissementsResolver },
                        component: GestionIndicateursComponent
                    }
                ]
            }
        ], { relativeLinkResolution: 'legacy' })
    ],
    providers: [
        SesameDatePipe,
        AuthenticateGuard,
        TerritoireGuard,
        AdminAMCGuard,
        AdminTerritoireGuard,
        BedManagerTerritoireGuard,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ConfigService],
            useFactory: (configService: ConfigService) => {
                return () => {
                    return configService.init();
                };
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
