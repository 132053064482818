import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Collaborateur } from 'src/app/shared/models/annuaire/collaborateur';
import { CollaborateurRole } from 'src/app/shared/models/annuaire/collaborateurRole';
import { Territoire } from 'src/app/shared/models/territoire';
import { CollaborateurService } from 'src/app/shared/services/annuaire/collaborateur.service';
import { TerritoireService } from 'src/app/shared/services/territoire.service';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { User } from 'src/app/authentification/models/user';
import { UserService } from 'src/app/authentification/services/user.service';

@Component({
  selector: 'app-gestion-roles',
  templateUrl: './gestion-roles.component.html',
  styleUrls: ['./gestion-roles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GestionRolesComponent implements OnInit {

  isLoading: boolean = false;
  isSaving: boolean = false;
  
  user: User;

  // L'ensemble des collaborateurs de la plateforme
  collaborateurs: Collaborateur[] = [];
  
  // L'ensemble des territoires de la plateforme
  territoires: Territoire[] = [];

  // Droits admin AMC
  adminAmc: Collaborateur[] = [];
  nonAdminAmc: Collaborateur[] = [];

  // Territoire courant et droits associés
  selectedTerritoire: Territoire;
  adminSelectedTerritoire: Collaborateur[] = [];
  bedSelectedTerritoire: Collaborateur[] = [];
  nonAdminSelectedTerritoire: Collaborateur[] = [];
  nonBedSelectedTerritoire: Collaborateur[] = [];

  constructor(
    private collaborateurService: CollaborateurService,
    private territoireService: TerritoireService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    
    this.user = this.userService.currentUser;

    combineLatest([
      this.collaborateurService.getAll(),
      this.territoireService.getAll()
    ])
    .subscribe(([collaborateurs, territoires]) => {
      this.isLoading = false;
      
      this.collaborateurs = collaborateurs;

      // Initialisation des droits Admin AMC
      this.adminAmc = this.collaborateurs.filter(collab => this.hasRole(collab, 1));
      this.nonAdminAmc = this.collaborateurs.filter(collab => !this.hasRole(collab, 1));

      this.territoires = territoires.filter(t => this.user.isRole(1) || this.user.isRoleTerritoire(2, t.id));
      this.user.territoires.forEach(t => {
        if(!this.selectedTerritoire && territoires.find(ter => ter.id == t)) {
          this.selectedTerritoire = territoires.find(ter => ter.id == t);
        }
      });
      if(!this.selectedTerritoire) {
        this.selectedTerritoire = territoires[0];
      }
       
      // Initialisation des droits sur le territoire courant
      this.selectTerritoire();
    });
  }

  hasRole(collab: Collaborateur, roleId: number) {
    return collab.collaborateurRoles.filter(cr => cr.roleId == roleId && (roleId == 1 || cr.territoireId == this.selectedTerritoire.id)).length > 0;
  }

  selectTerritoire() {
    if(this.selectedTerritoire) {
      let collabsSelectedTerritoire = this.collaborateurs.filter(collab => this.selectedTerritoire.territoireEtablissements.map(te => te.etablissementID).includes(collab.etablissementID));
  
      this.adminSelectedTerritoire = collabsSelectedTerritoire.filter(collab => this.hasRole(collab, 2));
      this.bedSelectedTerritoire = collabsSelectedTerritoire.filter(collab => this.hasRole(collab, 3));
    
      this.nonAdminSelectedTerritoire = collabsSelectedTerritoire.filter(collab => !this.hasRole(collab, 2));
      this.nonBedSelectedTerritoire = collabsSelectedTerritoire.filter(collab => !this.hasRole(collab, 3));
    }
  }

  newCollaborateurRole(roleId: number, collabId: number): CollaborateurRole {
    let colRole: CollaborateurRole = new CollaborateurRole();

    colRole.roleId = roleId;
    colRole.collaborateurId = collabId;

    if(roleId != 1) {
      colRole.territoireId = this.selectedTerritoire.id;
    }

    return colRole;
  }

  getCollaborateurRole(roleId: number, collabId: number): CollaborateurRole {
    return this.collaborateurs.find(c => c.id == collabId).collaborateurRoles.find(cr => cr.roleId == roleId && (roleId == 1 || cr.territoireId == this.selectedTerritoire.id));
  }

  validate() {
    this.isSaving = true;
    
    // #region Construction des objets CollaborateurRole à envoyer à la fonction
    let collaborateursRoles: CollaborateurRole[] = [];
    
    this.adminAmc.forEach(aamc => {
      let collabRole: CollaborateurRole = this.getCollaborateurRole(1, aamc.id);
      if(!collabRole) {
        collabRole = this.newCollaborateurRole(1, aamc.id);
      }
      collaborateursRoles.push(collabRole);
    });
    
    this.adminSelectedTerritoire.forEach(ater => {
      let collabRole: CollaborateurRole = this.getCollaborateurRole(2, ater.id);
      if(!collabRole) {
        collabRole = this.newCollaborateurRole(2, ater.id);
      }
      collaborateursRoles.push(collabRole);
    });
    
    this.bedSelectedTerritoire.forEach(bter => {
      let collabRole: CollaborateurRole = this.getCollaborateurRole(3, bter.id);
      if(!collabRole) {
        collabRole = this.newCollaborateurRole(3, bter.id);
      }
      collaborateursRoles.push(collabRole);
    });
    // #endregion
    
    // #region Enregistrement en base de données
    this.territoireService.updateCollaborateurRoles(this.selectedTerritoire.id, collaborateursRoles).subscribe(
      collabRoles => {
        this.isSaving = false;

        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail:  'Les rôles ont bien été enregistrés.'
        });

        // Mise à jour du front
        this.collaborateurs.forEach(c => {
          let newCollabRoles: CollaborateurRole[] = collabRoles.filter(cr => cr.collaborateurId == c.id);
          
          // Suppression des anciens rôles du collaborateur
          c.collaborateurRoles = c.collaborateurRoles.filter(cr => newCollabRoles.filter(new_cr => new_cr.roleId == cr.roleId
                                                                                                   && (cr.roleId == 1
                                                                                                       || cr.territoireId != this.selectedTerritoire.id
                                                                                                       || new_cr.territoireId == cr.territoireId)).length > 0);
          
          // Ajout des nouveaux rôles du collaborateur avec l'ID généré en base de données
          c.collaborateurRoles = c.collaborateurRoles.concat(newCollabRoles.filter(new_cr => !c.collaborateurRoles.map(cr => cr.id).includes(new_cr.id)))
        });
      },
      error => {
        this.isSaving = false;

        this.messageService.add({
          severity: 'error',
          summary: 'Données non enregistrées',
          detail:  'Une erreur est survenue.'
        });

        console.log(error);
      }
    );
    // #endregion
  }
}
