import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MegaMenuItem, MenuItem, MessageService } from 'primeng/api';
import { Etablissement } from '../shared/models/annuaire/etablissement';
import { Secteur } from '../shared/models/secteur';
import { eChamps } from '../shared/models/tdb_champs';
import { TDB_Donnees } from '../shared/models/tdb_donnees';
import { SesameDatePipe } from '../shared/pipe/sesame-date.pipe';
import { UniteService } from '../shared/services/unite.service';
import { User } from '../authentification/models/user';
import { UserService } from '../authentification/services/user.service';
import { Territoire } from '../shared/models/territoire';
import { TerritoireService } from '../shared/services/territoire.service';

@Component({
  selector: 'app-tableau-de-bord',
  templateUrl: './tableau-de-bord.component.html',
  styleUrls: ['./tableau-de-bord.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableauDeBordComponent implements OnInit {

  territoires: Territoire[] = [];
  selectedTerritoire: Territoire;

  etablissements: Etablissement[] = [];  
  secteurs: Secteur[] = [];  
  etablissementsMenu: MegaMenuItem[];
  secteursMenu: MegaMenuItem[];

  tableauDeBord: any[] = [];
  tdbTotal: any;

  //data previsionnel des unites
  previsionnel: any[] = [];
  //total des previsionnels par jour
  previsionnelTotal: any;
  //Constante du nombre de jours de prévision
  NB_JOUR_PREVISION: number = 10; //Si modifié, bien mettre à jour la partie html éditable du tableau qui n'est pas dynamique (tout le reste l'est normalement)

  //Différencier le type de selection plus facilement
  isSecteurSelected: boolean = false;
  selected: any[] = [];
  //Paginator
  first: number = 0;

  //Ajouter des informations au tableau
  isTableauEntier: boolean = false;

  //Choix prévisionnel ou jour
  isPrevisionnel: boolean = false;
  //données pour le selecteur entre réel et previsionnel
  selectPrevisionnel: any = [
    {
      libelle : "Réel",
      valeur : false
    },
    {
      libelle : "Prévisionnel",
      valeur : true
    },
  ]
  //les 10 jours prévisionnels avec libelle (Lun/Mar/...) et objet date associé
  joursPrevision: any = [];

  //Variable permettant de gérer les champs éditables
  editedValue: number;

  //Variables permettant de remettre l'ancienne valeur à la case précédente dans le cas très spécifique de la tabulation
  previousChampId: number;
  previousUnite: any;
  previousIndexPrev: number;
  
  //Utilisateur connecté pour rendre les champs éditables ou non selon le cas
  user: User;

  isLoading: boolean = false;
  isSearching: boolean = false;
  isSavingPromise: Promise<void> = null;
  isErreurRequete: boolean = false;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private uniteService: UniteService,
    private territoireService: TerritoireService,
    private sesameDate : SesameDatePipe,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      // #region Réinitialisation des éléments
      // Obligatoire car le subscribe des paramètres de la route ci-dessus permet de recharger les resolvers et de rappeler le ngOnInit()
      // au simple changement du paramètre de la route, ce que l'on souhaite, mais sans recharger le composant entièrement,
      // et donc les variables globales à la page ne sont pas réinitialisées. Cette region permet de le faire.
      this.selected = [];
      this.joursPrevision = [];
      this.isTableauEntier = false;
      this.isPrevisionnel = false;
      // #endregion

      this.user = this.userService.currentUser;
  
      let getTerritoires: boolean = this.user.isRole(1) || this.user.isRole(2) || this.user.isRole(3) || this.user.territoires.length > 1;
  
      if(getTerritoires) {
        this.isLoading = true;
        this.territoireService.getAll().subscribe(territoires => {
          this.isLoading = false;
          
          this.territoires = territoires;
          this.selectedTerritoire = this.territoires.find(ter => ter.id == +routeParams.id);
        
          this.initMenu();
        });
      } else {
        this.initMenu();
      }
    });
  }

  initMenu() {
    //On récupère les établissements et secteurs du resolver
    if (this.route.snapshot.data.etablissements) this.etablissements = this.route.snapshot.data.etablissements;
    if (this.route.snapshot.data.secteurs) this.secteurs = this.route.snapshot.data.secteurs;

    //On renseigne le menu établissements
    this.etablissementsMenu = this.etablissements.map(etab => {
      //On passe au format any pour le menubar
      var e = etab as any;
      //Etablissement
      return {
          label: e.nom,
          id: e.id,
          command: () => { this.selectedEtablissement(e.id) },
          items : [
                    [
                      {
                        //Secteurs
                        label: 'Secteurs',
                        items : [{ label:'Tous', id:null, command : () => {this.selectedEtablissement(e.id)}}]
                                .concat(e.secteurs.map(s => {
                                  return {
                                    label: s.nom,
                                    id: s.id,
                                    command: () => {this.selectedEtablissement(e.id,s.id)}
                                    }}))                                
                      } 
                    ]
                  ]
            }});
            
    //On renseigne le menu secteurs
    this.secteursMenu = this.secteurs.map(sec => {
      var s = sec as any;
      return {        
        label: s.nom,
        id: s.id,
        command : () => {this.selectedSecteur(s.id)},
      }
    });

    //initialisation des 10 jours pour le tableau previsionnel
    this.initialiserJours();
  }

  selectTerritoire() {
    this.router.navigate([this.selectedTerritoire.id + '/tableau-de-bord']);
  }

  // #region Sélection des établissements / secteurs / services

  selectedEtablissement(idEtablissement : any, idSecteur? : any) {
    //Contrôle si secteur territoire sélectionné
    if(this.isSecteurSelected) this.selected = [];
    this.isSecteurSelected = false;
    //Si établissement sélectionné
    if(idEtablissement && !idSecteur){
      this.selected = this.selected.filter(s => s.idEtablissement != idEtablissement);
      this.selected.push({idEtablissement : idEtablissement, idSecteur: null});
    }
    //Si secteur sélectionné
    else if(!this.selected.some(s => s.idEtablissement == idEtablissement && s.idSecteur == idSecteur)){
      if(this.selected.filter(s => s.idEtablissement == idEtablissement && s.idSecteur == null).length > 0){
        this.selected = this.selected.filter(s => !(s.idEtablissement == idEtablissement && s.idSecteur == null));
      }
      this.selected.push({idEtablissement : idEtablissement, idSecteur: idSecteur});
    }
    this.first = 0;
    this.setMenuStyleClass();
    this.loadTableau();
  }

  selectedSecteur(id : number) {
    this.isSecteurSelected = true;
    this.selected = [{idEtablissement : null, idSecteur : id}];
    this.first = 0;
    this.setMenuStyleClass();
    this.loadTableau();
  }

  setMenuStyleClass() {
    if(this.isSecteurSelected){ //Selection secteur
      this.secteursMenu.forEach(secteur => {
        if(this.selected?.some(s => s.idSecteur == secteur.id)){
          secteur.styleClass = "menuItem";
        } else {
          secteur.styleClass = "";
        }
      });
      this.etablissementsMenu.forEach(etablissement => etablissement.styleClass = "");
    } else { //Selection établissements
      this.etablissementsMenu.forEach(etablissement => {
        if(this.selected?.some(s => s.idEtablissement == etablissement.id)){ //etablissement selectionné
          if(!etablissement.styleClass) etablissement.styleClass = "menuItem";
          if(this.selected[0]?.idSecteur){ //Secteurs selectionnés en particulier
            etablissement.items[0][0].items?.forEach(i => {
              if(i.id){
                if(this.selected?.some(s => s.idEtablissement == etablissement.id && s.idSecteur == i.id)){
                  i.styleClass = "menuItem";
                } else {
                  i.styleClass = "";
                }
              } else {
                i.styleClass = "";
              }
            });
          } else {//Tous les secteurs
            for(let i = 0; i < etablissement.items[0][0].items.length; i++){
              if(i==0){
                etablissement.items[0][0].items[i].styleClass = "menuItem";
              } else {
                etablissement.items[0][0].items[i].styleClass = "";
              }
            }
          }
        } else { //etablissement non selectionné
          etablissement.styleClass = "";
          etablissement.items[0][0].items.forEach(i => i.styleClass = "");
        }
      });
      this.secteursMenu.forEach(secteur => secteur.styleClass = "");
    }
    
    //Refresh l'affichage du menu pour l'application des styles
    let etablissementCopy = [...this.etablissementsMenu];
    let secteurCopy = [...this.secteursMenu];
    this.secteursMenu = [];
    this.etablissementsMenu = [];
    this.secteursMenu = secteurCopy;
    this.etablissementsMenu = etablissementCopy;
  }

  getDisplaySelected(selected : any) {
    let display = '';
    if(selected){
      if(selected.idEtablissement){
        let etablissement = this.etablissements?.find(e => e.id == selected.idEtablissement);
        display += etablissement.nom;
      }
      if(selected.idSecteur) {        
        if(display != ''){
          display += ' - ';
        }

        var secteur;
        if(selected.idEtablissement){          
          let etablissementMenu = this.etablissementsMenu?.find(e => e.id == selected.idEtablissement);
          let secteurs : MenuItem = etablissementMenu.items[0][0];
          secteur = secteurs.items?.find(secteur => secteur.id == selected.idSecteur);
        }
        else {
          secteur = this.secteursMenu?.find(s => s.id == selected.idSecteur);
        }
        if(secteur) display +='Secteur <b>'+ secteur.label +'</b>';
      }
    }
    return display;
  }

  removeSelected(selected) {
    this.isSecteurSelected = false;
    this.selected = this.selected.filter(s => s != selected);
    this.setMenuStyleClass();
    this.loadTableau();
  }

  // #endregion

  // #region Chargement des tableaux

  loadTableau() {
    this.isSearching = true;
    this.isErreurRequete = false;
    //On récupère la liste des secteurs
    var secteurs: number[] = [];
    var etablissements: number[] = [];
    this.selected.forEach(s => {
      if (s.idSecteur) {
        secteurs.push(parseInt(s.idSecteur));
      } else if (s.idEtablissement){
        etablissements.push(parseInt(s.idEtablissement));
      }
    });
    
    //Pour ne pas lancer si plus aucun critère
    if(secteurs.length > 0 || etablissements.length > 0){      
      if(!this.isPrevisionnel){
        this.loadTDB(secteurs,etablissements);          
      } else {
        this.loadPrevisionnel(secteurs,etablissements);      
      }
    }
  }

  loadTDB(secteurs: number[], etablissements: number[]) {
    //On récupère les tdb des secteurs concernés
    this.uniteService.getTableauDeBord(etablissements,secteurs).subscribe(tdb => {
      //Init tableau
      this.tableauDeBord = [];
      //Init totaux
      this.tdbTotal = new Object as any;
      this.tdbTotal.total = 0;      
      this.tdbTotal.capacitaireReel = 0;
      this.tdbTotal.dispoH = 0;
      this.tdbTotal.dispoF = 0;
      this.tdbTotal.dispoI = 0;
      this.tdbTotal.dispoT = 0;
      this.tdbTotal.occup = 0;
      this.tdbTotal.indispo = 0;
      this.tdbTotal.entrees = 0;
      this.tdbTotal.sorties = 0;
      this.tdbTotal.LTtotal = 0;
      this.tdbTotal.LTouverts = 0;
      this.tdbTotal.LTdispo = 0;

      //Contrôle si on affiche le libellé établissement (plusieurs étabs)
      var afficheEtab = this.isSecteurSelected || [...new Set(tdb.map((u) => u.etablissementID))].length > 1;
      //On consolide les données de chaque unité
      tdb.forEach(u => {
        //On récupère la date des données
        var dateHeure = u.donnees.filter(d => new Date(d.dateHeure).getTime() < new Date().getTime())?.sort((a,b) => { return new Date(b.dateHeure).getTime() - new Date(a.dateHeure).getTime()})[0]?.dateHeure;

        //Création de la ligne
        var item = new Object as any;
        if (afficheEtab) item.etablissement = u.etablissement.nom;
        item.id = u.id;
        item.nom = u.nom;
        item.nomOrder = item.etablissement ? item.etablissement + ' ' + item.nom : item.nom;
        item.dateHeure = dateHeure;

        // Unité modifiable ou non (si l'établissement ne possède pas SESAME et que le compte est sur l'établissement en question ou admin de territoire / bed manager de territoire)
        item.editable = !u.etablissement.connecteurId && (this.user.isRole(1)
                                                          || this.user.isRoleTerritoire(2, parseInt(this.route.snapshot.params.id))
                                                          || this.user.isRoleTerritoire(3, parseInt(this.route.snapshot.params.id))
                                                          || this.user.etablissementId == u.etablissementID);

        // Saisie des valeurs du tableau
        item.capacitaireReel = !item.editable ? this.getDonneeChamps(u.donnees,eChamps.CapacitaireReel) : u.getCapacitaireReel ? u.getCapacitaireReel : 0;
        item.dispoH = this.getDonneeChamps(u.donnees,eChamps.LitDispoHomme);
        item.dispoF = this.getDonneeChamps(u.donnees,eChamps.LitDispoFemme);
        item.dispoI = this.getDonneeChamps(u.donnees,eChamps.LitDispoIndetermine);
        item.dispoT = item.dispoH +  item.dispoF + item.dispoI;
        item.occup = !item.editable ? this.getDonneeChamps(u.donnees,eChamps.LitOccupeHomme) + this.getDonneeChamps(u.donnees,eChamps.LitOccupeFemme) + this.getDonneeChamps(u.donnees,eChamps.LitChargeOccupeHomme) + this.getDonneeChamps(u.donnees,eChamps.LitChargeOccupeFemme) : this.getDonneeChamps(u.donnees,eChamps.LitOccupeTotal);
        item.occupCapacitaire = !item.editable ? this.getDonneeChamps(u.donnees,eChamps.LitOccupeHomme) + this.getDonneeChamps(u.donnees,eChamps.LitOccupeFemme) : this.getDonneeChamps(u.donnees,eChamps.LitOccupeTotal); //On ne comptabilise pas les lits chargés dans le total
        item.indispo = this.getDonneeChamps(u.donnees,eChamps.LitIndisponible);
        item.total = item.dispoT + item.occupCapacitaire + item.indispo;
        item.entrees = !item.editable ? this.getDonneeChamps(u.donnees,eChamps.PreadmissionHomme)+ this.getDonneeChamps(u.donnees,eChamps.PreadmissionFemme) : this.getDonneeChamps(u.donnees,eChamps.PreadmissionTotal);
        item.sorties = !item.editable ? this.getDonneeChamps(u.donnees,eChamps.SortiePreviHomme) + this.getDonneeChamps(u.donnees,eChamps.SortiePreviFemme) : this.getDonneeChamps(u.donnees,eChamps.SortiePrevTotal);
        item.LTtotal = this.getDonneeChamps(u.donnees,eChamps.LitTensionTotal);
        item.LTouverts = this.getDonneeChamps(u.donnees,eChamps.LitTensionOuvert);
        item.LTdispo = this.getDonneeChamps(u.donnees,eChamps.LitTensionDisponible);

        this.tableauDeBord.push(item);
        
        //Calcul du total
        this.tdbTotal.total += item.total;
        this.tdbTotal.capacitaireReel += item.capacitaireReel;
        this.tdbTotal.dispoH += item.dispoH;
        this.tdbTotal.dispoF += item.dispoF;
        this.tdbTotal.dispoI += item.dispoI;
        this.tdbTotal.dispoT += item.dispoT;
        this.tdbTotal.occup += item.occup;
        this.tdbTotal.indispo += item.indispo;
        this.tdbTotal.entrees += item.entrees;
        this.tdbTotal.sorties += item.sorties;
        this.tdbTotal.LTtotal += item.LTtotal;
        this.tdbTotal.LTouverts += item.LTouverts;
        this.tdbTotal.LTdispo += item.LTdispo;
      });

      this.isSearching = false;
    },
    error => {
      this.isSearching = false;
      this.isErreurRequete = true;

      this.displayToastErreur(error);
    });   
  }

  loadPrevisionnel(secteurs: number[], etablissements: number[]) {
    this.uniteService.getPrevisionnel(etablissements,secteurs).subscribe(previsionnel => {
      this.previsionnel = [];
      this.previsionnelTotal = [];
      //init des totaux (modulaire avec NB_JOUR_PREVISION)
      for(var i = 0; i <= this.NB_JOUR_PREVISION; i++) this.previsionnelTotal.push(0);
      //Contrôle si on affiche le libellé établissement (plusieurs étabs)
      var afficheEtab = this.isSecteurSelected || [...new Set(previsionnel.map((u) => u.etablissementID))].length > 1;      
      previsionnel.forEach(u => {
        //Création de l'élément de l'unité
        var item = new Object as any;
        if (afficheEtab) item.etablissement = u.etablissement.nom;
        item.id = u.id;
        item.nom = u.nom;
        item.nomOrder = item.etablissement ? item.etablissement + ' ' + item.nom : item.nom;
        item.previsionnel = [];
        for(var i = 0; i <= this.NB_JOUR_PREVISION; i++){
          //récupérer la prévision du jour en question
          var data = u.donnees?.filter(d =>
            //pas de transformation avec new Date pour ne pas changer la timezone
            this.sesameDate.transform(d.dateHeure,'dd/MM/yyyy') == this.sesameDate.transform(this.joursPrevision[i].dateObject,'dd/MM/yyyy')
          );
          //une prevision (si plus, problème, donc on considère aucune)
          if(data.length == 1){
            item.previsionnel[i] = data[0].valeur;
            this.previsionnelTotal[i] += data[0].valeur;
          //aucune prévision
          } else {
            item.previsionnel[i] = "-";
          }
        }

        // Unité modifiable ou non (si l'établissement ne possède pas SESAME et que le compte est sur l'établissement en question ou admin de territoire / bed manager de territoire)
        item.editable = !u.etablissement.connecteurId && (this.user.isRole(1)
                                                          || this.user.isRoleTerritoire(2, parseInt(this.route.snapshot.params.id))
                                                          || this.user.isRoleTerritoire(3, parseInt(this.route.snapshot.params.id))
                                                          || this.user.etablissementId == u.etablissementID);

        this.previsionnel.push(item);
      });

      this.isSearching = false;
    },
    error => {
      this.isSearching = false;
      this.isErreurRequete = true;

      this.displayToastErreur(error);
    });
  }

  // #endregion

  // #region Utilitaires

  getDateHeure(unite:any) {
    if (unite?.dateHeure) {
      //On calcul la différence
      var now = new Date();
      var date = new Date(unite.dateHeure);
      var diffMs = (now.getTime() - date.getTime()); // milliseconds
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      var retour = "Mis à jour il y a";
      if (diffHrs > 0) {
        retour += " " + diffHrs;
        retour += diffHrs > 1 ? " heures" : " heure";
      }
      if (diffMins > 0 || diffHrs == 0) {
        retour += " "+ diffMins;
        retour += diffMins > 1 ? " minutes" : " minute";
      }
      return retour; 
    }
  }

  getDonneeChamps(donnees:TDB_Donnees[],champsId:number) {
    //On récupère la donnée du jour la plus récente du champs
    var donnee = donnees.filter(d => d.champsID == champsId && new Date(d.dateHeure).getTime() < new Date().getTime())?.sort((a,b) => { return new Date(b.dateHeure).getTime() - new Date(a.dateHeure).getTime()})[0]?.valeur;
    return donnee ? donnee : 0;
  }

  getCouleurTension(TotalLit: number, LitDisponible: number) {
    if (LitDisponible == 0) return "FondRouge";
    if (LitDisponible > TotalLit / 4) return "FondVert";
    if (LitDisponible <= TotalLit / 4) return "FondJaune";
  }

  getClassValeur(valeur : number) {
    if(!isNaN(Number(valeur)) && valeur <= 0) return 'alerte';
    return '';
  }

  initialiserJours() {
    for(let i = 0; i <= this.NB_JOUR_PREVISION; i++){
      //Mettre à la date voulue
      let dateCible = new Date();
      dateCible.setDate(dateCible.getDate() + i);
      dateCible.setHours(0,0,0,0);

      let element = {libelle : Jours[dateCible.getDay()], dateObject : dateCible};
      this.joursPrevision.push(element);
    }
  }

  displayToastErreur(error: any) {
    let summary: string;
    let detail: string;
    if(error.status == 401) {
      summary = "Accès restreint";
      detail = "Au moins un établissement ou un secteur sélectionné n'est rattaché à aucun de vos territoires.";
    }
    else {
      summary = "Une erreur est survenue.";
      detail = "Une erreur est survenue.";
    }
    
    this.messageService.add({
      severity: "error",
      summary: summary,
      detail: detail
    });
  }

  // #endregion

  // #region Fonctions au clic sur les divers boutons
  
  affichageTableau() {
    this.isTableauEntier = !this.isTableauEntier;
  }

  clickSelectPrevision(event : any) {
    if(event.option.valeur != this.isPrevisionnel){
      this.isPrevisionnel = !this.isPrevisionnel;
      this.loadTableau();
    }
  }

  // #endregion

  // #region Modification des champs du tableau

  onKeyDown(event: KeyboardEvent) {
    // Empêche la saisie du caractère "-" (tiret)
    if (event.key === '-') {
      event.preventDefault();
    }
  }
  
  onEditInit(event) {
    // #region Sécurité

    if(this.previousChampId && this.previousUnite && (this.previousChampId != 15 || this.previousIndexPrev != null)) {
      // Si ces deux variables sont remplies (trois si on modifie le tableau prévisionnel), c'est que l'on était dans le else du onEditComplete(), signifiant qu'il n'y a pas eu de modification de la valeur.
      // Or, on a vu que l'on pouvait arriver dans ce else dans le cas précis de la tabulation dans une case du tableau (voir commentaire dans le else du onEditComplete()).
      // Donc dans ce cas précis, on relance le onEditComplete() avec la valeur mise à jour, juste avant de gérer l'arrivée dans la nouvelle case.

      this.onEditComplete({ data: this.previousUnite, field: (this.previousIndexPrev != null ? this.previousChampId.toString() + "#" + this.previousIndexPrev.toString() : this.previousChampId.toString()) });

      this.previousChampId = null;
      this.previousUnite = null;
      this.previousIndexPrev = null;
    }

    // #endregion

    if (!this.isSavingPromise) {
      this.initEditedValue(event);
    } else {
      // S'il y a un onEditComplete() en cours, on attend qu'il se termine grâce à la promesse pour initialiser la prochaine case
      this.isSavingPromise.then(() => {
        this.isSavingPromise = null;
        this.initEditedValue(event);
      });
    }
  }

  initEditedValue(event) {
    let field: string[] = event.field.split('#');
    let champId: number = +field[0];
    let indexColonnePrev: number;

    switch(champId) {
      case eChamps.LitDispoHomme:
        this.editedValue = +event.data.dispoH;
        break;
      case eChamps.LitDispoFemme:
        this.editedValue = +event.data.dispoF;
        break;
      case eChamps.LitDispoIndetermine:
        this.editedValue = +event.data.dispoI;
        break;
      case eChamps.LitOccupeTotal:
        this.editedValue = +event.data.occup;
        break;
      case eChamps.LitIndisponible:
        this.editedValue = +event.data.indispo;
        break;
      case eChamps.PreadmissionTotal:
        this.editedValue = +event.data.entrees;
        break;
      case eChamps.SortiePrevTotal:
        this.editedValue = +event.data.sorties;
        break;
      case eChamps.LitTensionTotal:
        this.editedValue = +event.data.LTtotal;
        break;
      case eChamps.LitTensionOuvert:
        this.editedValue = +event.data.LTouverts;
        break;
      case eChamps.LitTensionDisponible:
        this.editedValue = +event.data.LTdispo;
        break;
      case eChamps.PrevisionnelLitsDisponibles:
        indexColonnePrev = +field[1];
        this.editedValue = event.data.previsionnel[indexColonnePrev] != "-" ? +event.data.previsionnel[indexColonnePrev] : null;
        break;
    }
  }

  onEditComplete(event) {
    this.isSavingPromise = new Promise<void>((resolve) => {
      let uniteId: number = +event.data.id;
      let field: string[] = event.field.split('#');
      let champId: number = +field[0];
      let indexColonnePrev: number;
      let valeur: number;

      // #region Récupération de la valeur modifiée
      switch(champId) {
        case eChamps.LitDispoHomme:
          event.data.dispoH = event.data.dispoH != null ? event.data.dispoH : 0;
          valeur = +event.data.dispoH;
          break;
        case eChamps.LitDispoFemme:
          event.data.dispoF = event.data.dispoF != null ? event.data.dispoF : 0;
          valeur = +event.data.dispoF;
          break;
        case eChamps.LitDispoIndetermine:
          event.data.dispoI = event.data.dispoI != null ? event.data.dispoI : 0;
          valeur = +event.data.dispoI;
          break;
        case eChamps.LitOccupeTotal:
          event.data.occup = event.data.occup != null ? event.data.occup : 0;
          valeur = +event.data.occup;
          break;
        case eChamps.LitIndisponible:
          event.data.indispo = event.data.indispo != null ? event.data.indispo : 0;
          valeur = +event.data.indispo;
          break;
        case eChamps.PreadmissionTotal:
          event.data.entrees = event.data.entrees != null ? event.data.entrees : 0;
          valeur = +event.data.entrees;
          break;
        case eChamps.SortiePrevTotal:
          event.data.sorties = event.data.sorties != null ? event.data.sorties : 0;
          valeur = +event.data.sorties;
          break;
        case eChamps.LitTensionTotal:
          event.data.LTtotal = event.data.LTtotal != null ? event.data.LTtotal : 0;
          valeur = +event.data.LTtotal;
          break;
        case eChamps.LitTensionOuvert:
          event.data.LTouverts = event.data.LTouverts != null ? event.data.LTouverts : 0;
          valeur = +event.data.LTouverts;
          break;
        case eChamps.LitTensionDisponible:
          event.data.LTdispo = event.data.LTdispo != null ? event.data.LTdispo : 0;
          valeur = +event.data.LTdispo;
          break;
        case eChamps.PrevisionnelLitsDisponibles:
          indexColonnePrev = +field[1];
          valeur = event.data.previsionnel[indexColonnePrev] != null && event.data.previsionnel[indexColonnePrev] != "-" ? +event.data.previsionnel[indexColonnePrev] : null;
          break;
      }
      // #endregion

      if(this.editedValue != valeur) {
        let donnees: TDB_Donnees = new TDB_Donnees();
        donnees.uniteID = uniteId;
        donnees.champsID = champId;
        donnees.valeur = valeur;
        if(champId == eChamps.PrevisionnelLitsDisponibles) {
          donnees.dateHeure = this.joursPrevision[indexColonnePrev].dateObject;
        }

        this.uniteService.updateChamp(donnees).subscribe(
          donnees => {
            resolve();

            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'Le champ a bien été modifié.'
            });

            // #region Mise à jour visuelle du tableau (compteurs impactés par la modification de la valeur + tooltip de l'heure de dernière mise à jour)
            if(champId != eChamps.PrevisionnelLitsDisponibles) {
              switch(champId) {
                case eChamps.LitDispoHomme:
                case eChamps.LitDispoFemme:
                case eChamps.LitDispoIndetermine:
                  let oldDispoT: number = +event.data.dispoT;
                  let oldTotal: number = +event.data.total;
                  event.data.dispoT = event.data.dispoH + event.data.dispoF + event.data.dispoI;
                  event.data.total = event.data.dispoT + event.data.occupCapacitaire + event.data.indispo;
                  this.tdbTotal.total = this.tdbTotal.total - oldTotal + event.data.total;
                  this.tdbTotal.dispoT = this.tdbTotal.dispoT - oldDispoT + event.data.dispoT;
                  if(champId == eChamps.LitDispoHomme) {
                    this.tdbTotal.dispoH = this.tdbTotal.dispoH - this.editedValue + event.data.dispoH;
                  } else if (champId == eChamps.LitDispoFemme) {
                    this.tdbTotal.dispoF = this.tdbTotal.dispoF - this.editedValue + event.data.dispoF;
                  } else {
                    this.tdbTotal.dispoI = this.tdbTotal.dispoI - this.editedValue + event.data.dispoI;
                  }
                  break;
                case eChamps.LitOccupeTotal:
                  this.tdbTotal.occup = this.tdbTotal.occup - this.editedValue + event.data.occup;
                  break;
                case eChamps.LitIndisponible:
                  this.tdbTotal.indispo = this.tdbTotal.indispo - this.editedValue + event.data.indispo;
                  break;
                case eChamps.PreadmissionTotal:
                  this.tdbTotal.entrees = this.tdbTotal.entrees - this.editedValue + event.data.entrees;
                  break;
                case eChamps.SortiePrevTotal:
                  this.tdbTotal.sorties = this.tdbTotal.sorties - this.editedValue + event.data.sorties;
                  break;
                case eChamps.LitTensionTotal:
                  this.tdbTotal.LTtotal = this.tdbTotal.LTtotal - this.editedValue + event.data.LTtotal;
                  break;
                case eChamps.LitTensionOuvert:
                  this.tdbTotal.LTouverts = this.tdbTotal.LTouverts - this.editedValue + event.data.LTouverts;
                  break;
                case eChamps.LitTensionDisponible:
                  this.tdbTotal.LTdispo = this.tdbTotal.LTdispo - this.editedValue + event.data.LTdispo;
                  break;
              }
              event.data.dateHeure = donnees.dateHeure;
            } else {
              this.previsionnelTotal[indexColonnePrev] = this.previsionnelTotal[indexColonnePrev] - (this.editedValue != null ? this.editedValue : 0) + (event.data.previsionnel[indexColonnePrev] != null ? event.data.previsionnel[indexColonnePrev] : 0);
              event.data.previsionnel[indexColonnePrev] = event.data.previsionnel[indexColonnePrev] != null ? event.data.previsionnel[indexColonnePrev] : "-";
            }
            // #endregion
          },
          (e) => {
            resolve();

            console.error("ERREUR MODIFICATION CHAMP");
            console.error(e);

            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la modification du champ',
              detail: 'Le champ n\'a pas pu être modifier. Veuillez réessayer.'
            });

            // #region On remet l'ancienne valeur
            switch(champId) {
              case eChamps.LitDispoHomme:
                event.data.dispoH = this.editedValue;
                break;
              case eChamps.LitDispoFemme:
                event.data.dispoF = this.editedValue;
                break;
              case eChamps.LitDispoIndetermine:
                event.data.dispoI = this.editedValue;
                break;
              case eChamps.LitOccupeTotal:
                event.data.occup = this.editedValue;
                break;
              case eChamps.LitIndisponible:
                event.data.indispo = this.editedValue;
                break;
              case eChamps.PreadmissionTotal:
                event.data.entrees = this.editedValue;
                break;
              case eChamps.SortiePrevTotal:
                event.data.sorties = this.editedValue;
                break;
              case eChamps.LitTensionTotal:
                event.data.LTtotal = this.editedValue;
                break;
              case eChamps.LitTensionOuvert:
                event.data.LTouverts = this.editedValue;
                break;
              case eChamps.LitTensionDisponible:
                event.data.LTdispo = this.editedValue;
                break;
              case eChamps.PrevisionnelLitsDisponibles:
                event.data.previsionnel[indexColonnePrev] = this.editedValue != null ? this.editedValue : "-";
                break;
            }
            // #endregion
          }
        );
      } else {
        resolve();

        // Si on modifiait le prévisionnel et qu'il y avait un tiret, on le remet car en entrant dans le champ en mode modification,
        // le composant Primeng passe directement la valeur à null car il ne comprend pas le - dans un inputNumber
        if(champId == eChamps.PrevisionnelLitsDisponibles && this.editedValue == null) {
          event.data.previsionnel[indexColonnePrev] = "-";
        }

        // #region Sécurité

        // On peut arriver dans ce else lorsque l'on fait une tabulation dans une case du tableau.
        // On passe alors à la case d'après mais le fait de quitter la case précédente n'enregistre pas la modification en base de données car en faisant la tabulation,
        // on passe à la case d'après AVANT que le ngModel n'agisse sur la valeur, donc on arrive ici, comme s'il n'y avait pas eu de modification.
        // Sauf que visuellement, la nouvelle valeur saisie persiste, ce qui peut amener une confusion puisqu'elle n'est pas enregistrée en base et que les totaux impactés par cette valeur ne sont pas modifiés en direct.
        // On saisit donc trois variables qui permettront de relancer l'enregistrement avec la nouvelle valeur lorsque l'on arrivera dans la fonction onEditInit() de la case d'après parce qu'à ce moment-là,
        // le ngModel aura bien eu effet et donc on pourra revenir en arrière.

        this.previousChampId = champId;
        this.previousUnite = event.data;
        this.previousIndexPrev = indexColonnePrev;

        // #endregion
      }
    });
  }

  // #endregion
}

//Enumération des jours associée aux résultats de Date.getDay() (0 à 6)
enum Jours {
  Dim = 0,
  Lun,
  Mar,
  Mer,
  Jeu,
  Ven,
  Sam,
}