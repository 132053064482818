import { Etablissement } from "./annuaire/etablissement";
import { Capacitaire } from "./capacitaire";
import { TDB_Donnees } from "./tdb_donnees";

 export class Unite {
   id: number;
   etablissementID: number;
   nom: string = ""; 
   code: string = "";
   isActif: boolean = true;

   etablissement: Etablissement;
   donnees: TDB_Donnees[] = [];
   capacitaires: Capacitaire[] = [];

   // L'établissement, les données et le capacitaires doivent être inclus dans l'objet pour que cette fonction fonctionne
   get getCapacitaireReel(): number {
      if(this.etablissement.connecteurId) {
         let donneesCapacitaire: TDB_Donnees[] = this.donnees.filter(d => d.champsID == 1 && new Date(d.dateHeure).getTime() < new Date().getTime());
         if(donneesCapacitaire.length > 0) {
            return donneesCapacitaire.sort((d1, d2) => { return new Date(d2.dateHeure).getTime() - new Date(d1.dateHeure).getTime()})[0].valeur;
         }
      } else {
         if(this.capacitaires.length > 0) {
            let capacitaire: Capacitaire = this.capacitaires[0];
            if(capacitaire.capacitaireReel) {
               if(new Date(capacitaire.dateReelDebut) <= new Date() && (!capacitaire.dateReelFin || new Date(capacitaire.dateReelFin) >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))) {
                  return capacitaire.capacitaireReel;
               } else if(capacitaire.capacitaireMax) {
                  return capacitaire.capacitaireMax;
               }
            } else if(capacitaire.capacitaireMax) {
               return capacitaire.capacitaireMax;
            }
         }
      }

      return null;
   }
}