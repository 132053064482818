
 export class TDB_Champs {
    public id: number;
    public libelle: string;  
    public correspondance: number;        
 }

 export enum eChamps {
   CapacitaireReel = 1,
   LitDispoHomme = 2,
   LitDispoFemme = 3,
   LitDispoIndetermine = 4,
   LitOccupeHomme = 5,
   LitOccupeFemme = 6,
   LitIndisponible = 7,
   PreadmissionHomme = 8,
   PreadmissionFemme = 9,
   SortiePreviHomme = 10,
   SortiePreviFemme = 11,        
   LitTensionTotal = 12,
   LitTensionOuvert = 13,
   LitTensionDisponible = 14,
   PrevisionnelLitsDisponibles = 15,   
   LitChargeOccupeHomme = 16,
   LitChargeOccupeFemme = 17,
   LitOccupeTotal = 18,
   PreadmissionTotal = 19,
   SortiePrevTotal = 20,
   NombreDePassagesJ1 = 21,
   Presents = 22,
   NonVus6h = 23,
   HospitalisationCouloir = 24,
   TempsDePresence = 25,
   NombrePatients75ans = 26,
   TransfertEnAttenteDepuis = 27,
   RAD = 28,
   Hospitalisation48h = 29,
   NonVus = 30,
   HospitalisationCouloir24h = 31,
   HospitalisationUHCD = 32
 }