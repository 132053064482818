<app-nav-menu></app-nav-menu>
<div class="container">
    <div style="display: flex; justify-content:space-between;">
        <div class="titre-parametrage">
            Paramétrage > <b>Gestion des rôles</b>
        </div>
        <div *ngIf="!isLoading">
            <p-dropdown *ngIf="territoires.length > 1" id="territoire-etab" [options]="territoires"
                [(ngModel)]="selectedTerritoire" optionLabel="nom" (onChange)="selectTerritoire()">
            </p-dropdown>
        </div>
    </div>

    <div *ngIf="!isLoading; else loadingBlock">
        <div class="p-d-flex p-d-flex-row message-alert">
            <div class="message-icon">
                <span class="fa-sharp fa-solid fa-circle-info"></span>
            </div>
            <div class="message-content">
                Pour chaque rôle, les collaborateurs disponibles sont affichés dans les listes de gauche. Les collaborateurs possédant déjà le rôle correspondant sont affichés dans les listes de droite.<br/>
                <b>Passez dans les listes de droite les collaborateurs auxquels vous souhaitez affecter le rôle correspondant grâce aux flèches ensuite cliquez sur valider pour sauvegarder les modifications.</b>
            </div>
        </div>
        <div *ngIf="user.isRole(1)" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <h5>Administrateurs de la plateforme</h5>
                <p-pickList [source]="nonAdminAmc" [target]="adminAmc"
                    sourceFilterPlaceholder="Rechercher par nom" targetFilterPlaceholder="Rechercher par nom" filterBy="display">
                    <ng-template let-collab pTemplate="item">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <span>{{collab.display}}</span>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <h5>Administrateurs du Territoire - {{selectedTerritoire?.nom}}</h5>
                <p-pickList [source]="nonAdminSelectedTerritoire" [target]="adminSelectedTerritoire"
                    sourceFilterPlaceholder="Rechercher par nom" targetFilterPlaceholder="Rechercher par nom" filterBy="display">
                    <ng-template let-collab pTemplate="item">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <span> {{collab.display}}</span>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <h5>Bed Managers de territoire - {{selectedTerritoire?.nom}}</h5>
                <p-pickList [source]="nonBedSelectedTerritoire" [target]="bedSelectedTerritoire"
                    sourceFilterPlaceholder="Rechercher par nom" targetFilterPlaceholder="Rechercher par nom" filterBy="display">
                    <ng-template let-collab pTemplate="item">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <span>{{collab.display}}</span>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12">
                <button type="button" class="btn-amc green" (click)="validate()" [disabled]="!selectedTerritoire || isSaving">
                    <span class="icon-validate">&nbsp; Valider</span>
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #loadingBlock>
    <div style="text-align: center">
        <sesame-loader></sesame-loader>
    </div>
</ng-template>
