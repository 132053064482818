import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MegaMenuItem, MenuItem, MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { Secteur } from 'src/app/shared/models/secteur';
import { Territoire } from 'src/app/shared/models/territoire';
import { Unite } from 'src/app/shared/models/unite';
import { SecteurService } from 'src/app/shared/services/secteur.service';
import { TerritoireService } from 'src/app/shared/services/territoire.service';
import { UniteService } from 'src/app/shared/services/unite.service';

@Component({
  selector: 'app-secteurs-de-territoire',
  templateUrl: './secteurs-de-territoire.component.html',
  styleUrls: ['./secteurs-de-territoire.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecteursDeTerritoireComponent implements OnInit {
  
  isLoading: boolean = false;

  territoireId: number;

  territoires: Territoire[] = [];
  selectedTerritoire: Territoire;

  secteurs: Secteur[] = [];
  etablissements: Etablissement[] = [];
  services: Unite[] = [];
  
  editingSecteurId: number;
  editingSecteurNom: string;

  loading : boolean = false;

  constructor (
    private confirmationService : ConfirmationService,
    private secteurService: SecteurService,
    private uniteService: UniteService,
    private territoireService: TerritoireService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      this.loading = true;

      this.territoireId = parseInt(routeParams.id);
      
      // On récupère les établissements et secteurs du resolver
      if (this.route.snapshot.data.etablissements) this.etablissements = this.route.snapshot.data.etablissements;
      if (this.route.snapshot.data.secteurs) this.secteurs = this.route.snapshot.data.secteurs;

      combineLatest([
        this.territoireService.getAll(),
        this.uniteService.getByEtablissementIds(this.etablissements.map(e => e.id)) // On récupère les unités / services de tous les établissements du resolver
      ])
      .subscribe(([territoires, services]) => {
        this.territoires = territoires;
        this.selectedTerritoire = this.territoires.find(ter => ter.id == this.territoireId);

        this.services = services;

        // Mise en forme du MegaMenu de chaque secteur de territoire avec les établissements au premier niveau et les services au second niveau
        this.secteurs.forEach(secteur => this.setMegaMenuItem(secteur));
        
        this.loading = false;
      });
    });
  }

  setMegaMenuItem(secteur: Secteur) {
    secteur.etablissementsMenu = this.etablissements.map(etab => {
      // MegaMenu de l'établissement
      let megaMenu: MegaMenuItem = {};
      megaMenu.id = etab.id.toString();
      megaMenu.label = etab.nom;

      // Saisie des enfants de l'établissement (unités / services) en vérifiant si au moins l'un des services est associé au secteur de territoire
      let isSecteurUnite: boolean = false;
      megaMenu.items = [[
        {
          label: "Services",
          items: [
            {
              id: null,
              label: "Tous",
              command: () => { this.selectService(secteur, etab.id) },
              icon: this.services.filter(s => s.etablissementID == etab.id).length > 0 && this.services.filter(s => s.etablissementID == etab.id && !secteur.secteurUnites.map(su => su.uniteID).includes(s.id)).length == 0 ? "fas fa-check-circle" : ""
            } as MenuItem
          ].concat(this.services.filter(s => s.etablissementID == etab.id).map(service => {
            let menu: MenuItem = {} as MenuItem;
            menu.id = service.id.toString();
            menu.label = service.nom;
            if(service.isActif) {
              menu.command = () => { this.selectService(secteur, etab.id, service.id) };
            } else {
              menu.disabled = true;
            }
            if(secteur.secteurUnites.filter(su => su.uniteID == service.id).length > 0) {
              menu.icon = "fas fa-check-circle";
              isSecteurUnite = true;
            } else {
              menu.icon = "";
            }
            return menu;
          }) as MenuItem[])
        }
      ]];

      // Si au moins l'un des services de l'établissement est associé au secteur de territoire, on application le style au MegaMenu de l'établissement
      if(isSecteurUnite) {
        megaMenu.styleClass = "menuItem";
      } else {
        megaMenu.styleClass = "";
      }

      return megaMenu;
    }) as MegaMenuItem[];
  }

  selectTerritoire() {
    this.router.navigate([this.selectedTerritoire.id + '/parametrage/secteurs-de-territoire']);
  }

  // #region ------------- SECTEURS -------------

  onNomSecteurClick(secteur: Secteur) {
    this.editingSecteurId = secteur.id;
    this.editingSecteurNom = secteur.nom;
    setTimeout(() => {
      document.getElementById('input-' + secteur.id).focus();
    }, 100);
  }

  keyPress(event: KeyboardEvent, secteur: Secteur) {
    if(event.key == 'Enter') {
      this.addOrUpdateSecteur(secteur);
    }
  }

  createSecteur() {
    // Mise en forme du nouveau secteur
    let newSecteur: Secteur = new Secteur();
    newSecteur.territoireId = this.territoireId;
    this.secteurs.push(newSecteur);
    this.onNomSecteurClick(newSecteur);
  }

  removeSecteur(secteur: Secteur) {
    this.confirmationService.confirm({
      message: "Souhaitez-vous vraiment supprimer le secteur <b>" + secteur.nom + "</b> ? Attention, cette action est définitive.",
      accept: () => {
        this.secteurService.remove(secteur.id).subscribe(() => {
          // Mise à jour front
          this.secteurs = this.secteurs.filter(s => s.id != secteur.id);

          this.messageService.add({ 
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le secteur a bien été supprimé.'
          });
        },
        (e) => {
          console.error("ERREUR SUPPRESION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la suppression du secteur',
            detail: 'Le secteur n\'a pas pu être supprimé. Veuillez réessayer.'
          });
        });
      }
    });
  }

  addOrUpdateSecteur(secteur: Secteur) {
    this.editingSecteurId = null;
    if(!secteur.id) {
      if(!secteur.nom || secteur.nom == '') {
        this.secteurs.pop();
      } else {
        this.secteurService.add(secteur).subscribe(s => {
          // Mise à jour front
          this.secteurs.pop();
          this.secteurs.push(s);
          s.secteurUnites = [];
          this.setMegaMenuItem(s);
  
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le secteur a bien été créé.'
          });
        },
        (e) => {
          console.error("ERREUR CREATION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la création du secteur',
            detail: 'Le secteur n\'a pas pu être créé. Veuillez réessayer.'
          });
        });
      }
    } else {
      if(!secteur.nom || secteur.nom == '') {
        secteur.nom = this.editingSecteurNom;
        this.editingSecteurNom = null;
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur lors de la modification du secteur',
          detail: 'Le nom du secteur ne doit pas être vide.'
        });
      } else if(this.editingSecteurNom != secteur.nom) {
        this.secteurService.update(secteur).subscribe(() => {
          this.editingSecteurNom = null;
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail: 'Le secteur a bien été modifié.'
          });
        },
        (e) => {
          secteur.nom = this.editingSecteurNom;
          this.editingSecteurNom = null;
          console.error("ERREUR MODIFICATION SECTEUR");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification du secteur',
            detail: 'Le secteur n\'a pas pu être modifié. Veuillez réessayer.'
          });
        });
      }
    }
  }

  // #endregion

  // #region ------------- GESTION DES ÉTABLISSEMENTS --> UNITÉS / SERVICES DANS LES SECTEURS -------------

  selectService(secteur: Secteur, etabId: number, serviceId?: number) {
    // Si on clique sur "Tous" mais qu'il n'y a aucun service dans l'établissement, on ne fait rien
    if(!serviceId && this.services.filter(s => s.etablissementID == etabId).length == 0) {
      return;
    }

    let isAjout: boolean;
    let serviceIds: number[] = [];

    // #region Récupération des IDs d'unité / service à ajouter / supprimer sur le secteur et de l'item du menu pour gérer l'affichage ensuite
    
    if(serviceId) {
      isAjout = !secteur.secteurUnites.find(su => su.uniteID == serviceId);
      serviceIds.push(serviceId);
    } else {
      isAjout = this.services.filter(s => s.etablissementID == etabId && !secteur.secteurUnites.map(su => su.uniteID).includes(s.id)).length > 0;
      serviceIds = this.services.filter(s => s.etablissementID == etabId
                                             && ((isAjout && !secteur.secteurUnites.map(su => su.uniteID).includes(s.id))
                                                 || (!isAjout && secteur.secteurUnites.map(su => su.uniteID).includes(s.id)))).map(s => s.id);
    }

    // #endregion

    if(isAjout) {
      // #region Ajout du ou des unités au secteur

      this.secteurService.addSecteurUnites(secteur.id, serviceIds).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail: serviceId ? 'Le service a bien été associé au secteur.' : 'Les services ont bien été associés au secteur.'
        });

        // Mise à jour front
        serviceIds.forEach(id => secteur.secteurUnites.push({ secteurID: secteur.id, uniteID: id }));
        this.setMegaMenuItem(secteur);
      },
      (e) => {
        console.error("ERREUR ASSOCIATION SERVICE(S) AVEC SECTEUR");
        console.error(e);

        this.messageService.add({
          severity: 'error',
          summary: serviceId ? 'Erreur lors de l\'ajout du service dans le secteur' : 'Erreur lors de l\'ajout des services dans le secteur',
          detail: serviceId ? 'Le service n\'a pas pu être ajouté dans le secteur. Veuillez réessayer.' : 'Les services n\'ont pas pu être ajoutés dans le secteur. Veuillez réessayer.'
        });
      });

      // #endregion
    } else {
      // #region Suppression du ou des unités du secteur

      this.secteurService.removeSecteurUnites(secteur.id, serviceIds).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail: serviceId ? 'Le service a bien été supprimé du secteur.' : 'Les services ont bien été supprimés du secteur.'
        });

        // Mise à jour front
        secteur.secteurUnites = secteur.secteurUnites.filter(su => !serviceIds.includes(su.uniteID));
        this.setMegaMenuItem(secteur);
      },
      (e) => {
        console.error("ERREUR SUPPRESSION DE L'ASSOCATION DES SERVICE(S) DANS LE SECTEUR");
        console.error(e);

        this.messageService.add({
          severity: 'error',
          summary: serviceId ? 'Erreur lors de la suppression du service dans le secteur' : 'Erreur lors de la suppression des services dans le secteur',
          detail: serviceId ? 'Le service n\'a pas pu être supprimé dans le secteur. Veuillez réessayer.' : 'Les services n\'ont pas pu être supprimés dans le secteur. Veuillez réessayer.'
        });
      });

      // #endregion
    }
  }

  // #endregion
}
