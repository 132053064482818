import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Collaborateur } from '../shared/models/annuaire/collaborateur';
import { Etablissement } from '../shared/models/annuaire/etablissement';
import { CollaborateurService } from '../shared/services/annuaire/collaborateur.service';
import { EtablissementService } from '../shared/services/annuaire/etablissement.service';
import { PopUpRechercheAvanceeComponent } from './pop-up-recherche-avancee/pop-up-recherche-avancee.component';
import { PopUpModificationMdpComponent } from './pop-up-modification-mdp/pop-up-modification-mdp.component';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fonction } from '../shared/models/annuaire/fonction';
import { CollaborateurFonction } from '../shared/models/annuaire/collaborateurFonction';
import { FonctionService } from '../shared/services/annuaire/fonction.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {
  identifiantValid: boolean;
  loadingLogin: boolean = false;
  isSelectedEtab: boolean = false;
  inscriptionForm : UntypedFormGroup;
  mdpType: string = "password";
  fonctions: Fonction[] = [];
  refAvancee: DynamicDialogRef;

  etablissements: Etablissement[] = [];

  refPassword: DynamicDialogRef;

  @Input() mode: number = 0;
  collaborateurToValidate: Collaborateur;
  loading: boolean = false;

  timer: any;
  waitTime = 500;
  unsubscribeSearchLogin: Subscription = null;

  constructor(
    private formBuilder : UntypedFormBuilder,
    private collaborateurService: CollaborateurService,
    public dialogService: DialogService,
    private etablissementService: EtablissementService,
    private fonctionService: FonctionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    /** Récupère les informations en fonction de la route 
     * Mode 0 = Inscription
     * Mode 1 = depuis l'administration, l'admin souhaite Créer un compte
     * Mode 2 = depuis l'administration, l'admin souhaite Activer un compte
     * Mode 3 = depuis l'admnistration, l'admin souhaite Modifier un compte
     * Mode 4 = Mon profil
     */
    if(this.mode == 0 && history.state.mode) {
      this.mode = history.state.mode;
    }

    // Récupération du collaborateur pour Activer / Modifier un compte
    if(this.mode == 2 || this.mode == 3) {
      this.collaborateurToValidate = history.state.collaborateur;
    }
    
    this.loading = true;
    
    // Récupération du collaborateur pour Mon profil et de la liste des fonctions
    if(this.mode == 4) {
      combineLatest([
        this.collaborateurService.getMyProfil(),
        this.fonctionService.getAll()
      ]).
      subscribe(([collab, fonctions]) => {
        this.loading = false;
        this.collaborateurToValidate = collab;
        this.fonctions = fonctions;
        this.initForm();
      });
    } else if(this.mode == 0 || this.mode == 1 || this.mode == 3) {
      // Récupération des établissements et de la liste des fonctions 
      combineLatest([
        this.etablissementService.getAll(),
        this.fonctionService.getAll()
      ]).
      subscribe(([etabs, fonctions]) => {
        this.loading = false;
        this.etablissements = etabs;
        this.fonctions = fonctions;
        this.initForm();
      });
    } else {
      // Récupération de la liste des fonctions
      this.fonctionService.getAll().subscribe(fonctions => {
        this.loading = false;
        this.fonctions = fonctions;
        this.initForm();
      });
    }
  }

  initForm() {
    if(this.mode == 0 || this.mode == 1) {
      // Initialise les champs à null
      this.inscriptionForm = this.formBuilder.group({
        etabNom: [null, Validators.required],
        siret: [null, Validators.required],
        finess_ej: [null, Validators.required],
        finess_etab: [null, Validators.required],
        ville: [null, Validators.nullValidator],
        etabMail: [null, Validators.nullValidator],
        civilite: [null, Validators.required],
        nom: [null, Validators.required],
        prenom: [null, Validators.required],
        fonction: [null, Validators.nullValidator],
        mail: [null, [Validators.email, Validators.required]],
        mail2: [null, [Validators.email, Validators.nullValidator]],
        telBureau: [null, Validators.required],
        tel: [null, Validators.nullValidator],
        login: [null, {
          validators: [Validators.required],
          asyncValidators: [this.isLoginValid()],
          updateOn: 'change'
        }],
        password: [null, Validators.required],
        // Champ de sélection d'un établissement
        selectedEtab: [null, Validators.nullValidator]
      });
    } else {
      // Sécurité : si une fonction associée à ce collaborateur a été supprimée depuis, on la rajoute dans la liste
      this.collaborateurToValidate.collaborateurFonctions.forEach(cf => {
        if(!this.fonctions.map(f => f.id).includes(cf.fonctionId)) {
          this.fonctions.push(cf.fonction);
        }
      });
      this.fonctions = this.fonctions.sort((f1, f2) => f1.libelle.localeCompare(f2.libelle));

      // Initialise les champs avec les informations du collaborateur
      this.inscriptionForm = this.formBuilder.group({
        // Etablissement
        etabNom: [this.collaborateurToValidate.etablissement.nom, Validators.required],
        siret: [this.collaborateurToValidate.etablissement.siret, Validators.required],
        finess_ej: [this.collaborateurToValidate.etablissement.finess_EJ, Validators.required],
        finess_etab: [this.collaborateurToValidate.etablissement.finess_Etab, Validators.required],
        ville: [this.collaborateurToValidate.etablissement.ville, Validators.nullValidator],
        etabMail: [this.collaborateurToValidate.etablissement.mail, [Validators.email, Validators.nullValidator]],
        // Collaborateur
        civilite: [this.collaborateurToValidate.civilite, Validators.required],
        nom: [this.collaborateurToValidate.nom, Validators.required],
        prenom: [this.collaborateurToValidate.prenom, Validators.required],
        fonction: [this.collaborateurToValidate.collaborateurFonctions.map(cf => cf.fonction), Validators.nullValidator],
        mail: [this.collaborateurToValidate.mail, [Validators.email, Validators.required]],
        mail2: [this.collaborateurToValidate.mail2, [Validators.email, Validators.nullValidator]],
        telBureau: [this.collaborateurToValidate.telBureau, Validators.required],
        tel: [this.collaborateurToValidate.telPortable, Validators.nullValidator],
        login: [this.collaborateurToValidate.login, {
          validators: [Validators.required],
          asyncValidators: [this.isLoginValid()],
          updateOn: 'change'
        }],
        password: [this.collaborateurToValidate.password, Validators.required],
        // Champ de sélection d'un établissement
        selectedEtab: [this.collaborateurToValidate.etablissement, Validators.nullValidator]
      });
      // #region Bloque la modification des champs 
      if(this.mode != 3) {
        // Collaborateur
        this.inscriptionForm.get('civilite').disable();
        this.inscriptionForm.get('nom').disable();
        this.inscriptionForm.get('prenom').disable();
        this.inscriptionForm.get('fonction').disable();
        this.inscriptionForm.get('mail').disable();
        if(this.mode != 4) {
          this.inscriptionForm.get('mail2').disable();
          this.inscriptionForm.get('telBureau').disable();
          this.inscriptionForm.get('tel').disable();
        }
        this.inscriptionForm.get('login').disable();
        this.inscriptionForm.get('password').disable();
      }
      // Etablissement (bloqués dans tous les cas, si modification nécessaire il faut utiliser le module "Territoires")
      this.inscriptionForm.get('etabNom').disable();
      this.inscriptionForm.get('siret').disable();
      this.inscriptionForm.get('finess_ej').disable();
      this.inscriptionForm.get('finess_etab').disable();
      this.inscriptionForm.get('ville').disable();
      this.inscriptionForm.get('etabMail').disable();
      // #endregion
    }
  }

  // Changer le mode d'affichage du mot de passe
  switchMdpType() {
    this.mdpType = (this.mdpType == "password") ? "text" : "password";
  }

  // Sélection d'un établissement existant dans la base
  onEtabChange(etab: Etablissement) {
    if(etab) {
      this.remplissageEtablissement(etab);
      this.inscriptionForm.get('selectedEtab').setValue(etab);
      this.inscriptionForm.get('etabMail').setValue(etab.mail);
      this.inscriptionForm.get('etabMail').disable();
    } else {
      this.editEtablissement();
    }
  }

  // Ouverture et fermeture de la pop-up recherche avancée
  rechercheApiFiness() {
    this.refAvancee = this.dialogService.open(PopUpRechercheAvanceeComponent, {
      header: "Recherche avancée",
      width: "60%",
      contentStyle: { height: "auto", overflow: "auto" },
      data: {
        recherche: this.inscriptionForm.get('selectedEtab').value ? '' : this.inscriptionForm.get('etabNom').value
      },
    });

    this.refAvancee.onClose.subscribe((choix) => {
      if(choix) {
        this.remplissageEtablissement(choix);
      }
    });
  }

  remplissageEtablissement(etab: Etablissement) {
    this.inscriptionForm.get('etabNom').setValue(etab.nom);
    this.inscriptionForm.get('siret').setValue(etab.siret);
    this.inscriptionForm.get('finess_ej').setValue(etab.finess_EJ);
    this.inscriptionForm.get('finess_etab').setValue(etab.finess_Etab);
    this.inscriptionForm.get('ville').setValue(etab.ville);
    this.inscriptionForm.get('etabMail').setValue("");

    this.inscriptionForm.get('etabNom').disable();
    this.inscriptionForm.get('siret').disable();
    this.inscriptionForm.get('finess_ej').disable();
    this.inscriptionForm.get('finess_etab').disable();
    this.inscriptionForm.get('ville').disable();
    this.inscriptionForm.get('etabMail').enable();

    this.inscriptionForm.get('selectedEtab').setValue(null);

    this.isSelectedEtab = true;
  }
  
  editEtablissement() {
    this.inscriptionForm.get('etabNom').setValue("");
    this.inscriptionForm.get('siret').setValue("");
    this.inscriptionForm.get('finess_ej').setValue("");
    this.inscriptionForm.get('finess_etab').setValue("");
    this.inscriptionForm.get('ville').setValue("");
    this.inscriptionForm.get('etabMail').setValue("");

    this.inscriptionForm.get('etabNom').enable();
    this.inscriptionForm.get('siret').enable();
    this.inscriptionForm.get('finess_ej').enable();
    this.inscriptionForm.get('finess_etab').enable();
    this.inscriptionForm.get('ville').enable();
    this.inscriptionForm.get('etabMail').enable();

    this.inscriptionForm.get('selectedEtab').setValue(null);

    this.isSelectedEtab = false;
  }

  identifiantSearch(login: string) {
    if(this.unsubscribeSearchLogin) {
      this.unsubscribeSearchLogin.unsubscribe();
    }

    this.identifiantValid = null;

    if(login.length > 2) {

      if(this.mode == 3 && login == this.collaborateurToValidate.login) {
        this.identifiantValid = true;
      } else {
        this.timer = setTimeout(() => {
          this.loadingLogin = true;
          this.unsubscribeSearchLogin = this.collaborateurService.searchValidIdentifiant(login).subscribe(isValid => {
            this.identifiantValid = isValid;
            this.loadingLogin = false;
          }, error => {
            this.identifiantValid = false;
            this.loadingLogin = false;
          });
        }, this.waitTime);
      }
    }
  }

  validate() {
    // Mise en forme de l'établissement
    var etab = new Etablissement();
    etab.nom = this.inscriptionForm.get('etabNom').value;
    etab.siret = this.inscriptionForm.get('siret').value;
    etab.finess_EJ = this.inscriptionForm.get('finess_ej').value;
    etab.finess_Etab = this.inscriptionForm.get('finess_etab').value;
    etab.ville = this.inscriptionForm.get('ville').value;
    etab.mail = this.inscriptionForm.get('etabMail').value;
    
    // Récupère ou créer l'établissement
    this.etablissementService.add(etab).subscribe(etablissement => {
      // Mise en forme de l'utilisateur
      var user = new Collaborateur();
      user.civilite = this.inscriptionForm.get('civilite').value;
      user.nom = this.inscriptionForm.get('nom').value;
      user.prenom = this.inscriptionForm.get('prenom').value;
      user.mail = this.inscriptionForm.get('mail').value;
      user.mail2 = this.inscriptionForm.get('mail2').value;
      user.telBureau = this.inscriptionForm.get('telBureau').value;
      user.telPortable = this.inscriptionForm.get('tel').value;
      user.login = this.inscriptionForm.get('login').value;
      user.password = this.inscriptionForm.get('password').value;
      user.etablissementID = etablissement.id;
      user.isVisible = true;
      user.isActif = (this.mode == 1) ? true : false;

      // Mise en forme des fonctions
      user.collaborateurFonctions = [];
      if(this.inscriptionForm.get('fonction').value) {
        this.inscriptionForm.get('fonction').value.forEach(f => {
          let newCF: CollaborateurFonction = new CollaborateurFonction();
          newCF.collaborateurId = user.id;
          newCF.fonctionId = f.id;
          user.collaborateurFonctions.push(newCF);
        });
      }

      if(this.mode != 3 && this.mode != 4) {
        // Création 
        this.collaborateurService.add(user).subscribe((c) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le compte a bien été créé.'
          });
          this.return();
        }),
        (e) =>{
          console.error("ERREUR CREATION COMPTE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la création de compte',
            detail: 'Le compte n\'as pas pu être ajouté. Veuillez réessayer.'
          });
        }
      } else {
        // Modification
        user.id = this.collaborateurToValidate.id;
        user.isActif = this.collaborateurToValidate.isActif;

        this.collaborateurService.update(user).subscribe((c) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le compte a bien été mis à jour.'
          });
          this.return();
        }),
        (e) =>{
          console.error("ERREUR MODIFICATION COMPTE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification du compte',
            detail: 'Le compte n\'as pas pu être modifié. Veuillez réessayer.'
          });
        }
      }
    })
  }

  // Modification de mon profil
  validateMonProfil() {
    let profilModificationMessage: string = "";
    if(this.inscriptionForm.get('mail2').value != this.collaborateurToValidate.mail2) {
      profilModificationMessage += "Mail 2 </b>";
    }
    if(this.inscriptionForm.get('telBureau').value != this.collaborateurToValidate.telBureau && this.inscriptionForm.get('telBureau').value != "") {
      profilModificationMessage += (profilModificationMessage != "") ? " et <b>Téléphone Bureau</b>": "Téléphone Bureau</b>";
    }
    if(this.inscriptionForm.get('tel').value != this.collaborateurToValidate.telPortable) {
      profilModificationMessage += (profilModificationMessage != "") ? " et <b>Téléphone Portable</b>": "Téléphone Portable</b>";
    }

    if(profilModificationMessage != "") {
      // Ouverture de la popup
      this.confirmationService.confirm({
        message: 'Souhaitez-vous vraiment modifier vos informations personelles concernant votre <b>'+  profilModificationMessage + ' ?',
        header: '',
        accept: () => {
          // Cast pour uniforme avec la bdd
          if(this.inscriptionForm.get('mail2').value == "") this.inscriptionForm.get('mail2').setValue(null);
          if(this.inscriptionForm.get('telBureau').value == "") this.inscriptionForm.get('telBureau').setValue(null);
          if(this.inscriptionForm.get('tel').value == "") this.inscriptionForm.get('tel').setValue(null);
  
          // Met à jour les modifications
          this.collaborateurToValidate.mail2 = (this.collaborateurToValidate.mail2 != this.inscriptionForm.get('mail2').value) ?  this.inscriptionForm.get('mail2').value : this.collaborateurToValidate.mail2;
          this.collaborateurToValidate.telBureau = (this.collaborateurToValidate.telBureau != this.inscriptionForm.get('telBureau').value) ? this.inscriptionForm.get('telBureau').value : this.collaborateurToValidate.telBureau;
          this.collaborateurToValidate.telPortable = (this.collaborateurToValidate.telPortable != this.inscriptionForm.get('tel').value) ? this.inscriptionForm.get('tel').value : this.collaborateurToValidate.telPortable;
  
          this.collaborateurService.update(this.collaborateurToValidate).subscribe(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Données enregistrées avec succès !',
              detail:  'Le compte a bien été mis à jour.'
            });
          }),
          (e) =>{
            console.error("ERREUR MODIFICATION COMPTE");
            console.error(e);
    
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur lors de la modification du compte',
              detail: 'Le compte n\'as pas pu être modifié. Veuillez réessayer.'
            });
          }
        },
        reject: () => {
          // remet les valeurs d'origines
          this.inscriptionForm.get('mail2').setValue(this.collaborateurToValidate.mail2);
          this.inscriptionForm.get('telBureau').setValue(this.collaborateurToValidate.telBureau);
          this.inscriptionForm.get('tel').setValue(this.collaborateurToValidate.telPortable);
        }
      });
    }
  }

  return() {
    history.back();
  }

  activate() {
    this.collaborateurService.activate(this.collaborateurToValidate.id).subscribe(() =>{
      this.messageService.add({
        severity: 'success',
        summary: 'Données enregistrées avec succès !',
        detail:  'Le compte a bien été activé.'
      });
      this.return();
    },
    (e) =>{
      console.error("ERREUR ACTIVATION COMPTE");
      console.error(e);

      this.messageService.add({
        severity: 'error',
        summary: 'Erreur lors de l\'activation du compte',
        detail: 'Le compte n\'as pas pu être activé. Veuillez réessayer.'
      });
    })
  }

  openPopupModificationMdp() {
    this.refPassword = this.dialogService.open(PopUpModificationMdpComponent, {
      header: "MODIFIER MON MOT DE PASSE",
      width: "40%",
      contentStyle: { height: "auto", overflow: "auto" },
      data: {
        collaborateur: this.collaborateurToValidate
      },
    });

    this.refPassword.onClose.subscribe((password) => {
      if(password) {
        this.collaborateurService.updatePassword(password).subscribe(() => {
          this.collaborateurToValidate.password = password;
          this.messageService.add({
            severity: 'success',
            summary: 'Données enregistrées avec succès !',
            detail:  'Le compte a bien été mis à jour.'
          });
        }),
        (e) => {
          console.error("ERREUR MODIFICATION COMPTE");
          console.error(e);
  
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification du compte',
            detail: 'Le compte n\'as pas pu être modifié. Veuillez réessayer.'
          });
        }
      }
    });
  }

  isLoginValid(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if(control.value.length > 2) {
        return this.collaborateurService.searchValidIdentifiant(control.value).pipe(
          map((res: boolean) => {
            return ((res == true) || (this.mode == 3 && control.value == this.collaborateurToValidate.login)) ? null : { loginAlreadyExists: true }
          })
        );
      } else {
        const error: ValidationErrors = {
          error: 'L\'identifiant doit être de 3 carctères au minimum'
        };
        return of(error);
      }
    }
  }
}