<header>
  <nav class="navbar">
    <div style="display: flex; justify-content:space-between; align-items: center;">
      <div style="margin-left: 10px;">
        <img src="../../assets/images/logo_amc_new.png" [routerLink]="'/' + territoireId + '/tableau-de-bord'">
      </div>
      <div class="menu-items" style="margin-right: 10px;">
        <button id="tdb" [class]='getButtonClass("tdb")' [routerLink]="'/' + territoireId + '/tableau-de-bord'">Tableau de bord</button>
        <!-- <button id="indic" [class]='getButtonClass("indic")' [routerLink]="'/indicateurs'">Indicateurs</button> -->
        <div class="sous-menu">
          <button id="params" *ngIf="isAdminAMC || isAdminTerritoire || isBedManagerTerritoire" [class]='getButtonClass("params") + " upper-menu"'> <span class="up fa-solid fa-chevron-down"></span><span class="down fa-solid fa-chevron-down"></span>&nbsp;&nbsp;Paramétrage</button>
          <div class="sous-menu-items">
            <button id="params-territoires" *ngIf="isAdminAMC" [routerLink]="'/parametrage/territoires'">Territoires</button>
            <button id="params-secteurs-territoire" *ngIf="isAdminAMC || isAdminTerritoire || isBedManagerTerritoire" [routerLink]="'/' + territoireBedManagerId + '/parametrage/secteurs-de-territoire'">Secteurs de territoire</button>
            <button id="params-roles" *ngIf="isAdminAMC || isAdminTerritoire" [routerLink]="'/parametrage/gestion-roles'">Gestion des rôles</button>
            <button id="params-secteurs-services" *ngIf="isAdminAMC || isAdminTerritoire" [routerLink]="'/' + territoireAdminId +'/parametrage/gestion-secteurs-services'">Secteurs et services</button>
            <button id="params-comptes" *ngIf="isAdminAMC || isAdminTerritoire" [routerLink]="'/parametrage/gestion-comptes'">Gestion des comptes</button>
            <button id="params-indicateurs" *ngIf="isAdminAMC || isAdminTerritoire || isBedManagerTerritoire" [routerLink]="'/' + territoireBedManagerId + '/parametrage/gestion-indicateurs'">Gestion des indicateurs</button>
          </div>
        </div>
        <button id="profil" [class]='getButtonClass("profil")' [routerLink]="'/profil'">Mon profil</button>
        <button id="logout" pTooltip="Se déconnecter" class="orange rond" (click)="logout()">
          <span class="fas fa-right-from-bracket"></span>
        </button>
      </div>
    </div>
  </nav>
</header>
