import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { Unite } from "../models/unite";
import { plainToInstance } from "class-transformer";
import { map } from "rxjs/operators";
import { TDB_Donnees } from "../models/tdb_donnees";

@Injectable({
    providedIn: 'root'
  })
export class UniteService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/gdl/unite';

    getTableauDeBord(etablissementIds:number[], secteurIds:number[]): Observable<Unite[]> {
        var url = this.url + "/tableau-de-bord?";        
        //On ajoute les id dans l'url en query
        for(var i = 0; i < etablissementIds.length; i++){
            if(i == 0){
                url += 'etablissementIds=' + etablissementIds[i]
            }
            if(i > 0){
                url += '&etablissementIds=' + etablissementIds[i]
            }       
        }
        for(var i = 0; i < secteurIds.length; i++){
            if(i == 0 && etablissementIds?.length == 0){
                url += 'secteurIds=' + secteurIds[i]
            }
            else{
                url += '&secteurIds=' + secteurIds[i]
            }       
        }
        return this.http.get<Unite[]>(url).pipe(map(u => plainToInstance(Unite, u)));
    }

    getPrevisionnel(etablissementIds:number[], secteurIds:number[]): Observable<Unite[]> {
        var url = this.url + "/previsionnel?";        
        //On ajoute les id dans l'url en query
        for(var i = 0; i < etablissementIds.length; i++){
            if(i == 0){
                url += 'etablissementIds=' + etablissementIds[i]
            }
            if(i > 0){
                url += '&etablissementIds=' + etablissementIds[i]
            }       
        }
        for(var i = 0; i < secteurIds.length; i++){
            if(i == 0 && etablissementIds?.length == 0){
                url += 'secteurIds=' + secteurIds[i]
            }
            else{
                url += '&secteurIds=' + secteurIds[i]
            }       
        }
        return this.http.get<Unite[]>(url).pipe(map(u => plainToInstance(Unite, u)));
    }

    getByEtablissementIds(etabIds: number[]): Observable<Unite[]> {
        let parametres: string = "?";
        etabIds.forEach(id => parametres += (parametres == "?" ? "" : "&") + "etabIds=" + id);
        return this.http.get<Unite[]>(this.url + "/byEtablissementIds" + parametres).pipe(map(u => plainToInstance(Unite, u)));
    }

    add(newUnite: Unite): Observable<Unite> {
        return this.http.post<Unite>(this.url, newUnite).pipe(map(u => plainToInstance(Unite, u)));
    }

    update(uniteToUpdate: Unite): Observable<Unite> {
        return this.http.put<Unite>(this.url + "/" + uniteToUpdate.id, uniteToUpdate).pipe(map(u => plainToInstance(Unite, u)));
    }

    remove(id: number) {
        return this.http.delete(this.url + "/" + id);
    }

    updateChamp(donnees: TDB_Donnees): Observable<TDB_Donnees> {
        return this.http.put<TDB_Donnees>(this.url + "/" + donnees.uniteID + "/updateChamp", donnees).pipe(map(d => plainToInstance(TDB_Donnees, d)));
    }
}