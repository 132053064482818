import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { User } from 'src/app/authentification/models/user';
import { UserService } from 'src/app/authentification/services/user.service';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { Seuil } from 'src/app/shared/models/seuil';
import { EtablissementService } from 'src/app/shared/services/annuaire/etablissement.service';
import { SeuilService } from 'src/app/shared/services/seuil.service';
import { TerritoireService } from 'src/app/shared/services/territoire.service';
import { eChamps, TDB_Champs } from 'src/app/shared/models/tdb_champs';
import { TDB_ChampsService } from 'src/app/shared/services/tdb_champs.service';
import { TDB_Seuil_Etablissement } from 'src/app/shared/models/tdb_seuil_etablissement';
import { Territoire } from 'src/app/shared/models/territoire';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gestion-indicateurs',
  templateUrl: './gestion-indicateurs.component.html',
  styleUrls: ['./gestion-indicateurs.component.scss']
})
export class GestionIndicateursComponent implements OnInit {

  isLoading: boolean = false;
  isModification: boolean = false;
  isSaving: boolean = false;

  user: User;

  seuils: Seuil[] = [];
  etablissements: Etablissement[] = [];
  selectedEtablissement: Etablissement;

  etablissementSeuil: Etablissement;

  champsUrgence: TDB_Champs[] = [];
  champsUHCD: TDB_Champs[] = [];

  tableauSeuilsUrgence = [];
  tableauSeuilsUHCD = [];

  territoires: Territoire[] = [];
  selectedTerritoire: Territoire;

  constructor(
    private seuilService: SeuilService,
    private territoireService: TerritoireService,
    private userService: UserService,
    private etablissementService: EtablissementService,
    private tdb_champsService: TDB_ChampsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    combineLatest([
      this.seuilService.getAll(),
      this.tdb_champsService.getAllUrgence(),
      this.route.params
    ])
      .subscribe(([seuils, champs, routeParams]) => {
        this.seuils = seuils;
        this.champsUrgence = champs.filter(c => c.correspondance == 2);
        this.champsUHCD = champs.filter(c => c.correspondance == 3);
        if (this.route.snapshot.data.etablissements) {
          this.etablissements = this.route.snapshot.data.etablissements;
          if(this.etablissements.find(e => e.id == this.userService.currentUser.etablissementId)) {
            this.selectedEtablissement = this.etablissements.find(e => e.id == this.userService.currentUser.etablissementId);
          } else {
            this.selectedEtablissement = this.etablissements[0];
          }
          this.isLoading = true;
          this.territoireService.getAll().subscribe(territoires => {
            this.territoires = territoires;
            this.selectedTerritoire = this.territoires.find(ter => ter.id == parseInt(routeParams.id));
  
            if(this.selectedTerritoire) {
              this.selectEtablissement();
            } else {
              this.isLoading = false;
            }
          });
        }else{
          this.isLoading = false;
        }
      });
  }

  selectTerritoire() {
    this.router.navigate([this.selectedTerritoire.id + '/parametrage/gestion-indicateurs']);
  }

  selectEtablissement() {
    this.etablissementService.getEtablissementSeuilById(this.selectedEtablissement.id).subscribe(etablissementSeuil => {
      this.etablissementSeuil = etablissementSeuil;
      this.tableauSeuilsUrgence = this.formatage(etablissementSeuil[0].seuilEtablissement, this.champsUrgence);
      this.tableauSeuilsUHCD = this.formatage(etablissementSeuil[0].seuilEtablissement, this.champsUHCD);
      this.isModification = etablissementSeuil[0].seuilEtablissement.length > 0;
      this.isLoading = false;
    });
  }

  formatage(etablissementSeuil: TDB_Seuil_Etablissement[], champs: TDB_Champs[]) {
    var tableauSeuils = [];
    for (let i = 0; i < champs.length; i++) {
      let tableauSeuilsRow = [];
      tableauSeuilsRow.push(champs[i]);
      for (let j = 1; j <= this.seuils.length; j++) {
        if (etablissementSeuil.length > 0) tableauSeuilsRow.push(etablissementSeuil.find(es => es.champsId == champs[i].id && es.seuilId == j).valeur);
      }
      tableauSeuils.push(tableauSeuilsRow);
    }
    return tableauSeuils;
  }

  validate() {
    this.isSaving = true;
    var tableauSeuilEtablissement: TDB_Seuil_Etablissement[] = [];
    this.tableauSeuilsUrgence.concat(this.tableauSeuilsUHCD).forEach(seuil => {
      for (let i = 1; i <= this.seuils.length; i++) {
        var newSeuil = new TDB_Seuil_Etablissement();
        newSeuil.etablissementId = this.selectedEtablissement.id;
        newSeuil.champsId = seuil[0].id;
        newSeuil.seuilId = i;
        newSeuil.valeur = seuil[i];
        tableauSeuilEtablissement.push(newSeuil)
      }
    });
    if (this.isModification) {
      this.etablissementService.updateSeuil(this.selectedEtablissement.id, tableauSeuilEtablissement).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail: 'Les seuils de tension ont été modifiés.'
        });
        this.selectEtablissement();
        this.isSaving = false;
      },
        (e) => {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de l\'enregistrement des données',
            detail: 'Les seuils de tension n\'ont pas été enregistrés. Veuillez réessayer.'
          });
        });
    } else {
      this.etablissementService.addSeuil(this.selectedEtablissement.id, tableauSeuilEtablissement).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Données enregistrées avec succès !',
          detail: 'Les seuils de tension ont été enregistrés.'
        });
        this.selectEtablissement();
        this.isSaving = false;
      },
        (e) => {
          console.error(e);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de l\'enregistrement des données',
            detail: 'Les seuils de tension n\'ont pas été enregistrés. Veuillez réessayer.'
          });
        });
    }
  }
}