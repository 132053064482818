import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment.prod';
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";
import { Seuil } from "../models/seuil";

@Injectable({
    providedIn: 'root'
  })
  export class SeuilService {
  
    constructor(private http: HttpClient) { }
  
    private url = environment.url + 'api/gdl/seuil';
    
    getAll(): Observable<Seuil[]> {
      return this.http.get<Seuil[]>(this.url).pipe(map(t => plainToInstance(Seuil, t)));
    }

    update(seuil: Seuil): Observable<Seuil> {
      return this.http.put<Seuil>(this.url + "/" + seuil.id, seuil).pipe(map(t => plainToInstance(Seuil, t)));
    }

  }