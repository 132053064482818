import { NgModule } from '@angular/core';
import { DropdownModule } from "primeng/dropdown";
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { CarouselModule } from "primeng/carousel";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { OrderListModule } from "primeng/orderlist";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { PickListModule } from "primeng/picklist";
import { FormsModule } from "@angular/forms";
import { FileUploadModule } from "primeng/fileupload";
import { TableModule } from "primeng/table";
import { SelectButtonModule } from "primeng/selectbutton";
import { ColorPickerModule } from "primeng/colorpicker";
import { MultiSelectModule } from "primeng/multiselect";
import { InputMaskModule } from "primeng/inputmask";
import { TooltipModule } from "primeng/tooltip";
import { RadioButtonModule } from "primeng/radiobutton";
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ChartModule } from "primeng/chart";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { Tree, TreeModule } from 'primeng/tree';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
    declarations: [],
    imports: [
        DropdownModule,
        InputTextModule,
        TooltipModule,
        ToastModule,
        TableModule,
        ColorPickerModule,
        FileUploadModule,
        PickListModule,
        InputMaskModule,
        MultiSelectModule,
        RadioButtonModule,
        DynamicDialogModule,
        SelectButtonModule,
        OrderListModule,
        FormsModule,
        CarouselModule,
        AccordionModule,
        CheckboxModule,
        InputTextareaModule,
        TriStateCheckboxModule,
        ChartModule,
        ConfirmDialogModule,
        CalendarModule,
        TreeModule,
        MenuModule,
        MegaMenuModule,
        InputNumberModule,
        MessagesModule,
        CardModule,
        PasswordModule,
        InputSwitchModule
    ],
    providers: [
        MessageService,
        ConfirmationService,
        PrimeNGConfig,
        DialogService
    ],
    exports: [
        DropdownModule,
        InputTextModule,
        TooltipModule,
        ToastModule,
        TableModule,
        ColorPickerModule,
        FileUploadModule,
        PickListModule,
        InputMaskModule,
        MultiSelectModule,
        RadioButtonModule,
        DynamicDialogModule,
        SelectButtonModule,
        OrderListModule,
        FormsModule,
        CarouselModule,
        AccordionModule,
        CheckboxModule,
        InputTextareaModule,
        TriStateCheckboxModule,
        ChartModule,
        ConfirmDialogModule,
        CalendarModule,
        TreeModule,
        MegaMenuModule,
        InputNumberModule,
        MessagesModule,
        CardModule,
        PasswordModule,
        InputSwitchModule
    ]
})
export class PrimeNgModule {

}