import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Fonction } from '../../models/annuaire/fonction';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class FonctionService {
  
  constructor(private http: HttpClient) { }

  private url = environment.url + 'api/ann/fonction';

  getAll(): Observable<Fonction[]> {
    return this.http.get<Fonction[]>(this.url).pipe(
      map(f => {
        return plainToInstance(Fonction, f);
      })
    );
  }

}
