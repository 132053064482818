import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Etablissement } from 'src/app/shared/models/annuaire/etablissement';
import { F_Record } from 'src/app/shared/models/APIFiness/F_Record';
import { F_Result } from 'src/app/shared/models/APIFiness/F_Result';
import { ApiFinessService } from 'src/app/shared/services/APIFiness/api-finess.service';

@Component({
  selector: 'app-pop-up-recherche-avancee',
  templateUrl: './pop-up-recherche-avancee.component.html',
  styleUrls: ['./pop-up-recherche-avancee.component.scss']
})
export class PopUpRechercheAvanceeComponent implements OnInit {

  texteRecherche: string = "";
  loading: boolean = false;

  configurationTableStructure = [
    { field: "raison_sociale", header: "Nom" },
    { field: "entite_juridique", header: "FINESS Juridique" },
    { field: "etablissement", header: "FINESS Établissement" },
    { field: "code_postal", header: "CP" },
    { field: "libelle_commune", header: "Ville" },
    { field: "dep", header: "Dpt" },
  ];

  apiFinessResult: F_Result;

  constructor(
    private apiFinessService: ApiFinessService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if (this.config.data.recherche && this.config.data.recherche != "") {
      this.texteRecherche = this.config.data.recherche;
      this.recherche();
    }
  }

  // Temps avant que la recherche ne soit prise en compte
  modificationRecherche() {
    setTimeout(() => {
      this.recherche();
    }, 1000);
  }

  // Recherche via l'API Finess
  recherche() {
    if (this.texteRecherche.length > 2) {
      this.loading = true;
      this.apiFinessService.recherche(this.texteRecherche).subscribe((result) => {
        this.apiFinessResult = result;
        this.loading = false;
      });
    }
  }

  selectionEtablissement(record: F_Record) {
    var etabChoisi: Etablissement = new Etablissement();
    etabChoisi.nom = record.fields.raison_sociale;
    etabChoisi.finess_EJ = record.fields.entite_juridique;
    etabChoisi.finess_Etab = record.fields.etablissement;
    etabChoisi.siret = record.fields.siret;
    etabChoisi.ville = record.fields.libelle_commune;
    
    //Passage de l'établissement choisi au composant appelant cette popup
    this.ref.close(etabChoisi);
  }
}
