export class User {
    id: number;
    nom: string;
    prenom: string;
    email: string;
    etablissementId: number;
    territoires: number[];
    roles: string[];

    static FromUserData(userData: any): User {
        if (userData) {
            var user = new User();
            user.id = Number.parseInt(userData.id);
            user.nom = userData.nom;
            user.prenom = userData.prenom;
            user.email = userData.email;
            user.etablissementId = Number.parseInt(userData.etabId);
            user.territoires = this.convertToArrayOfNumber(userData.territoireId);
            user.roles = this.convertToArray(userData.role);
            return user;
        }
        return null;
    }

    get NomPrenom(): string {
        return this.nom.toUpperCase() + " " + this.prenom;
    }

    isRole(roleId: number): boolean {
        return this.roles?.find(r => r.startsWith(roleId + ";")) ? true : false;
    }

    isRoleTerritoire(roleId: number, territoireId: number): boolean {
        return this.roles?.find(r => r == roleId + ";" + territoireId) ? true : false;
    }

    static convertToArray(data: any):any[]
    {
       let datas = [];
       if(Array.isArray(data) == true){
           datas = data;
       }
       else if (data){
           datas.push(data);
       }
       return datas;
    }

    static convertToArrayOfNumber(data: any): number[] {
        var array: number[] = []; 
        if ( data != null ) {
            if ( data instanceof Array ) { 
                data.forEach( e => {    
                    array = array.concat(this.convertToArrayOfNumber(e));    
                });    
            } else {    
                if ( data == null ) {}    
                if ( (typeof data) == "string" ) {    
                    array.push(parseInt(data));        
                } else if ( (typeof data) == "number" ) {    
                    array.push(data);    
                }    
            }
        }
        return array;
    }
}