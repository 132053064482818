import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { IdleTimeoutManager } from "idle-timer-manager";
import { DialogService } from 'primeng/dynamicdialog';
import { IdlePopupComponent } from '../components/idle-popup/idle-popup.component';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { CollaborateurService } from 'src/app/shared/services/annuaire/collaborateur.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private userSubject: BehaviorSubject<User>
  currentUser: User;
  user$: Observable<User>

  constructor(private oauthService: OidcSecurityService, private router: Router, private dialogService: DialogService, private configService : ConfigService, private http: HttpClient, private collaborateurService: CollaborateurService) {
    this.oauthService.checkAuth().subscribe((auth) => {
      if (auth) {
          this.startIdleSession();
          if(this.currentUser) this.collaborateurService.updateActivite().subscribe();
      }
    });
    this.userSubject = new BehaviorSubject<User>(null);
    this.user$ = this.userSubject.asObservable();
    this.oauthService.userData$.subscribe(d => {
      this.currentUser = User.FromUserData(d.userData);
      this.userSubject.next(this.currentUser)
    });

    this.oauthService.stsCallback$.subscribe((callback) => {
      const routeBeforeAuth = localStorage.getItem('routeBeforeAuth');
      if (!!this.currentUser) {
        this.router.navigateByUrl(routeBeforeAuth);
        localStorage.removeItem('routeBeforeAuth');
      }
    })
  }

  private startIdleSession() {
    new IdleTimeoutManager({
      timeout: this.configService.get("IdleTime"),
      onExpired: () => {
        const ref = this.dialogService.open(IdlePopupComponent,{
          header: 'Rester connecté ?',
          width:'20%',
          data:{
            timeoutTimer : this.configService.get("IdleTimePopUp")
          },
          closable: false,
          closeOnEscape: false
        });
        ref.onClose.subscribe((data : boolean) => {
          if(data){
            this.startIdleSession();
          }
          else
          {
            this.logout();
          }
        })
      }
    });
  }

  login(currentUrl: string) {
    localStorage.setItem("routeBeforeAuth", currentUrl);
    this.oauthService.authorize();
  }

  loginAuto(currentUrl: string) {
    localStorage.setItem("routeBeforeAuth", currentUrl);
    this.oauthService.authorize(null, {customParams:{"acr_values":"idp:Windows"}});
  }

  logout() {
    this.oauthService.logoffAndRevokeTokens().subscribe();
  }

  getToken() {
    return this.oauthService.getAccessToken();
  }
}
