import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Collaborateur } from 'src/app/shared/models/annuaire/collaborateur';

@Component({
  selector: 'app-pop-up-modification-mdp',
  templateUrl: './pop-up-modification-mdp.component.html',
  styleUrls: ['./pop-up-modification-mdp.component.scss']
})
export class PopUpModificationMdpComponent implements OnInit {

  mdpForm : UntypedFormGroup;
  collaborateur: Collaborateur;

  constructor(
    private formBuilder : UntypedFormBuilder,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.collaborateur = this.config.data.collaborateur;
    
    this.mdpForm = this.formBuilder.group({
      login: [null, Validators.required],
      password: [null, Validators.required],
      newPassword: [null, Validators.required],
      confrimPassword: [null, Validators.required]
    });
  }

  validate() {
    // vérifie si combinaison login/password est correct
    if(this.mdpForm.get('login').value == this.collaborateur.login && 
    this.mdpForm.get('password').value == this.collaborateur.password &&
    this.mdpForm.get('newPassword').value == this.mdpForm.get('confrimPassword').value) {
      //Passage du mot de passe à la page parente
      this.ref.close(this.mdpForm.get('newPassword').value);
    } else {
      if((this.mdpForm.get('login').value != this.collaborateur.login || 
      this.mdpForm.get('password').value != this.collaborateur.password) && 
      this.mdpForm.get('newPassword').value != this.mdpForm.get('confrimPassword').value) {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur lors de la modification !',
          detail:  'Le couple login/mot de passe ainsi que le nouveau mot de passe et la confirmation ne correspondent pas.'
        });
      } else {
        // Message d'erreur couple login/mdp
        if(this.mdpForm.get('login').value != this.collaborateur.login || 
        this.mdpForm.get('password').value != this.collaborateur.password) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification !',
            detail:  'Le couple login/mot de passe ne correspond pas au compte.'
          });
        }
        // Message d'erreur couple nouveau mdp/confirmation
        if(this.mdpForm.get('newPassword').value != this.mdpForm.get('confrimPassword').value) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur lors de la modification !',
            detail: 'Le nouveau mot de passe et la confirmation ne correspondent pas.'
          });
        }
      }
    }
  }
}
